import { getUniqueValueFromArrayKeys1, getUniqueValueFromArrayKeys2, isAvailableArray } from "./data";

export function getUniqueFiltersList(dataList, key1, key2) {
  if (!dataList) return dataList
  
  if (key2) return getUniqueValueFromArrayKeys2(isAvailableArray(dataList), `${key1}`, `${key2}`)?.map(i => i[key1][key2])?.sort();

  const res = getUniqueValueFromArrayKeys1(isAvailableArray(dataList), `${key1}`)?.map(i => i[key1])?.sort();
  return res

  // let res = [];
  // switch (countKeys) {
  //   case 2:
  //     res = getUniqueValueFromArrayKeys2(isAvailableArray(dataList), `${key1}`, `${key2}`)?.map(i => i[key1][key2])?.sort();
  //     break;
  
  //   default:
  //     res = getUniqueValueFromArrayKeys1(isAvailableArray(dataList), `${key1}`)?.map(i => i[key1])?.sort();
  //     break;
  // }
  // return res
}

export function getUniqueFiltersListDateTime(dataList, countKeys, key1, key2) {
  if (!dataList) return dataList

  const newDataList = isAvailableArray(dataList)?.map(i => ({...i, [key1]: i[key1]?.slice(0, 10)}))

  let res = [];
  switch (countKeys) {
    case 2:
      res = getUniqueValueFromArrayKeys2(isAvailableArray(newDataList), `${key1}`, `${key2}`)?.map(i => i[key1][key2])?.sort();
      break;
  
    default:
      res = getUniqueValueFromArrayKeys1(isAvailableArray(newDataList), `${key1}`)?.map(i => i[key1])?.sort();
      break;
  }
  return res
}