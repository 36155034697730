const { createLocation } = require("./createLocation");
const { deleteLocationById } = require("./deleteLocationById");
const { getLocations } = require("./getLocations");
const { updateLocationById } = require("./updateLocationById");

module.exports = {
  createLocation,
  getLocations,
  updateLocationById,
  deleteLocationById,
};
