import styled from "styled-components";

export const SocialNetworksWrap = styled.div`
  position: relative;
`;

export const SocialNetworksBtn = styled.button`
  border: none;
  background-color: transparent;
  transition: var(--transition--100);

  .header & {
    color: var(--color--primary, #414141);
    text-transform: uppercase;
  }

  &:hover {
    color: var(--color--accent, #f19e1d);
  }
`;
export const SocialNetworksBox = styled.div`
  position: absolute;
  bottom: -60px;
  left: -10%;

  width: 120%;
  padding: 12px 20px;

  background-color: var(--color--bg, #F1F1F1);
  border-radius: 16px;
  box-shadow: var(--box-shadow--main);
  transition: var( --transition--200);
  z-index: 1;
  scale: 0;
  transform-origin: center;

  &::before {
    content: "";

    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);

    display: block;
    width: 24px;
    height: 12px;

    background-color: var(--color--bg, #F1F1F1);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  &.active { scale: 1; }
`;
