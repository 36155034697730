import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const updateTextById = createAsyncThunk(
  "texts/updateTextById",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await axios.patch(`/texts/${id}`, data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
