import { useSelector } from "react-redux";
// redux
import { selectTextsList } from "redux/texts/textsSelectors";
// data
import { text } from "data/text";

const useText = () => {
  const textDB = useSelector(selectTextsList) || null;

  return { textDB, text };
};

export default useText;
