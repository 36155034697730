import { createPortal } from "react-dom";
// components
import Nav from "components/Nav/Nav";
import Logo from "components/Logo/Logo";
import BurgerMenuBtn from "./BurgerMenuBtn";
import ContactsSn from "components/Contacts/ContactsSn";
import ContactsLine from "components/ContactsLine/ContactsLine";
// styleda
import { BurgerMenuContainer, BurgerMenuTag } from "./BurgerMenu.styled";
import { HeaderContainer } from "components/Header/Header.styled";

const modalRoot = document.querySelector("#modal-root");

const BurgerMenu = ({ isBurgerMenuOpen, setIsBurgerMenuOpen }) => {
  return createPortal(
    <BurgerMenuTag className={isBurgerMenuOpen ? "mobileMenuIsOpen" : ""}>
      <HeaderContainer className="burgerMenu">
        <BurgerMenuBtn isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />
      </HeaderContainer>
      <BurgerMenuContainer>
        <Logo className={"burger-menu"} />
        <ContactsSn className={"burger-menu"} />
        <Nav className={"burger-menu"} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />
        <ContactsLine className={"burger-menu"} />
      </BurgerMenuContainer>
    </BurgerMenuTag>,
    modalRoot
  );
};

export default BurgerMenu;
