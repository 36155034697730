import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";
// styled
import { Container } from "components/Common/common.styled";

export const BurgerMenuBtnTag = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  margin-left: 24px;

  color: var(--color--primary, #414141);

  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 4;

  @media ${minDevice.desktopXL} {
    display: none;
  }

  &.sidebar {
    margin-left: auto;

    @media ${minDevice.desktop} {
      display: none;
    }
  }
`;
export const BurgerMenuBtnLine = styled.span`
  position: absolute;

  width: 24px;
  height: 2px;

  border-radius: 4px;
  background-color: var(--color--primary, #414141);
  transition: var(--transition--100);

  &:first-child {
    transform: translateY(8px);
  }
  &:last-child {
    transform: translateY(-8px);
  }
  .mobileMenuIsOpen &:first-child {
    transform: rotate(-45deg);
  }
  .mobileMenuIsOpen &:nth-child(2n) {
    opacity: 0;
  }
  .mobileMenuIsOpen &:last-child {
    transform: rotate(45deg);
  }
`;

export const BurgerMenuTag = styled.div`
  position: fixed;
  top: 0px;
  left: 100%;
  width: 100%;
  height: 100%;

  z-index: 3;
  transition: var(--transition--200);
  background-color: var(--color--bg, #f1f1f1);
  overflow-y: scroll;

  &.mobileMenuIsOpen {
    left: 0;
  }
`;

export const BurgerMenuContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  max-width: 320px;
`;
