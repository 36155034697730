export function sortNumbers(arr, grow) {
  return [...arr]?.sort((a, b) => {
    if (grow) return a - b;
    return b - a;
  });
}

export function sortNumbers1key(arr, grow, key) {
  return [...arr]?.sort((a, b) => {
    if (grow) return a[key] - b[key];
    return b[key] - a[key];
  });
}

export function sortNumbers2keys(arr, grow, key1, key2) {
  return [...arr]?.sort((a, b) => {
    if (grow) return a[key1][key2] - b[key1][key2];
    return b[key1][key2] - a[key1][key2];
  });
}

export function sortNumbers3keys(arr, grow, key1, key2, key3) {
  return [...arr]?.sort((a, b) => {
    if (grow) return a[key1][key2][key3] - b[key1][key2][key3];
    return b[key1][key2][key3] - a[key1][key2][key3];
  });
}
