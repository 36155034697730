import React from 'react';
// hooks
import { useLang, useText } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// styled
import { RentalOfScootersBox, RentalOfScootersBoxWrap, RentalOfScootersLink, RentalOfScootersLinkWrap, RentalOfScootersSection } from './SectionRentalOfScooters.styled';
import { HomePageSectionTitle } from 'pages/public/HomePage/HomePage.styled';
import { Container } from 'components/Common/common.styled';

// ===== text translate START =====
const dataToTranslate = {
  // === DB & LOCAL ===
  textTitleRest: "pages.home.views.rental_of_scooters.title.rest",
  textTitleAccent: "pages.home.views.rental_of_scooters.title.accent",

  textRentalOfScootersForCouriers: "pages.home.views.rental_of_scooters.title_rental_of_scooters_for_couriers",
  textRentalOfElectricScooters: "pages.home.views.rental_of_scooters.title_rental_of_electric_scooters",
  textShortTermAndLongTermRent: "pages.home.views.rental_of_scooters.title_short_term_and_long_term_rent",
  textProfitableRentalFrom1Day: "pages.home.views.rental_of_scooters.text_profitable_rental_from_1_day",
}
// ===== text translate END =====

const SectionRentalOfScooters = () => {
  const { currentLang } = useLang();
  const { textDB } = useText();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang, textDB);
  const { 
    textTitleRest,
    textTitleAccent,
    textRentalOfScootersForCouriers,
    textRentalOfElectricScooters,
    textShortTermAndLongTermRent,
    textProfitableRentalFrom1Day,
  } = translate ?? {};
  // ===== text translate END =====

  return (
    <RentalOfScootersSection>
      <Container>
        <HomePageSectionTitle> <span>{textTitleAccent}</span> {textTitleRest} </HomePageSectionTitle>
        <RentalOfScootersBoxWrap>
          <RentalOfScootersBox>
            <RentalOfScootersLinkWrap className='link-1'>
              <RentalOfScootersLink to='/rental-of-scooters-for-couriers' className='link-1'>
                <span>{textRentalOfScootersForCouriers}</span>
              </RentalOfScootersLink>
            </RentalOfScootersLinkWrap>
          </RentalOfScootersBox>
          <RentalOfScootersBox>
            <RentalOfScootersLinkWrap className='link-2'>
              <RentalOfScootersLink to='/rental-of-electric-scooters' className='link-2'>
                <span>{textRentalOfElectricScooters}</span>
              </RentalOfScootersLink>
            </RentalOfScootersLinkWrap>
            <RentalOfScootersLinkWrap className='link-3'>
              <RentalOfScootersLink to='/short-term-and-long-term-rent' className='link-3'>
                <span>{textShortTermAndLongTermRent}</span>
                <span className='text'>{textProfitableRentalFrom1Day} </span>
              </RentalOfScootersLink>
            </RentalOfScootersLinkWrap>
          </RentalOfScootersBox>
        </RentalOfScootersBoxWrap>
      </Container>
    </RentalOfScootersSection>
  )
}

export default SectionRentalOfScooters