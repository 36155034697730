import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";

export const PageWrap = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;

  background-color: var(--color--bg, #f1f1f1);

  @media ${minDevice.desktop} {
    padding-left: 200px;
  }
`;

// export const MainWrap = styled.div`
//   flex: 0 0 100%;
//   width: 100%;
// `;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  min-height: 100vh;
  width: 100%;
  // border-top-left-radius: 30px;
  // box-shadow: inset 0 3px 4px rgba(0, 0, 0, .1);

  @media ${minDevice.tablet} {
    padding-top: 87px;
  }
`;