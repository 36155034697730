import React, { useState } from "react";
import { useDispatch } from "react-redux";
// redux
import { changeLang } from "redux/lang/langSlice";
// hooks
import { useLang } from "hooks";
// helpers
import { compareStrings, isAvailableArray, isAvailableObject } from "helpers";
// common
import { availableLanguages, availableSymbol } from "common/constants";
// styled
import { LangSwitcheBtn, LangSwitcherBox, LangSwitcherWrap, } from "./LangSwitcher.styled";

const LangSwitcher = ({ className = "" }) => {
  const { currentLang, symbol } = useLang();
  const [showLang, setShowLang] = useState(false);
  const dispatch = useDispatch();

  const availableLanguagesWithOutActive = isAvailableArray( availableLanguages )?.filter((i) => compareStrings(i, currentLang, false));

  const handleClick = (newCurrentLang) => {
    dispatch(changeLang(newCurrentLang));
    setShowLang(false);
  };

  return (
    <LangSwitcherWrap className={showLang ? `${className} active` : `${className}`}>
      <LangSwitcheBtn type="button" aria-label="Select lang" onClick={() => setShowLang(!showLang)}> 
        {`${symbol} | ${currentLang}`}
      </LangSwitcheBtn>

      <LangSwitcherBox className={showLang ? `${className} active` : `${className}`}>
        {availableLanguagesWithOutActive?.map((i, idx) => (
          <LangSwitcheBtn
            key={idx}
            type="button"
            aria-label="Select lang"
            onClick={() => handleClick(i)}
          >
            {isAvailableObject(availableSymbol)[i]} | {i}
          </LangSwitcheBtn>
        ))}
      </LangSwitcherBox>
    </LangSwitcherWrap>
  );
};

export default LangSwitcher;
