import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// redux
import { selectPublicRentalVehicles, selectVehiclesSearchList, } from "redux/vehicles/vehiclesSelectors";
// helpers
import { getAvailableVehicles } from "helpers";

const useAvailableVehicles = () => {
  const location = useLocation();
  const vehiclesHomePage = useSelector(selectPublicRentalVehicles);
  const vehiclesSearchPage = useSelector(selectVehiclesSearchList);
  let vehicles = vehiclesHomePage;
  if (location?.pathname === "/search") vehicles = vehiclesSearchPage;

  if (!vehicles) return {};
  const availableVehicles = getAvailableVehicles(vehicles);
  return availableVehicles;
};

export default useAvailableVehicles;
