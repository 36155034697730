import React from "react";
// hooks
import { useLang } from "hooks";
// helpers
import { getTranslate } from "helpers";
// components
import Logo from "components/Logo/Logo";
// styled
import { ThankYouBoxText, ThankYouBoxTitle, ThankYouBoxWrap, } from "./ThankYouBox.styled";
// ===== text translate START =====
const dataToTranslate = {
  // === string ===
  textThankYouForYourRequest: "thank_you_for_your_request",
  textOurManagerWillContactYouWithin10Minutes: "our_manager_will_contact_you_within_10_minutes",
};
// ===== text translate END =====

const ThankYouBox = () => {
  const { currentLang } = useLang();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const {
    // === string ===
    textThankYouForYourRequest,
    textOurManagerWillContactYouWithin10Minutes,
  } = translate ?? {};
  // ===== text translate END =====

  return (
    <ThankYouBoxWrap>
      <Logo className={"modal-thank-you"} />
      <ThankYouBoxTitle> {textThankYouForYourRequest} </ThankYouBoxTitle>
      <ThankYouBoxText> {textOurManagerWillContactYouWithin10Minutes} </ThankYouBoxText>
    </ThankYouBoxWrap>
  );
};

export default ThankYouBox;
