import React from 'react';
// components
import SvgUse from 'components/SvgUse/SvgUse';
import { ContactsLineBox, ContactsLineItem, ContactsLineLink, ContactsLineList } from './ContactsLine.styled';

const ContactsLine = ({ className }) => {
  return (
    <ContactsLineBox className={className}>
      <ContactsLineList>
        <ContactsLineItem>
          <ContactsLineLink to="https://maps.app.goo.gl/QUjzVD3uQBLgPRpX6" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10.5 0C6.90993 0 4 2.8607 4 6.39005C4 7.912 4.54437 9.30747 5.4489 10.4044C5.74047 10.7575 6.22824 11.3134 6.4825 11.6935C7.94652 13.88 9.82472 17.0555 10.3721 19.6615C10.466 20.1065 10.5792 20.1143 10.701 19.6759C11.0914 18.2708 12.1039 15.2005 14.1949 11.988C14.4443 11.6048 14.9498 11.0687 15.2636 10.7349C15.7182 10.2509 16.0965 9.69686 16.384 9.09277C16.7759 8.26872 17 7.35645 17 6.3908C17 2.86104 14.0901 0 10.5 0ZM10.5 9.84256C8.49623 9.84256 6.87186 8.24568 6.87186 6.27578C6.87186 4.30621 8.49623 2.7094 10.5 2.7094C12.5038 2.7094 14.1282 4.30628 14.1282 6.27578C14.1282 8.24575 12.5038 9.84256 10.5 9.84256Z" fill="#414141"/>
              <path d="M10.5 8C11.3284 8 12 7.32843 12 6.5C12 5.67157 11.3284 5 10.5 5C9.67157 5 9 5.67157 9 6.5C9 7.32843 9.67157 8 10.5 8Z" fill="#414141"/>
            </svg>
            The Czech Republic
          </ContactsLineLink>
        </ContactsLineItem>
        <ContactsLineItem>
          <ContactsLineLink to="tel:+420775177777" target="_blank">
            <SvgUse icontag={"tel-call"} />
            +420 775 177 777
          </ContactsLineLink>
        </ContactsLineItem>
      </ContactsLineList>
    </ContactsLineBox>
  )
}

export default ContactsLine