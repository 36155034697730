import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const deleteUserById = createAsyncThunk(
  "users/deleteUserById",
  async (id, thunkAPI) => {
    try {
      const res = await axios.delete(`/users/${id}`);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
