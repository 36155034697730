import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";
// styled
import { Container } from "components/Common/common.styled";

export const HeaderTag = styled.header`
  position: fixed;
  z-index: 3;

  flex: 0 0 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 12px 0;

  color: var(--color--primary, #414141);
  background-color: var(--color--white, #fff);
  box-shadow: 6px 6px 34px 0px rgba(0, 0, 0, 0.15);

  @media ${minDevice.tablet} {
    height: 87px;
  }
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;

  @media ${minDevice.desktopL} {
    justify-content: space-between;
  }
  // @media ${minDevice.desktopXL} {
  //   justify-content: space-between;
  // }

  &.burgerMenu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    justify-content: flex-end;
    margin-top: 24px;

    @media ${minDevice.tablet} {
      margin-top: 32px;
    }
  }
`;
