import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { minDevice } from "common/breakpoints";

export const RentalOfScootersSection = styled.section`
  width: 100%;
  padding: 80px 0;

  @media ${minDevice.desktopXL} {
    padding: 132px 0;
  }
`;

export const RentalOfScootersBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  --gap: 24px;
  gap: var(--gap);

  @media ${minDevice.desktopL} {
    flex-direction: row;
    --gap: 18px;
  }
`;

export const RentalOfScootersBox = styled.div`
  flex: 0 0 calc((100% - 18px) / 2);
  display: flex;
  flex-direction: column;
  gap: var(--gap);
`;

export const RentalOfScootersLinkWrap = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const RentalOfScootersLink = styled(Link)`
  position: relative;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 28px;
  min-height: 260px;
  height: 100%;

  color: var(--color--primary, #414141);
  font-family: "Helvetica Now Display";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px; /* 120% */
  text-transform: uppercase;

  background-color: #d9d9d9;
  border-radius: 26px;
  border: 1px solid transparent;

  &.link-1 {
    color: #fff;
    background-color: var(--color--accent, #f19e1d);
    background-image: url("${process.env
        .PUBLIC_URL}/images/homepage/scooter-rental-1.webp"),
      url("${process.env.PUBLIC_URL}/images/icons/steps-bg.svg");
    background-repeat: no-repeat;
    background-position: bottom left 10%, center;
    background-size: 200px auto, cover;
  }
  &.link-2 {
    background-image: url("${process.env
      .PUBLIC_URL}/images/homepage/scooter-rental-2.webp");
    background-repeat: no-repeat;
    background-position: bottom left 20%;
    background-size: 160px auto;
  }
  &.link-3 {
    color: var(--color--accent, #f19e1d);
    border-color: var(--color--accent, #f19e1d);
    background-image: url("${process.env
      .PUBLIC_URL}/images/homepage/scooter-rental-3.webp");
    background-repeat: no-repeat;
    background-position: bottom left 80%;
    background-size: 140px auto;
  }

  & .text {
    max-width: 104px;
    margin-top: auto;

    color: var(--color--primary, #414141);
    font-size: 16px;
    font-weight: 400;
    line-height: 21px; /* 131.25% */
    text-transform: lowercase;
  }

  &::before {
    content: "";

    position: absolute;
    bottom: -80px;
    right: -80px;

    display: block;
    width: 128px;
    height: 128px;

    border-radius: 26px;
    background-color: #fff;
    border: 1px solid transparent;
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: "";

    position: absolute;
    bottom: 0;
    right: 0;

    display: block;
    width: 32px;
    height: 32px;

    background-image: url("${process.env
      .PUBLIC_URL}/images/icons/arrow-link.svg");

    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px;
    z-index: 1;
  }

  &.link-3:before {
    border-color: var(--color--accent, #f19e1d);
  }
  &.link-1:after {
    background-image: url("${process.env
      .PUBLIC_URL}/images/icons/arrow-link-1.svg");
  }

  &:hover {
    opacity: 0.8;
  }

  @media ${minDevice.tablet} {
    min-height: 285px;

    font-size: 32px;
    line-height: 40px; /* 125% */

    border-radius: 46px;

    &.link-1 {
      background-position: bottom left 68%, center;
      background-size: 320px auto, cover;

      & span {
        max-width: 240px;
      }
    }

    &.link-2 {
      background-position: bottom left 68%;
      background-size: 336px auto;

      & span {
        max-width: 240px;
      }
    }

    &.link-3 {
      background-position: bottom left 80%;
      background-size: 218px auto;

      & span {
        max-width: 300px;
      }
    }

    & .text {
      line-height: 24px; /* 150% */
    }

    &::before {
      width: 148px;
      height: 148px;

      bottom: -48px;
      right: -48px;

      border-radius: 46px;
    }

    &:after {
      width: 80px;
      height: 80px;

      background-size: 80px;
    }
  }
  @media ${minDevice.desktopL} {
    &.link-1 {
      background-position: bottom left 68%, center;
      background-size: 548px auto, cover;

      & span {
        max-width: none;
      }
    }

    &.link-2 {
      & span {
        max-width: 412px;
      }
    }

    &.link-3 {
      & span {
        max-width: 352px;
      }
    }

    & .text {
      font-size: 24px;
      line-height: 32px; /* 133.333% */
    }
  }
  @media ${minDevice.desktopXL} {
    font-size: 40px;
    line-height: 48px; /* 120% */
  }
`;