import { getCurrentLangObject } from "helpers/data";
import { text } from "data/text";
import { availableSymbol } from "common/constants";

export default function addSymbolAndPerDay(price, currentLang = 'en', perDay, free) {
  const symbol = availableSymbol[currentLang];

  let res = `${price} ${symbol}`;
  if (perDay) res = `${price} ${symbol} / ${getCurrentLangObject(text?.day, currentLang)}`;
  if (free) res = price ? `${price} ${symbol} / ${getCurrentLangObject(text?.day, currentLang)}` : getCurrentLangObject(text?.free, currentLang)?.toLowerCase();

  return res
}