import { compareStrings, includesString, isAvailableArray } from "helpers/data";

export default function filterDataToRender(dataList, filterParams) {
  const isAvailableDataList = isAvailableArray(dataList)?.length > 0;
  if (!isAvailableDataList || !filterParams) return null;

  const { category, types, brands, fuel, transmission } = filterParams ?? {};
  const isAvailableCategory = Boolean(category);
  const isAvailableFuel = compareStrings(fuel, "all", false);
  const isAvailableTransmission = compareStrings(transmission, "all", false);
  const isAvailableTypes = isAvailableArray(types)?.length > 0;
  const isAvailablebBrands = isAvailableArray(brands)?.length > 0;

  const filteredDataToRender = dataList?.filter((i) => {
    const isItemPassCategory = isAvailableCategory
      ? compareStrings(category, i?.category)
      : true;
    const isItemPassTypes = isAvailableTypes
      ? includesString(types, i?.body_type)
      : true;
    const isItemPassBrands = isAvailablebBrands
      ? includesString(brands, i?.brand)
      : true;
    const isItemPassFuel = isAvailableFuel
      ? compareStrings(fuel, i?.fuel?.type)
      : true;
    const isItemPassTransmission = isAvailableTransmission
      ? compareStrings(transmission, i?.gearbox)
      : true;
    const isItemPass =
      isItemPassCategory &&
      isItemPassTypes &&
      isItemPassBrands &&
      isItemPassFuel &&
      isItemPassTransmission;

    if (isItemPass) return i;

    return false
  });

  return filteredDataToRender;
}

export function filterDataToRender2(dataList, filterParams) {
  const isAvailableDataList = isAvailableArray(dataList)?.length > 0;
  if (!isAvailableDataList || !filterParams) return null;

  const { fuel, transmission } = filterParams ?? {};
  const isAvailableFuel = compareStrings(fuel, "all", false);
  const isAvailableTransmission = compareStrings(transmission, "all", false);

  const filteredDataToRender = dataList?.filter((i) => {
    const isItemPassFuel = isAvailableFuel
      ? compareStrings(fuel, i?.fuel?.type)
      : true;
    const isItemPassTransmission = isAvailableTransmission
      ? compareStrings(transmission, i?.gearbox)
      : true;
    const isItemPass = isItemPassFuel && isItemPassTransmission;

    if (isItemPass) return i;

    return false
  });

  return filteredDataToRender;
}