const { createService } = require("./createService");
const { deleteServiceById } = require("./deleteServiceById");
const { getServices } = require("./getServices");
const { updateServiceById } = require("./updateServiceById");

module.exports = {
  createService,
  deleteServiceById,
  getServices,
  updateServiceById,
};
