import React, { useEffect } from "react";
import { createPortal } from "react-dom";
// components
import SvgUse from "components/SvgUse/SvgUse";
// styled
import { ModalBackdrop, ModalContainer, ModaleBtnClose, } from "components/Common/common.styled";

const modalRoot = document.querySelector("#modal-root");

const Modal = ({ showModal, setShowModal, className = "", children }) => {
  useEffect(() => {
    if (showModal) document.body.classList.add("modalIsOpen");
    else document.body.classList.remove("modalIsOpen");
  }, [showModal]);

  return createPortal(
    <ModalBackdrop className={showModal ? `modalIsOpen ${className}` : className} >
      <ModalContainer>
        <ModaleBtnClose type="button" aria-label="Close modal" onClick={() => setShowModal(false)} >
          <SvgUse icontag={"x"} />
        </ModaleBtnClose>
        {children}
      </ModalContainer>
    </ModalBackdrop>,
    modalRoot
  );
};

export default Modal;
