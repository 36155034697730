import { isAvailableArray } from "helpers/data";

export default function serializeSearchParams(state) {
  const params = new URLSearchParams();
  if (state?.sDate) params.set("sDate", state?.sDate);
  if (state?.eDate) params.set("eDate", state?.eDate);
  if (state?.sTime) params.set("sTime", state?.sTime);
  if (state?.eTime) params.set("eTime", state?.eTime);

  if (state?.category) params.set("category", state?.category?.toLowerCase()?.replace(" +", "_plus"));
  if (state?.transmission) params.set("transmission", state?.transmission?.toLowerCase());
  if (state?.fuel) params.set("fuel", state?.fuel?.toLowerCase());
  if (isAvailableArray(state?.types)?.length > 0) params.set("types", state?.types?.join(",")?.toLowerCase());
  if (isAvailableArray(state?.brands)?.length > 0) params.set("brands", state?.brands?.join(",")?.toLowerCase());

  return params;
}

// const params = Object.fromEntries(newSearchParamsObj.entries());

// const state = {
//   categories: ['electronics', 'apparel'],
//   priceRange: {
//     min: 100,
//     max: 500,
//   },
//   sortBy: 'price-asc',
// };

// function serialize (state) {
//   const params = new URLSearchParams();
//   params.set('categories', state.categories.join(',')); // Comma-separated list
//   params.set('minPrice', state.priceRange.min); // Individual values
//   params.set('maxPrice', state.priceRange.max);
//   params.set('sortBy', state.sortBy);
//   return params;
// };


// const searchParamSchema = {
//   serialize: (stateObj) => encodeURIComponent(JSON.stringify(stateObj)),
//   deserialize: (paramString) => JSON.parse(decodeURIComponent(paramString))
// };

// const deserializeWithValidation = (paramString) => {
//   const deserialized = JSON.parse(decodeURIComponent(paramString));
//   if (isValidStateObj(deserialized)) {
//     return deserialized;
//   } else {
//     throw new Error('Invalid state object');
//   }
// };