export default function getIcon(btnType) {
  if (!btnType) return null;

  switch (btnType?.toLowerCase()) {
    case "add":
      return "plus";

    case "edit":
      return "pencil";

    case "delete":
      return "trash";

    case "details":
      return "document";

    case "refresh":
      return "cycle";

    default:
      break;
  }
}
