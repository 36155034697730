import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import { Navigation, Pagination, Keyboard } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function SwiperSliderText({ sliderList, className, slidesPerView }) {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Keyboard]}
      navigation
      pagination={{ clickable: true }}
      // set params
      slidesPerView={slidesPerView}
      spaceBetween={50}
      className={className}
    >
      {sliderList?.map((i, idx) => ( <SwiperSlide key={idx}>{i}</SwiperSlide> ))}
    </Swiper>
  );
}
