import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";

export const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;
export const Main = styled.main`
  position: relative;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  padding-top: 60px;

  @media ${minDevice.tablet} {
    padding-top: 87px;
  }
`;
