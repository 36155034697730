import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";

export const ThankYouBoxWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 48px;
  padding: 36px 32px;

  background-color: #fff;
  border-radius: 26px;

  @media ${minDevice.tablet} {
    border-radius: 46px;
  }
  @media ${minDevice.desktopXL} {
    padding: 120px 68px;
  }
`;
export const ThankYouBoxTitle = styled.strong`
  color: var(--color--accent, #f19e1d);
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;

  @media ${minDevice.tablet} {
    font-size: 48px;
    line-height: 56px;
  }
`;
export const ThankYouBoxText = styled.p`
  color: var(--color--primary, #414141);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
`;
