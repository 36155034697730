export default function getColorClass(string) {
  if (["accent"].includes(string)) return "accent";
  if (["new", "pending", "in park"].includes(string)) return "blue";
  if (["confirmed"].includes(string)) return "blueviolet";
  if (["in progress", "in rent"].includes(string)) return "orange";
  if (["completed", "active"].includes(string)) return "green";
  if (["canceled", "inactive"].includes(string)) return "gray";

  // if (["confirmed"].includes(string)) return "green";

  return "";
}
