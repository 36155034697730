import { createSlice } from "@reduxjs/toolkit";
import { createBooking, getBookingById, getBookingByVehiclesGroupId, getBookings, updateBookingById } from "./operations";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const handleSuccess = (state) => {
  state.status = "success";
  state.error = null;
};

const initialState = {};

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    resetBookingsState() { return initialState; },
    resetBookingsStatus(state) { state.status = ""; },
    resetBookingsError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createBooking.pending, (state, action) => { handlePending(state); })
      .addCase(createBooking.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(createBooking.fulfilled, (state, action) => { handleSuccess(state); })
      .addCase(getBookings.pending, (state, action) => { handlePending(state); })
      .addCase(getBookings.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getBookings.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(getBookingById.pending, (state, action) => { handlePending(state); })
      .addCase(getBookingById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getBookingById.fulfilled, (state, action) => { handleFulfilled(state);
        state.item = action.payload;
      })
      .addCase(updateBookingById.pending, (state, action) => { handlePending(state); })
      .addCase(updateBookingById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateBookingById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        state.item = action.payload;
      })
      .addCase(getBookingByVehiclesGroupId.pending, (state, action) => { handlePending(state); })
      .addCase(getBookingByVehiclesGroupId.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getBookingByVehiclesGroupId.fulfilled, (state, action) => { handleFulfilled(state);
        state.item = action.payload;
      })
});

export const { resetBookingsState, resetBookingsStatus, resetBookingsError } = bookingsSlice.actions;
export const bookingsReducer = bookingsSlice.reducer;
