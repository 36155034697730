import React from 'react';
// hooks
import { useLang, useText } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// styled
import { ChooseYourTravelBox1, ChooseYourTravelBox2, ChooseYourTravelBoxWrap, ChooseYourTravelImg, ChooseYourTravelSection, ChooseYourTravelTitle } from './SectionChooseYourTravel.styled';
import { Container } from 'components/Common/common.styled';

// ===== text translate START =====
const dataToTranslate = {
  // === DB & LOCAL ===
  textTitleRest: "pages.home.views.hero.title.rest",
  textTitleAccent: "pages.home.views.hero.title.accent",
}
// ===== text translate END =====

const SectionChooseYourTravel = () => {
  const { currentLang } = useLang();
  const { textDB } = useText();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang, textDB);
  const { 
    textTitleRest,
    textTitleAccent, 
  } = translate ?? {};
  // ===== text translate END =====

  return (
    <ChooseYourTravelSection>
      <Container>
        <ChooseYourTravelBoxWrap>
          <ChooseYourTravelBox1>
            <ChooseYourTravelTitle> <span>{textTitleAccent}</span> {textTitleRest} </ChooseYourTravelTitle>
            <ChooseYourTravelImg className='img-1' src={`${process.env.PUBLIC_URL}/images/homepage/1.webp`} alt='Car elements' width='400' />
            <ChooseYourTravelImg className='img-2' src={`${process.env.PUBLIC_URL}/images/homepage/2.webp`} alt='Car elements' width='400' />
            <ChooseYourTravelImg className='img-3' src={`${process.env.PUBLIC_URL}/images/homepage/3.webp`} alt='Car elements' width='400' />
          </ChooseYourTravelBox1>
          <ChooseYourTravelBox2>
            <ChooseYourTravelImg className='img-4' src={`${process.env.PUBLIC_URL}/images/homepage/4.webp`} alt='Scooter elements' width="400" />
            <ChooseYourTravelImg className='img-5' src={`${process.env.PUBLIC_URL}/images/homepage/5.webp`} alt='Scooter elements' width="400" />
            <ChooseYourTravelImg className='img-6' src={`${process.env.PUBLIC_URL}/images/homepage/6.webp`} alt='Scooter elements' width="400" />
            <ChooseYourTravelImg className='img-7' src={`${process.env.PUBLIC_URL}/images/homepage/7.webp`} alt='Scooter elements' width="400" />
          </ChooseYourTravelBox2>
        </ChooseYourTravelBoxWrap>
      </Container>
    </ChooseYourTravelSection>
  )
}

export default SectionChooseYourTravel