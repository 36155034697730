import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";
// components
import { CardSetItem, CardSetItemLabel, CardSetPlusItem } from "components/Common/Placeholders";

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  --gap: 8px;
  gap: var(--gap);
  padding: 16px;
`;
export const FormBox = styled(CardSetPlusItem)``;

export const FormTitle = styled.h2`
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));

  font-size: 20px;
  line-height: normal;
  font-weight: 700;
`;
export const FormBoxTitle = styled.h3`
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));

  font-size: 16px;
  line-height: normal;
  font-weight: 600;
`;

export const Label = styled(CardSetItemLabel)`
  position: relative;

  display: flex;
  // gap: 4px;

  &.column { flex-direction: column; }
`;
export const LabelWrap = styled(CardSetItem)``;
export const LabelText = styled.p`
  padding-left: 16px;

  font-size: 15px;
  font-weight: 300;
  line-height: normal;
`;

export const InputNone = styled.input`
   display: none;
`;
export const InputText = styled.input`
  width: 100%;
  height: 32px;
  padding: 4px 8px;

  color: var(--color--primary, #414141);
  font-size: inherit;
  line-height: 1;

  background-color: var(--color--bg, #f1f1f1);
  border: 1px solid transparent;
  border-radius: 4px;

  &:hover { box-shadow: var(--box-shadow--main); }
  &:focus-within { border-color: var(--color--accent, #f19e1d); }

  .disabled &, &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
  }

  &.public {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    height: 48px;
    border-radius: 26px;
  }

  &.hide {
    position: absolute;
    bottom: 0;

    z-index: -1;
    opacity: 0;
    pointer-events: none;
    cursor: auto;
  }


`;
export const Textarea = styled.textarea`
  width: 100%;
  min-height: 64px;
  padding: 4px 8px;

  color: var(--color--primary, #414141);
  font-size: inherit;
  line-height: 1;

  background-color: var(--color--bg, #f1f1f1);
  border: 1px solid transparent;
  border-radius: 4px;
  resize: none;

  &:hover { box-shadow: var(--box-shadow--main); }
  &:focus-within { border-color: var(--color--accent, #f19e1d); }

  .disabled &, &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
  }
`;

export const InputDateBtn = styled.button`
  flex-grow: 1;
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));

  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 48px;
  max-height: 48px;
  padding: 12px 12px 12px 54px;

  font-size: 16px;
  line-height: 1;

  background-color: var(--color--bg, #f1f1f1);
  border-radius: 26px;
  border: 1px solid transparent;
  transition: 20ms linear;
  cursor: pointer;

  &.bg-fff {
    background-color: #fff;
  }

  &::before {
    content: "";

    position: absolute;
    top: 12px;
    left: 22px;

    display: block;
    width: 20px;
    height: 20px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    pointer-events: none;
    z-index: 2;
  }

  &.date::before {
    background-image: url("${process.env.PUBLIC_URL}/images/icons/calendar.svg");
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
  }

  &:hover {
    color: var(--color--accent);
  }

  @media ${minDevice.tablet} {
    --items: 2;
  }

  @media ${minDevice.desktopL} {
    padding-left: 44px;

    &::before { left: 12px; }
  }

  .edit & {
    padding-left: 54px;
    border-radius: 8px;
    &::before { left: 22px; }
  }
`;
export const InputDateBtn2 = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 4px 8px;

  background-color: var(--color--bg, #f1f1f1);
  border: 1px solid transparent;
  border-radius: 4px;

  &:hover { box-shadow: var(--box-shadow--main); }
  &:focus-within { border-color: var(--color--accent, #f19e1d); }

  .disabled &, &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
  }
`;

export const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const CheckboxLabel = styled.label`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 2px;
  border: 2px solid var( --color--bg, #F1F1F1);
  background-color: #fff;
  cursor: pointer;
  transition: all 100ms linear;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    transition: 200ms linear;
    opacity: 0;
  }

  input:checked ~ & {
    background-color: var(--color--accent, #F19E1D);
    border-color: var(--color--accent, #F19E1D);
    background-image: url("${process.env.PUBLIC_URL}/images/icons/check.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
  }
`;