import React from "react";
// hooks
import { useLang } from "hooks";
// helpers
import { getCurrentLangPrice, getCurrentLangObject, isAvailableArray } from "helpers";
// common
import { text } from "data/text";
// styled
import { InsurancesListTag, InsuranceItem, InsuranceItemName, InsuranceItemPrice, InsuranceFeaturesList, InsuranceFeaturesItem, InsuranceAddBtn, } from "./InsurancesList.styled";

const InsurancesList = ({ insurancesList, handleAddInsurance, addBtn = false, className = '', style }) => {
  const { currentLang, symbol } = useLang();

  return (
    <InsurancesListTag className={className} style={style}>
      {isAvailableArray(insurancesList)?.map((i, idx) => {
        const insuranceName = i?.name;
        const insuranceFeatures = i?.options;
        const insurancePrice = getCurrentLangPrice(i?.price, currentLang)
        const insurancePriceToRender = insurancePrice === 0 
          ? getCurrentLangObject(text?.free, currentLang)
          : `${insurancePrice} ${symbol}`

        return (
          <InsuranceItem key={idx} className={`min-1440-items-3 ${className}`} style={style}>
            <InsuranceItemName>{insuranceName}</InsuranceItemName>
            <InsuranceItemPrice> {insurancePriceToRender} <span> {getCurrentLangObject(i?.price, currentLang)?.value !== 0 && `/ ${getCurrentLangObject(text?.day, currentLang)}`} </span> </InsuranceItemPrice>
            <InsuranceFeaturesList>
              {isAvailableArray(insuranceFeatures)?.map((i, idx) => (
                <InsuranceFeaturesItem key={idx} className={!i?.checked && "delete"} >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"/>
                  </svg>
                  {getCurrentLangObject(i, currentLang)}
                </InsuranceFeaturesItem>
              ))}
            </InsuranceFeaturesList>

            {addBtn && <InsuranceAddBtn onClick={() => handleAddInsurance(i)}> {getCurrentLangObject(text?.to_add, currentLang)} </InsuranceAddBtn>}
          </InsuranceItem>
        );
      })}
    </InsurancesListTag>
  );
};

export default InsurancesList;
