const { createSale } = require("./createSale");
const { getSaleById } = require("./getSaleById");
const { getSales } = require("./getSales");
const { updateSaleById } = require("./updateSaleById");

module.exports = {
  createSale,
  getSales,
  getSaleById,
  updateSaleById,
};