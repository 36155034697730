import React, { useState } from "react";
// components
import ContactsSn from "components/Contacts/ContactsSn";
// styled
import { SocialNetworksBox, SocialNetworksBtn, SocialNetworksWrap } from "./SocialNetworks.styled";

const SocialNetworks = ({ className }) => {
  const [showSN, setShowSN] = useState(false);

  return (
    <SocialNetworksWrap>
      <SocialNetworksBtn type="button" onClick={() => setShowSN(!showSN)}>Social Networks</SocialNetworksBtn>
      <SocialNetworksBox className={showSN && "active"}>
        <ContactsSn className={className} />
      </SocialNetworksBox>
    </SocialNetworksWrap>
  );
};

export default SocialNetworks;
