import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { maxDevice, minDevice } from "common/breakpoints";
// components
import { CardSetItemLi, CardSetUl } from "components/Common/Placeholders";

export const ContactsSnBox = styled.div`
  width: 100%;
  color: inherit;
  background-color: transparent;
`;
export const ContactsSnList = styled(CardSetUl)`
  .main & { --gap: 20px; }
  .footer & { --gap: 12px; }

  .header &,
  .burger-menu & {
    justify-content: center;
  }

  // @media ${minDevice.tablet} {
  //   .footer &,
  //   .main & {
  //     --gap: 160px;
  //     row-gap: 24px;
  //   }
  // }
  @media ${minDevice.tablet} {
    .main & { --gap: 100px;  row-gap: 24px; }
    .footer & { --gap: 200px;  row-gap: 24px; }
  }
`;
export const ContactsSnItem = styled(CardSetItemLi)`
  .header &,
  .burger-menu & {
    flex: 0 0 20px;
  }

  @media ${maxDevice.tablet} {
    &:nth-of-type(3) { order: 4; }
    &:nth-of-type(4) { order: 3; }
    &:nth-of-type(5) { order: 5; }
  }

  @media ${minDevice.tablet} {
    --items: 2;
  }

  @media ${minDevice.desktopL} {
    .main & { --items: 3; }
  }
`;

export const ContactsSnLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 16px;

  color: inherit;
  font-weight: 400;
  line-height: normal;

  transition: var(--transition--100);

  & svg {
    display: block;
    width: 32px;
    height: 32px;

    fill: var(--color--accent, #f19e1d);
  }

  .header & svg {
    width: 20px;
    height: 20px;
  }
  .burger-menu & svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: var(--color--accent, #f19e1d);
  }

  .header &:hover,
  .burger-menu &:hover { 
    transform: scale(1.1);
  }

  @media ${maxDevice.tablet} {
    .footer & {
      flex-direction: column;
      gap: 2px;
    }
  }

  @media ${minDevice.desktopL} {
    .main & { font-size: 24px; }

    & svg {
      flex: 0 0 36px;
      width: 36px;
      height: 36px;
    }
  }
`;
export const ContactsSnTitle = styled.span`
  .header &,
  .burger-menu & {
    display: none;
  }
`;
