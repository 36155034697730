export const selectVehiclesStatus = (state) => state?.vehicles?.status;
export const selectVehiclesError = (state) => state?.vehicles?.error;
// === public rental ===
export const selectPublicRentalVehicles = (state) => state?.vehicles?.publicRentalVehicles;
// === private rental ===
export const selectRentalVehicles = (state) => state?.vehicles?.rentalVehicles;
export const selectRentalVehiclesGroup = (state) => state?.vehicles?.rentalVehiclesGroup;
export const selectRentalVehicle  = (state) => state?.vehicles?.rentalVehicle;

// === private sales ===
export const selectSalesVehicles = (state) => state?.vehicles?.salesVehicles;
export const selectSalesVehicle  = (state) => state?.vehicles?.salesVehicle;



// === search ===
export const selectVehiclesSearchList = (state) => state?.vehicles?.searchList;
export const selectVehiclesSearchParams = (state) => state?.vehicles?.searchParams;
export const selectVehiclesFiltersParams = (state) => state?.vehicles?.filtersParams;
// === search ===
