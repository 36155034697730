// === public rental ===
const { getPublicRentalVehicles } = require("./getPublicRentalVehicles");
// === private ById ===
const { createVehicle } = require("./createVehicle");
const { updateVehicleById } = require("./updateVehicleById");
const { deleteVehicleById } = require("./deleteVehicleById");
// === private rental ===
const { getRentalVehicles } = require("./getRentalVehicles");
// === private rental ByGroupId ===
const { getRentalVehiclesByGroupId } = require("./getRentalVehiclesByGroupId");
const { addRentalVehicleByGroupId } = require("./addRentalVehicleByGroupId");
const { updateRentalVehiclesByGroupId, } = require("./updateRentalVehiclesByGroupId");
const { deleteRentalVehiclesByGroupId, } = require("./deleteRentalVehiclesByGroupId");

// === public sale ===
const { getPublicSaleVehicles } = require("./getPublicSaleVehicles");
// === sale ===
const { getSaleVehicles } = require("./getSaleVehicles");
const { getSaleVehicleById } = require("./getSaleVehicleById");
// === sales ===
const { getSalesVehicles } = require("./getSalesVehicles");
const { getSalesVehicleById } = require("./getSalesVehicleById");
const { updateSalesVehicleById } = require("./updateSalesVehicleById");
// === search ===
const { getVehiclesBySearchQuery } = require("./getVehiclesBySearchQuery");
const { getVehiclesBySearchQueryAndGroupId, } = require("./getVehiclesBySearchQueryAndGroupId");
// === other ===
const { getRentalVehicleById } = require("./getRentalVehicleById");

module.exports = {
  // === public rental ===
  getPublicRentalVehicles,
  // === private ById ===
  createVehicle,
  updateVehicleById,
  deleteVehicleById,
  // === private rental ===
  getRentalVehicles,
  // === private rental ByGroupId ===
  getRentalVehiclesByGroupId,
  addRentalVehicleByGroupId,
  updateRentalVehiclesByGroupId,
  deleteRentalVehiclesByGroupId,

  // === public sale ===
  getPublicSaleVehicles,
  // === sale ===
  getSaleVehicles,
  getSaleVehicleById,
  // === sales ===
  getSalesVehicles,
  getSalesVehicleById,
  updateSalesVehicleById,

  // === search ===
  getVehiclesBySearchQuery,
  getVehiclesBySearchQueryAndGroupId,

  // === other ===
  getRentalVehicleById,
};

