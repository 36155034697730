import styled from "styled-components";

export const DatepickerBoxWrap = styled.div`
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;

  border-radius: 26px;
  background-color: #fff;
`;
export const DatepickerBox = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 300px;
  padding: 16px;
`;

export const DatepickerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;
export const DatepickerMain = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  border-top: 1px solid #e7eaee;
`;
export const DatepickerFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

// === DatepickerHeader ===
export const DatepickerBtnMonthPrev = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;

  background-color: transparent;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  transition: var(--transition--100);

  & svg {
    flex: 0 0 16px;
    stroke: #414141;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.8;
    box-shadow: var(--box-shadow--main);
    border-color: var(--color--accent, #f19e1d);

    & svg {
      stroke: var(--color--accent, #f19e1d);
    }
  }
`;
export const DatepickerBtnMonthNext = styled(DatepickerBtnMonthPrev)`
  & svg {
    transform: rotate(180deg);
  }
`;
export const DatepickerBtnMonthCurrent = styled.button`
  flex: 1 0 100px;

  color: var(--color--primary, #414141);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-align: center;

  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  // &:hover {
  //   color: var(--color--accent, #f19e1d);

  //   opacity: 0.8;
  //   box-shadow: var(--box-shadow--main);
  //   border-color: var(--color--accent, #f19e1d);
  // }
`;
// === DatepickerHeader ===

// === DatepickerMain ===
export const DatepickerWeekdaysList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  --gap: 1px;
  gap: var(--gap);
`;
export const DatepickerWeekdaysItem = styled.li`
  --items: 7;
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 44px;
`;
export const DatepickerDaysList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  --gap: 1px;
  gap: var(--gap);
`;
export const DatepickerDaysItem = styled.li`
  --items: 7;
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
`;

export const DatepickerDaysItemBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 44px;

  background-color: #fff;
  border: 0;
  border-radius: 4px;

  &.active-light {
    background-color: #f4e8d4;
  }
  &.active {
    color: #fff;
    background-color: var(--color--accent, #f19e1d);
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    color: var(--color--accent, #f19e1d);

    opacity: 0.8;
    box-shadow: var(--box-shadow--main);
    border-color: var(--color--accent, #f19e1d);
  }

  &.active:hover {
    color: #fff;
  }
`;

// === DatepickerMain ===

// === DatepickerFooter ===
export const DatepickerBtnSet = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  width: 100%;
  height: 44px;

  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;

  background-color: var(--color--accent, #f19e1d);
  border: 0;
  border-radius: 26px;

  & svg { fill: currentColor; }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.8;
    box-shadow: var(--box-shadow--main);
  }
`;
export const DatepickerBtnCancel = styled(DatepickerBtnSet)`
  color: var(--color--primary, #414141);
  background-color: #E7EAEE;
`;
// === DatepickerFooter ===
