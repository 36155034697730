import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { minDevice } from "common/breakpoints";
import { Container, SectionSubTitle } from "components/Common/common.styled";
import { CardSetItem, CardSetItemLi, CardSetUl } from "components/Common/Placeholders";

export const SectionCarForSaleTag = styled.section`
  width: 100%;
  padding: 16px 0;

  border-radius: 26px;
  background-color: var(--color--bg, #F1F1F1);

  @media ${minDevice.tablet} {
    padding: 72px 0;

    border-radius: 46px;
  }
`;

export const SectionCarForSaleContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 48px;

  overflow: hidden;

  @media ${minDevice.desktopL} {
    gap: 80px;
  }
`;

export const SectionCarForSaleSubTitle = styled(SectionSubTitle)`
  margin-bottom: 18px;
  
  @media ${minDevice.tablet} {
    margin-bottom: 22px;
  }
  @media ${minDevice.desktopL} {
    margin-bottom: 32px;
  }
`;


export const SectionCarForSaleBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionCarForSaleList = styled(CardSetUl)`
  align-items: center;
  --gap: 4px;

  @media ${minDevice.desktopL} {
    --gap: 16px;
  }
`;
export const SectionCarForSaleItem = styled(CardSetItemLi)`
  position: relative;

  flex-shrink: 0;
  min-height: 164px;
  padding: 48px 80px 48px 16px;

  background-color: #fff;
  border-radius: 46px;

  &::before {
    content: "";

    position: absolute;
    top: 48px;
    right: 16px;

    display: block;
    width: 56px;
    height: 56px;

    border-radius: 50%;
    background-color: var(--color--bg, #F1F1F1);
    border: 1px solid transparent;
    // z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: "";

    position: absolute;
    top: 48px;
    right: 16px;

    display: block;
    width: 56px;
    height: 56px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
  }

  &:nth-of-type(1)::after { background-image: url('${process.env.PUBLIC_URL}/images/car-for-sale/1.svg'); }
  &:nth-of-type(2)::after { background-image: url('${process.env.PUBLIC_URL}/images/car-for-sale/2.svg'); }
  &:nth-of-type(3)::after { background-image: url('${process.env.PUBLIC_URL}/images/car-for-sale/3.svg'); }
  &:nth-of-type(4)::after { background-image: url('${process.env.PUBLIC_URL}/images/car-for-sale/4.svg'); }
  &:nth-of-type(5)::after { background-image: url('${process.env.PUBLIC_URL}/images/car-for-sale/5.svg'); }
  &:nth-of-type(6)::after { background-image: url('${process.env.PUBLIC_URL}/images/car-for-sale/6.svg'); }
  &:nth-of-type(7)::after { background-image: url('${process.env.PUBLIC_URL}/images/car-for-sale/7.svg'); }
  &:nth-of-type(8)::after { background-image: url('${process.env.PUBLIC_URL}/images/car-for-sale/8.svg'); }

  &:nth-of-type(2) {
    color: #fff;
    background: linear-gradient(90deg, #434343 0%, #000 100%);
    background-image: url('${process.env.PUBLIC_URL}/images/icons/steps-bg-2.svg');
  }

  // &:hover {
  //   color: #fff;
  //   background: linear-gradient(90deg, #434343 0%, #000 100%);
  //   // background-image: url('${process.env.PUBLIC_URL}/images/icons/steps-bg-2.svg');
  // }
 
  @media ${minDevice.tablet} {
    --items: 2;
    min-height: 200px;
  }

  @media ${minDevice.desktopL} {
    --items: 4;
  }
`;
export const SectionCarForSaleItemText = styled.p`
  font-family: "Helvetica Now Display";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px; /* 120% */
  text-transform: uppercase;

  @media ${minDevice.desktopXL} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const SectionCarForSaleBox3List = styled(CardSetUl)`
  --gap: 4px;

  @media ${minDevice.desktopL} {
    --gap: 16px;
  }
`;
export const SectionCarForSaleBox3Item = styled(CardSetItemLi)`
  padding: 28px;

  text-align: center;
  line-height: 24px; /* 150% */

  background-color: #fff;
  border-radius: 46px;
  overflow: hidden;

  &:nth-of-type(1) {
    border: 1px solid transparent;
    border-color: var(--color--accent, #F19E1D)
  }

  &:nth-of-type(3) {
    color: #fff;
    background: linear-gradient(90deg, #434343 0%, #000 100%);
    background-image: url('${process.env.PUBLIC_URL}/images/icons/steps-bg-2.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

 
  @media ${minDevice.tablet} {
    --items: 2;

    font-size: 18px;
    line-height: 28px;
  }

  @media ${minDevice.desktopL} {
    --items: 4;

    font-size: 20px;
    line-height: 30px; /* 150% */
  }
`;


export const SectionCarForSaleBox2Inner = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media ${minDevice.tablet} {
    gap: 80px;
  }
  @media ${minDevice.desktopL} {
    flex-direction: row;
    --gap: 40px;
    gap: var(--gap);
    --items: 2;
  }
  @media ${minDevice.desktopXL} {
    --gap: 92px;
  }
`;
export const SectionCarForSaleBox2List = styled.ul`
  --items: 2;
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));

  display: flex;
  flex-wrap: wrap;

  --gap: 4px;
  gap: var(--gap);
  row-gap: 8px;

  @media ${minDevice.desktopL} {
    --gap: 16px;
    row-gap: 16px;
  }
`;
export const SectionCarForSaleBox2Item = styled(CardSetItemLi)`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px;

  font-size: 18px;
  line-height: 22px; /* 116.667% */
  text-align: center;

  background-color: #fff;
  border-radius: 26px;

  &:nth-of-type(3n+1) {
    --items: 1;
  }

  &:nth-of-type(1) {
    font-weight: 700;
    text-transform: uppercase;

    border: 1px solid transparent;
    border-color: var(--color--accent, #F19E1D);
  }

  @media ${minDevice.tablet} {
    --items: 2;

    font-size: 24px;
    line-height: 32px; /* 133.333% */

    border-radius: 46px;

    &:nth-of-type(1)::before {
      content: "";

      position: absolute;
      bottom: -28px;
      right: -52px;
      transform: rotate(34deg);
  
      display: block;
      width: 72px;
      height: 72px;

      background-image: url("${process.env.PUBLIC_URL}/images/car-for-sale/arrow.svg");

      background-repeat: no-repeat;
      background-position: center;
      background-size: 72px;
    }
  }
`;

export const SectionCarForSaleBox2LinkWrap = styled(CardSetItem)`
  --items: 2;

  position: relative;
  overflow: hidden;
`;
export const SectionCarForSaleBox2Link = styled(Link)`
  position: relative;

  display: flex;
  justify-content: center;
  padding: 20px;
  min-height: 600px;
  width: 100%;

  color: #fff;
  font-family: "Helvetica Now Display";
  font-size: 20px;
  font-weight: 700;
  line-height: 30px; /* 150% */
  text-transform: uppercase;

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%), url('${process.env.PUBLIC_URL}/images/car-for-sale/car.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 26px;

  &::before {
    content: "";

    position: absolute;
    bottom: -80px;
    right: -80px;

    display: block;
    width: 128px;
    height: 128px;

    border-radius: 26px;
    background-color: var( --color--bg, #F1F1F1);
    border: 1px solid transparent;
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: "";

    position: absolute;
    bottom: 0;
    right: 0;

    display: block;
    width: 32px;
    height: 32px;

    background-image: url("${process.env.PUBLIC_URL}/images/icons/arrow-link.svg");

    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px;
    z-index: 1;
  }

  & .text {
    display: block;
    max-width: 160px;
  }
  & .accent {
    color: var( --color--accent, #F19E1D);
  }

  &:hover {
    opacity: 0.8;
  }


  @media ${minDevice.tablet} {
    padding: 40px;
    height: 760px;

    font-size: 32px;
    line-height: 40px;

    border-radius: 46px;

    &::before {
      width: 148px;
      height: 148px;

      bottom: -48px;
      right: -48px;

      border-radius: 46px;
    }

    &:after {
      width: 80px;
      height: 80px;

      background-size: 80px;
    }

    & .text {
      max-width: 260px;
    }
  }
  @media ${minDevice.desktopXL} {
    padding: 48px;

    font-size: 46px;
    line-height: 56px;

    & .text {
      max-width: 360px;
    }
  }
`;

export const SectionCarForSaleBox2LinkList = styled.ul`
  position: absolute;
  // top: 68px;
  top: 88px;
  --left: 16px;
  left: var(--left);

  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - var(--left) * 2);
  padding: 8px 4px;

  border-radius: 16px;
  background: rgba(65, 65, 65, 0.60);
  pointer-events: none;

  @media ${minDevice.tablet} {
    top: 108px;

    padding: 16px;
  }
  @media ${minDevice.desktopL} {
    top: 136px;
  }
  @media ${minDevice.desktopXL} {
    top: 168px;
  }
`;
export const SectionCarForSaleBox2LinkItem = styled.li`
  position: relative;

  display: flex;
  min-height: 32px;
  padding-left: 58px;

  color: #fff;
  font-size: 16px;
  line-height: 22px;

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 32px;
    height: 32px;

    background-image: url("${process.env.PUBLIC_URL}/images/car-for-sale/check.svg");

    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px;
    z-index: 1;
  }

  @media ${minDevice.tablet} {
    font-size: 24px;
    line-height: 28px;
  }
  @media ${minDevice.desktopXL} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const SectionCarForSaleBtn = styled(Link)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;
  margin: 0 auto;

  color: #fff;
  font-family: "Helvetica Now Display";
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  border-radius: 36px;
  border: 0;
  background-color: var(--color--accent, #F19E1D);

  &:hover {
    opacity: 0.8;
  }

  @media ${minDevice.desktopL} {
    width: 288px;
    padding: 12px 36px;
  }
  // @media ${minDevice.desktopXL} {
  //   width: 288px;
  //   padding: 12px 36px;
  // }
`;
