import React from 'react'
// hooks
import { useLang } from 'hooks';
// helpers
import { getTextList, getTranslate, isAvailableArray } from 'helpers';
// styled
import { CountARentPriceBox, CountARentPriceBoxBtnClose, CountARentPriceBoxOptionsItem, CountARentPriceBoxOptionsList, CountARentPriceBoxText, CountARentPriceBoxTitle } from './CountARentPrice.styled';
// ===== text translate START =====
const dataToTranslate = {
  // === DB & LOCAL ===
  textTitle: "views.how_we_count_a_rent_price.title",
  textForExampleIfYouRentACar: "views.how_we_count_a_rent_price.text_for_example_if_you_rent_a_car",
  // === LOCAL ===
  textCloseIt: "close_it",
}
// ===== text translate END =====

const CountARentPrice = ({ setShowModal }) => {
  const { currentLang } = useLang();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const {
    // === obj ===
    textTitle,
    textForExampleIfYouRentACar,
    textCloseIt,
  } = translate ?? {};
  // ===== text translate END =====
  // ===== text list START =====
  const textList = getTextList(null, "views.how_we_count_a_rent_price.text_list", currentLang);
  // ===== text list END =====
  const countARentPriceListDataToRender = isAvailableArray(textList)?.map(i => ({name: i, checked: true}))

  return (
    <CountARentPriceBox>
      <CountARentPriceBoxTitle>{textTitle}</CountARentPriceBoxTitle>
      <CountARentPriceBoxText>{textForExampleIfYouRentACar}</CountARentPriceBoxText>
      <CountARentPriceBoxOptionsList>
        {countARentPriceListDataToRender?.map((i, idx) => ( 
          <CountARentPriceBoxOptionsItem key={idx}> 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"/>
            </svg>
            {i?.name} 
          </CountARentPriceBoxOptionsItem> 
        ))}
      </CountARentPriceBoxOptionsList>
      <CountARentPriceBoxBtnClose type='button' onClick={() => setShowModal(false)}>{textCloseIt} </CountARentPriceBoxBtnClose>
    </CountARentPriceBox>
  )
}

export default CountARentPrice