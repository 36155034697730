import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";
import { CardSetItemLi, CardSetUl } from "components/Common/Placeholders";

export const HowDoesItWorkSection = styled.section`
  width: 100%;
  padding: 80px 0;

  @media ${minDevice.desktopXL} {
    padding: 132px 0;
  }
`;

export const HowDoesItWorkStepsList = styled(CardSetUl)`
  --gap: 24px;

  @media ${minDevice.desktopXL} {
    --gap: 8px;
  }
`;
export const HowDoesItWorkStepsItem = styled(CardSetItemLi)`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 44px 28px 88px;

  border-radius: 26px;
  background: #f1f1f1;
  background-image: url("${process.env.PUBLIC_URL}/images/icons/steps-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media ${minDevice.tablet} {
    gap: 32px;
    padding: 44px 148px 92px 68px;

    border-radius: 46px;
  }

  @media ${minDevice.desktopL} {
    --items: 3;
    padding: 32px 100px 64px 48px;
  }

  &:nth-of-type(1)::after {
    content: "01";
  }
  &:nth-of-type(2)::after {
    content: "02";
  }
  &:nth-of-type(3)::after {
    content: "03";
  }

  &::after {
    position: absolute;
    bottom: 28px;
    right: 28px;

    color: rgba(65, 65, 65, 0.5);
    font-family: "Helvetica Now Display";
    font-size: 24px;
    font-weight: 700;

    opacity: 0.5;

    @media ${minDevice.tablet} {
      bottom: 40px;

      font-size: 36px;
    }

    @media ${minDevice.desktopL} {
      bottom: 24px;
      right: 32px;
    }
  }

  &:not(:last-of-type)::before {
    content: "";

    position: absolute;
    bottom: -48px;
    right: 12px;

    display: block;
    width: 90px;
    height: 64px;

    background-image: url("${process.env
      .PUBLIC_URL}/images/icons/steps-arrow-1.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;

    @media ${minDevice.tablet} {
      width: 98px;
      height: 72px;
    }

    @media ${minDevice.desktopL} {
      bottom: 24px;
      right: -188px;

      width: 220px;
      height: 32px;

      background-image: url("${process.env
        .PUBLIC_URL}/images/icons/steps-arrow-2.svg");
    }
  }
`;

export const HowDoesItWorkStepTitleWrap = styled.div`
  position: relative;

  &::after {
    content: "";

    position: absolute;
    right: 0;
    top: 0;

    display: block;
    width: 32px;
    height: 32px;

    border-radius: 50%;
    background-color: var(--color--white, #fff);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;

    li:nth-of-type(1) & {
      background-image: url("${process.env.PUBLIC_URL}/images/icons/zoom-out.svg");
    }
    li:nth-of-type(2) & {
      background-image: url("${process.env.PUBLIC_URL}/images/icons/car.svg");
    }
    li:nth-of-type(3) & {
      background-image: url("${process.env.PUBLIC_URL}/images/icons/edit.svg");
    }

    @media ${minDevice.tablet} {
      right: -120px;

      width: 48px;
      height: 48px;

      background-size: 28px;
    }

    @media ${minDevice.desktopL} {
      right: -72px;
    }
  }
`;
export const HowDoesItWorkStepTitle = styled.h3`
  font-family: "Helvetica Now Display";
  font-size: 24px;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  text-transform: uppercase;

  @media ${minDevice.desktopXL} {
    font-size: 40px;
    line-height: 56px; /* 140% */
  }
`;
export const HowDoesItWorkStepText = styled.p`
  line-height: 21px; /* 131.25% */
`;