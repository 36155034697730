import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";

export const HomePageSectionTitle = styled.h2`
  margin-bottom: 18px;

  color: var(--color--primary, #414141);
  font-family: "Helvetica Now Display";
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;

  @media ${minDevice.tablet} {
    // max-width: 480px;
    margin: 0 auto 22px;

    font-size: 32px;
    line-height: 40px;
  }
  @media ${minDevice.desktopXL} {
    max-width: none;
    margin-bottom: 72px;

    font-size: 48px;
    line-height: 56px;
  }

  & span {
    color: var(--color--accent, #f19e1d);
  }
`;
