import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";
// components
import { CardSetItemLi, CardSetUl } from "components/Common/Placeholders";
// styled
import { Container } from "components/Common/common.styled";

export const BenefitsSection = styled.section`
  width: 100%;
  padding: 28px 0;
  margin-bottom: 32px;

  background-color: #fff;
  border-radius: 26px;

  @media ${minDevice.tablet} {
    padding: 28px 0 48px;
    margin-bottom: 86px;

    border-radius: 46px;
  }
  @media ${minDevice.desktopL} {
    padding: 40px 0 60px;
    margin-bottom: 200px;
  }
`;
export const BenefitsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media ${minDevice.tablet} {
    gap: 28px;
  }
  @media ${minDevice.desktopXL} {
    gap: 52px;
  }
`;

export const BenefitsList = styled(CardSetUl)`
  --gap: 16px;

  @media ${minDevice.tablet} {
    --gap: 8px;
    row-gap: 36px;
  }
  @media ${minDevice.desktopL} {
    row-gap: 44px;
  }
`;
export const BenefitsItem = styled(CardSetItemLi)`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 44px;
  min-height: 160px;
  padding: 32px 24px 48px;

  border-radius: 26px;
  background-color: #f1f1f1;
  background-image: url("${process.env.PUBLIC_URL}/images/icons/steps-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  &::before {
    content: "";

    position: absolute;
    // top: -48px;
    // right: -48px;
    top: -44px;
    right: -44px;

    display: block;
    width: 128px;
    height: 128px;

    // border-radius: 26px;
    border-radius: 46px;
    background-color: #fff;
  }

  @media ${minDevice.tablet} {
    --items: 2;
    padding: 32px 32px 48px;

    border-radius: 46px;

    &::before {
      top: -40px;
      right: -40px;

      border-radius: 46px;
    }
  }

  @media ${minDevice.desktopL} {
    --items: 3;
    padding: 48px;

    &::before {
      top: -40px;
      right: -40px;

      width: 152px;
      height: 152px;
    }
  }
`;

export const BenefitTitleWrap = styled.div`
  position: relative;
  padding-right: 72px;

  @media ${minDevice.desktopL} {
    padding-right: 90px;
  }

  &::after {
    content: "";

    position: absolute;
    top: -18px;
    right: -12px;
    // top: -16px;
    // right: -8px;

    display: block;
    width: 48px;
    height: 48px;

    border-radius: 50%;
    background-color: var(--color--accent, #f19e1d);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px;

    li:nth-of-type(1) & { background-image: url("${process.env.PUBLIC_URL}/images/icons/benefit-1.svg"); }
    li:nth-of-type(2) & { background-image: url("${process.env.PUBLIC_URL}/images/icons/benefit-2.svg"); }
    li:nth-of-type(3) & { background-image: url("${process.env.PUBLIC_URL}/images/icons/benefit-3.svg"); }
    li:nth-of-type(4) & { background-image: url("${process.env.PUBLIC_URL}/images/icons/benefit-4.svg"); }
    li:nth-of-type(5) & { background-image: url("${process.env.PUBLIC_URL}/images/icons/benefit-5.svg"); }
    li:nth-of-type(6) & { background-image: url("${process.env.PUBLIC_URL}/images/icons/benefit-6.svg"); }

    @media ${minDevice.tablet} {
      top: -16px;
      right: -16px;

      width: 52px;
      height: 52px;

      background-size: 36px;
    }

    @media ${minDevice.desktopL} {
      top: -32px;
      right: -32px;

      width: 72px;
      height: 72px;

      background-size: 48px;
    }
  }
`;
export const BenefitTitle = styled.h3`
  font-family: "Helvetica Now Display";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  text-transform: uppercase;
`;
export const BenefitText = styled.p`
  margin-top: auto;
`;
