import { isAvailableArray, isAvailableObject } from "./checkIsDataAvailable";

export function getCurrentLangObject(data, currentLang) {
  const res = isAvailableObject(data)[currentLang];
  return res;
}
export function getCurrentLangArray(data, currentLang) {
  const res = isAvailableArray(data)[currentLang];
  return res;
}
export function getCurrentLangPrice(obj, currentLang) {
  switch (currentLang) {
    case "en":
      return Number(isAvailableObject(obj)?.eur);
    case "cz":
      return Number(isAvailableObject(obj)?.czk);

    default:
      break;
  }
}
