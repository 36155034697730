import React from "react";
// hooks
import { useLang, useText } from "hooks";
// helpers
import { getItemsList, getTranslate } from "helpers";
// components
import SwiperSliderText from "components/SwiperSlider/SwiperSliderText";
// styled
import { ReviewsContainer, ReviewsSection, ReviewsSwiperBox, ReviewsSwiperItem, ReviewsSwiperItemText } from "./Reviews.styled";
import { SectionTitle } from "components/Common/common.styled";

// ===== text translate START =====
const dataToTranslate = {
  // === DB & LOCAL ===
  textTitleRest: "views.reviews.title.rest",
  textTitleAccent: "views.reviews.title.accent",
}
// ===== text translate END =====

const Reviews = () => {
  const { currentLang } = useLang();
  const { textDB } = useText();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang, textDB);
  const { 
    textTitleRest,
    textTitleAccent,
  } = translate ?? {};
  // ===== text translate END =====
  // ===== text list START =====
  const itemsListReviews = getItemsList(textDB, "views.reviews.items_list");
  // ===== text list END =====


  const sliderList = itemsListReviews?.map(i => (
    <ReviewsSwiperItem>
      <ReviewsSwiperItemText>{i[currentLang]}</ReviewsSwiperItemText>
    </ReviewsSwiperItem>
  ))

  return (
    <ReviewsSection>
      <ReviewsContainer>
        <SectionTitle> <span>{textTitleAccent}</span> {textTitleRest} </SectionTitle>
        <ReviewsSwiperBox>
          {sliderList && 
            <SwiperSliderText
              sliderList={sliderList}
              className={"reviews-swiper"}
              slidesPerView={1} 
            />
          }
        </ReviewsSwiperBox>
      </ReviewsContainer>
    </ReviewsSection>
  );
};

export default Reviews;
