export function sortStings(arr, direction) {
  return [...arr]?.sort((a, b) => {
    if (direction) return a?.toLowerCase()?.localeCompare(b?.toLowerCase());
    return b?.toLowerCase()?.localeCompare(a?.toLowerCase());
  });
}

export function sortStings1key(arr, direction, key) {
  return [...arr]?.sort((a, b) => {
    if (direction) return a[key]?.toLowerCase()?.localeCompare(b[key]?.toLowerCase());
    return b[key]?.toLowerCase()?.localeCompare(a[key]?.toLowerCase());
  });
}
export function sortStings2keys(arr, direction, key1, key2) {
  return [...arr]?.sort((a, b) => {
    if (direction) return a[key1][key2]?.localeCompare(b[key1][key2]);
    return b[key1][key2]?.localeCompare(a[key1][key2]);
  });
}
export function sortStings3keys(arr, direction, key1, key2, key3) {
  return [...arr]?.sort((a, b) => {
    if (direction) return a[key1][key2][key3]?.localeCompare(b[key1][key2][key3]);
    return b[key1][key2][key3]?.localeCompare(a[key1][key2][key3]);
  });
}
