import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const NavTag = styled.nav`
  &.footer { max-width: 400px; }
  &.burger-menu { width: 100%; }
`;
export const NavList = styled.ul`
  display: flex;
  align-items: center;
  gap: 24px;

  .footer & {
    flex-wrap: wrap;
    row-gap: 36px;
  }

  .burger-menu & {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
`;
export const NavItem = styled.li`
  .header &.faq { display: none; }
  // .footer &.sale { margin-right: 300px; }
  .burger-menu & { padding: 8px 12px; }
`;
export const NavItemLink = styled(NavLink)`
  transition: var(--transition--100);

  .header & {
    color: var(--color--primary, #414141);
    text-transform: uppercase;
  }

  .footer & {
    color: var(--color--white, #fff);
  }

  .burger-menu & {
    color: var(--color--primary, #414141);
    text-transform: uppercase;
  }

  &.active {
    position: relative;

    &::before {
      content: "";

      position: absolute;
      left: -13px;

      display: block;
      width: 1px;
      height: 24px;

      background-color: var(--color--accent, #f19e1d);
    }
  }
  .header &.active {
    color: var(--color--accent, #f19e1d);
  }
  .burger-menu &.active {
    color: var(--color--accent, #f19e1d);
  }

  &:hover {
    color: var(--color--accent, #f19e1d);
  }
`;

export const NavItemBtn = styled.button`
  border: none;
  background-color: transparent;
  transition: var(--transition--100);

  .header & {
    color: var(--color--primary, #414141);
    text-transform: uppercase;
  }

  .footer & {
    color: var(--color--white, #fff);
  }

  &.active {
    position: relative;

    &::before {
      content: "";

      position: absolute;
      left: -13px;

      display: block;
      width: 1px;
      height: 24px;

      background-color: var(--color--accent, #f19e1d);
    }
  }
  .header &.active {
    color: var(--color--accent, #f19e1d);
  }

  &:hover {
    color: var(--color--accent, #f19e1d);
  }
`;
