import { checkIsEmptyArray } from "helpers/data";
import { getDatesBetweenDates } from "helpers/dateTime";

export default function checkAvailableDates(newStartDay, newEndDay, bookings) {
  const newDates = getDatesBetweenDates(newStartDay, newEndDay)?.map((i) => i?.toISOString());
  const unavailableDates = bookings?.filter((i) => newDates?.includes(i?.date));
  const areNewDatesAvailable = checkIsEmptyArray(unavailableDates);

  const res = { unavailableDates, areNewDatesAvailable };
  return res;
}
