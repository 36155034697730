export function sortDates(arr, grow) {
  return [...arr]?.sort((a, b) => {
    if (grow) return a.replaceAll("-", "") - b.replaceAll("-", "");
    return b.replaceAll("-", "") - a.replaceAll("-", "");
  });
}

export function sortDates1key(arr, grow, key) {
  return [...arr]?.sort((a, b) => {
    if (grow) return a[key].replaceAll("-", "") - b[key].replaceAll("-", "");
    return b[key].replaceAll("-", "") - a[key].replaceAll("-", "");
  });
}

export function sortLocalDates1key(arr, grow, key) {
  return [...arr]?.sort((a, b) => {
    if (grow) return a[key].replaceAll(".", "") - b[key].replaceAll(".", "");
    return b[key].replaceAll(".", "") - a[key].replaceAll(".", "");
  });
}


export function sortDatesTimestamp2keys(arr, grow, key1, key2) {
  return [...arr]?.sort((a, b) => {
    const dateA = new Date(a[key1][key2]).getTime();
    const dateB = new Date(b[key1][key2]).getTime();
  
    if (grow) return dateA - dateB;
    return dateB - dateA;
  });
}