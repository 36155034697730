export default function generateSearchUrl(params) {
  if (!params) return "";
  return params?.toString()?.replaceAll("%3A", ":")?.replaceAll("%2B", "")?.replaceAll("%2C", ",");
}

// %2C
// ====================
// function standardEncoding(v: string): string {
//   return encodeURIComponent(v)
//   .replace(/%40/gi, '@')
//   .replace(/%3A/gi, ':')
//   .replace(/%24/gi, '$')
//   .replace(/%2C/gi, ',')
//   .replace(/%3B/gi, ';')
//   .replace(/%2B/gi, '+')
//   .replace(/%3D/gi, '=')
//   .replace(/%3F/gi, '?')
//   .replace(/%2F/gi, '/');
//   }
// ====================
// const url = new URL(location.href);
// url.searchParams.set(key, value);

// This will preserve everything about the URL and only change or add the one query param.
// You can then do whatever you want with the url object. Examples:

// Log the URL string.
// console.log(url.href);

// Go to the URL.
// location.assign(url);

// Go to the URL, but overwrite this history entry.
// location.replace(url);

// Same as `location.assign` without reloading.
// history.pushState(null, '', url);

// Same as `location.replace` without reloading.
// history.replaceState(null, '', url);