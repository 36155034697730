import styled from "styled-components";
// styled
import { BasicBtnAccent } from "components/Button/Button.styled";

export const CountARentPriceBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  max-width: 480px;
  margin: 0 auto;
  padding: 36px 32px;

  background-color: #fff;
  border-radius: 46px;
`;

export const CountARentPriceBoxTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px; /* 150% */
  text-transform: uppercase;
  text-align: center;
`;
export const CountARentPriceBoxText = styled.p`
  padding-bottom: 16px;

  line-height: 21px;
  text-align: center;

  border-bottom: 2px solid var(--color--bg, #f1f1f1);
`;

export const CountARentPriceBoxOptionsList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const CountARentPriceBoxOptionsItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;

  line-height: 24px; /* 150% */

  & svg {
    fill: var(--color--accent, #f19e1d);
  }

  &.delete {
    text-decoration: line-through;

    & svg {
      fill: currentColor;
    }
  }
`;

export const CountARentPriceBoxBtnClose = styled(BasicBtnAccent)`
  width: 100%;
  max-width: 180px;
  height: 44px;

  font-weight: 600;
  line-height: 24px; /* 150% */
  text-transform: uppercase;

  border: none;
  border-radius: 26px;
`;
