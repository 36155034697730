import { createSlice } from "@reduxjs/toolkit";
import { getTexts, getTextsPublic, updateTextById } from "./operations";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const initialState = {};

export const textsSlice = createSlice({
  name: "texts",
  initialState,
  reducers: {
    resetTextsState() { return initialState; },
    resetTextsStatus(state) { state.status = ""; },
    resetTextsError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getTextsPublic.pending, (state, action) => { handlePending(state); })
      .addCase(getTextsPublic.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getTextsPublic.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(getTexts.pending, (state, action) => { handlePending(state); })
      .addCase(getTexts.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getTexts.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(updateTextById.pending, (state, action) => { handlePending(state); })
      .addCase(updateTextById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateTextById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        state.list = [...state.list].map((i) => {
          if (action.payload?.text_id === i?.text_id) return action.payload;
          return i;
        });
      }),
});

export const { resetTextsState, resetTextsStatus, resetTextsError } = textsSlice.actions;
export const textsReducer = textsSlice.reducer;
