import { getCurrentLangPrice, isAvailableArray } from "helpers/data";

export default function getNormalizeDeliveryServices(deliveryServicesList) {
  let priceDeliveryServices = { eur: 0, czk: 0 };
  if (deliveryServicesList) priceDeliveryServices = isAvailableArray(deliveryServicesList)?.reduce(
    (prev, i) => {
      const totalEUR = prev?.eur + getCurrentLangPrice(i?.price, "en");
      const totalCZK = prev?.czk + getCurrentLangPrice(i?.price, "cz");
      return { eur: totalEUR, czk: totalCZK };
    },
    { eur: 0, czk: 0 }
  );


  const res = {
    list: deliveryServicesList,
    price: priceDeliveryServices,
  }

  return res
}