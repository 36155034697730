export function getUniqueValueFromArray(objectsArr) {
  const uniqueObjMap = {};
  for (const object of objectsArr) {
    uniqueObjMap[object] = object;
  }

  const unique = Object.values(uniqueObjMap);
  return unique;
}

export function getUniqueValueFromArrayKeys1(objectsArr, key1) {
  const uniqueObjMap = {};
  for (const object of objectsArr) {
    uniqueObjMap[object[key1]] = object;
  }

  const unique = Object.values(uniqueObjMap);
  return unique;
}

export function getUniqueValueFromArrayKeys2(objectsArr, key1, key2) {
  const uniqueObjMap = {};
  for (const object of objectsArr) {
    if (!object[key1]) continue;
    uniqueObjMap[object[key1][key2]] = object;
  }

  const unique = Object.values(uniqueObjMap);
  return unique;
}

export function getUniqueValueFromArrayKeys3(objectsArr, key1, key2, key3) {
  if (!objectsArr || !key1 || !key2 || !key3 ) return null

  const uniqueObjMap = {};
  for (const object of objectsArr) {
    uniqueObjMap[object[key1][key2][key3]] = object;
  }

  const unique = Object.values(uniqueObjMap);
  return unique;
}
