export default function parseSearchParams(params) {
  const searchParamsObj = {
    sDate: params?.get("sDate"),
    eDate: params?.get("eDate"),
    sTime: params?.get("sTime"),
    eTime: params?.get("eTime"),
    category: params?.get("category")?.replace("_plus", " +"),
    transmission: params?.get("transmission"),
    fuel: params?.get("fuel"),
    types: params?.getAll("types")?.length > 0 ? params?.getAll("types")[0]?.split(',') : null,
    brands: params?.getAll("brands")?.length > 0 ? params?.getAll("brands")[0]?.split(',') : null,
  };
  return searchParamsObj;
}

// const parse = (params) => {
//   return {
//     categories: params.getAll('categories').split(','), // Parse list
//     priceRange: {
//       min: Number(params.get('minPrice')), // Convert to numbers
//       max: Number(params.get('maxPrice')),
//     },
//     sortBy: params.get('sortBy'),
//   };
// };

// const search = window.location.search;
// const params = new URLSearchParams(search);
// const foo = params.get('bar');

// const params = [];
// searchParams.forEach((value, key) => { params.push([key, value]); });
