import { getDaysInMonth } from "helpers";

const useDate = () => {
  const today = new Date();
  const todayJSON = today?.toJSON().slice(0, 10);
  const todayISO = new Date(todayJSON).toISOString();

  today.setHours(0, 0, 0, 0);
  
  const todayMonthIndex = today.getMonth();
  const todayMonth = todayMonthIndex + 1;
  const todayYear = today.getFullYear();
  const todayMonthDates = getDaysInMonth(todayMonth, todayYear);

  return {
    today,
    todayMonthIndex,
    todayMonth,
    todayYear,
    todayMonthDates,

    todayJSON,
    todayISO,
  };
};

export default useDate;
