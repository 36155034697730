const { createUser } = require("./createUser");
const { getUsers } = require("./getUsers");
const { updateUserById } = require("./updateUserById");
const { deleteUserById } = require("./deleteUserById");

module.exports = {
  createUser,
  getUsers,
  updateUserById,
  deleteUserById,
};
