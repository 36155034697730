import { createSlice } from "@reduxjs/toolkit";
import { createInsurance, deleteInsuranceById, getInsurances, updateInsuranceById } from "./operations";
import { isAvailableArray } from "helpers";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const initialState = {};

export const insurancesSlice = createSlice({
  name: "insurances",
  initialState,
  reducers: {
    resetInsurancesState() { return initialState; },
    resetInsurancesStatus(state) { state.status = ""; },
    resetInsurancesError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createInsurance.pending, (state, action) => { handlePending(state); })
      .addCase(createInsurance.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(createInsurance.fulfilled, (state, action) => { handleFulfilled(state, "added");
        state.list = [...isAvailableArray(state.list)]?.concat([action.payload]);
      })
      .addCase(getInsurances.pending, (state, action) => { handlePending(state); })
      .addCase(getInsurances.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getInsurances.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(updateInsuranceById.pending, (state, action) => { handlePending(state); })
      .addCase(updateInsuranceById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateInsuranceById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        state.list = [...state.list].map((i) => {
          if (action.payload?.insurance_id === i?.insurance_id) return action.payload;
          return i;
        });
      })
      .addCase(deleteInsuranceById.pending, (state, action) => { handlePending(state); })
      .addCase(deleteInsuranceById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(deleteInsuranceById.fulfilled, (state, action) => { handleFulfilled(state, "deleted");
        state.list = [...state.list].filter((i) => action.payload?.insurance_id !== i?.insurance_id);
      })
});

export const { resetInsurancesState, resetInsurancesStatus, resetInsurancesError } = insurancesSlice.actions;
export const insurancesReducer = insurancesSlice.reducer;
