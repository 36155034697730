import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { maxDevice, minDevice } from "common/breakpoints";

export const LogoLink = styled(Link)`
  flex-shrink: 0;
  display: flex;
  align-items: center;

  &.header {
    color: var(--color--primary, #414141);

    & svg {
      width: 82px;
      height: 33px;
    }
  }

  &.footer {
    color: var(--color--white, #fff);

    & svg {
      width: 210px;
      height: 82px;
    }
  }

  &.burger-menu,
  &.modal-thank-you {
    color: var(--color--primary, #414141);

    & svg {
      width: 160px;
      height: auto;
    }
  }

  &.modal-thank-you {
    justify-content: center;
    width: 200px;
    height: 200px;

    background-color: var(--color--bg, #f1f1f1);
    border-radius: 50%;
  }

  & svg {
    display: block;
  }

  @media ${minDevice.tablet} {
    &.header svg {
      width: 102px;
      height: 41px;
    }

    &.footer svg {
      width: 268px;
      height: 108px;
    }
  }

  @media ${maxDevice.desktopL} {
    &.header {
      margin-right: auto;
    }
  }
`;
