import { useSelector } from "react-redux";
// redux
import { selectVehiclesSearchList } from "redux/vehicles/vehiclesSelectors";
// helpers
import { getUniqueFiltersList } from "helpers";

const useVehiclesSearchList = () => {
  const vehicles = useSelector(selectVehiclesSearchList);
  const vehicleTypes = getUniqueFiltersList(vehicles, "body_type");
  const vehicleBrands = getUniqueFiltersList(vehicles, "brand");
  const vehicleFuel = ["All"].concat(getUniqueFiltersList(vehicles, "fuel", "type"));
  const vehicleTransmission = ["All"].concat(getUniqueFiltersList(vehicles, "gearbox"));

  return {
    vehicles,
    vehicleTypes,
    vehicleBrands,
    vehicleFuel,
    vehicleTransmission,
  };
};

export default useVehiclesSearchList;
