import React from "react";
// styled
import { OfficeNavItem, OfficeNavItemLink, OfficeNavList, OfficeNavTag, } from "./OfficeNav.styled";

const OfficeNavAdmin = ({ setIsBurgerMenuOpen, isSmallView }) => {
  const navListData = [
    {
      name: "Vehicles",
      url: "/vehicles",
    },
    {
      name: "Locations",
      url: "/locations",
    },
    {
      name: "Insurances",
      url: "/insurances",
    },
    {
      name: "Services",
      url: "/services",
    },
    // {
    //   name: "Texts",
    //   url: "/texts",
    // },
  ];

  const handleClick = () => {
    if (!isSmallView) return;
    if (setIsBurgerMenuOpen) return setIsBurgerMenuOpen(false);
  };

  return (
    <OfficeNavTag>
      <OfficeNavList>
        {navListData?.map((i, idx) => {
          return (
            <OfficeNavItem key={idx} className={i?.className || ""}>
              <OfficeNavItemLink className={i?.className || ""} onClick={() => handleClick(i?.name)} to={i?.url} end={i?.end}>
                {i?.name}
              </OfficeNavItemLink>
            </OfficeNavItem>
          );
        })}
      </OfficeNavList>
    </OfficeNavTag>
  );
};

export default OfficeNavAdmin;
