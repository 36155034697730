import React from 'react';
// components
import SvgUse from 'components/SvgUse/SvgUse';
// styled
import { ContactsBox, ContactsItem, ContactsLink, ContactsLinkWrap, ContactsList, ContactsTitle } from './Contacts.styled';

const Contacts = ({ className }) => {
  const contactsData = [
    { title: "Tel", link: "tel:+420775177777", linkTitle: "+420 775 177 777", icon:  <SvgUse icontag="tel" />, },
    { title: "Mail", link: "mailto:info@alufcar.cz", linkTitle: "info@alufcar.cz", icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"> <path fillRule="evenodd" clipRule="evenodd" d="M7.05 5C4.81325 5 3 6.76288 3 8.9375V22.0625C3 24.2371 4.81325 26 7.05 26H25.95C28.1868 26 30 24.2371 30 22.0625V8.9375C30 6.76288 28.1868 5 25.95 5H7.05ZM6.81445 7.64491C6.89094 7.63182 6.96965 7.625 7.05 7.625H25.95C26.0304 7.625 26.1091 7.63183 26.1856 7.64492L16.5 13.9226L6.81445 7.64491ZM5.7 10.0774V22.0625C5.7 22.7874 6.30442 23.375 7.05 23.375H25.95C26.6956 23.375 27.3 22.7874 27.3 22.0625V10.0775L17.2489 16.5921C16.7954 16.886 16.2046 16.886 15.7512 16.5921L5.7 10.0774Z" /> </svg>, },
    { title: "Address", link: "https://maps.app.goo.gl/QUjzVD3uQBLgPRpX6", linkTitle: "Jana Masaryka 326/36 Praha 2", icon: <SvgUse icontag="location" />, target: "_blank" },
  ]

  return (
    <ContactsBox className={className}>
      <ContactsList>
        {contactsData?.map((i, idx) => (
          <ContactsItem key={idx}>
            {i?.icon}
            <ContactsLinkWrap>
              <ContactsTitle>{i?.title}</ContactsTitle>
              <ContactsLink to={i?.link} target={i.target}>{i?.linkTitle}</ContactsLink>
            </ContactsLinkWrap>
          </ContactsItem>
        ))}
      </ContactsList>
    </ContactsBox>
  )
}
// TODO TEXT

export default Contacts;