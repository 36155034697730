import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from "redux-persist";

import { langReducer } from "./lang/langSlice";
import { authReducer } from "./auth/authSlice";
import { usersReducer } from "./users/usersSlice";
import { vehiclesReducer } from "./vehicles/vehiclesSlice";
import { bookingsReducer } from "./bookings/bookingsSlice";
import { salesReducer } from "./sales/salesSlice";
import { locationsReducer } from "./locations/locationsSlice";
import { insurancesReducer } from "./insurances/insurancesSlice";
import { servicesReducer } from "./services/servicesSlice";
import { textsReducer } from "./texts/textsSlice";

const langPersistConfig = {
  key: "lang",
  storage,
  whitelist: ["lang"],
};
const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["token"],
};

export const store = configureStore({
  reducer: {
    lang: persistReducer(langPersistConfig, langReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    users: usersReducer,
    vehicles: vehiclesReducer,
    bookings: bookingsReducer,
    sales: salesReducer,
    locations: locationsReducer,
    insurances: insurancesReducer,
    services: servicesReducer,
    texts: textsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
