import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { minDevice } from "common/breakpoints";
// components
import { CardSetItem } from "components/Common/Placeholders";

export const HomePageCarRental = styled.section`
  width: 100%;
  padding: 16px 0;

  border-radius: 26px;
  background-color: var(--color--bg, #F1F1F1);

  @media ${minDevice.tablet} {
    padding: 72px 0;

    border-radius: 46px;
  }
`;
export const HomePageCarRentalBoxWrap = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media ${minDevice.tablet} {
    gap: 80px;
  }
  @media ${minDevice.desktopL} {
    flex-direction: row;
    --gap: 40px;
    gap: var(--gap);
    --items: 2;
  }
  @media ${minDevice.desktopXL} {
    // flex-direction: row;
    --gap: 92px;
    // gap: var(--gap);
    // --items: 2;
  }
`;
export const HomePageCarRentalBox2 = styled(CardSetItem)``;

export const HomePageCarRentalList = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  height: 100%;

  @media ${minDevice.tablet} {
    gap: 12px;
  }
`;
export const HomePageCarRentalItem = styled.li`
  position: relative;

  flex-grow: 1;
  width: 100%;
  padding: 32px 28px;

  font-size: 20px;
  line-height: 30px; /* 150% */
  text-align: center;

  background-color: var(--color--white, #FFF);
  border: 1px solid var(--color--white, #FFF);
  border-radius: 46px;


  @media ${minDevice.tablet} {
    padding: 16px 40px;
  }

  &:nth-of-type(1) {
    color: var(--color--white, #FFF);
    background-color: var(--color--accent, #F19E1D);
    border-color: var(--color--accent, #F19E1D);

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: -8px;
      transform: translateY(-50%);

      display: block;
      width: 20px;
      height: 20px;

      border-radius: 50%;
      background-color: var(--color--white, #FFF);
      background-image: url('${process.env.PUBLIC_URL}/images/icons/check-accent.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;

      @media ${minDevice.tablet} {
        left: -17px;

        width: 34px;
        height: 34px;

        background-size: 20px;
      }
    }
  }
  &:nth-of-type(3), &:nth-of-type(5) {
    border-color: var(--color--accent, #F19E1D);
  }
`;

export const HomePageCarRentalLinkWrap = styled(CardSetItem)`
  flex-grow: 1;
  overflow: hidden;
`;

export const HomePageCarRentalLink = styled(Link)`
  position: relative;

  display: block;
  height: 356px;
  padding: 28px;

  color: #fff;
  font-family: "Helvetica Now Display";
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%), url('${process.env.PUBLIC_URL}/images/homepage/car-rental.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 26px;

  &::before {
    content: "";

    position: absolute;
    bottom: -80px;
    right: -80px;

    display: block;
    width: 128px;
    height: 128px;

    border-radius: 26px;
    background-color: var( --color--bg, #F1F1F1);
    border: 1px solid transparent;
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: "";

    position: absolute;
    bottom: 0;
    right: 0;

    display: block;
    width: 32px;
    height: 32px;

    background-image: url("${process.env.PUBLIC_URL}/images/icons/arrow-link-2.svg");

    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px;
    z-index: 1;
  }

  & .text {
    display: block;
    max-width: 160px;
  }
  & .accent {
    color: var( --color--accent, #F19E1D);
    background-color: transparent;
  }

  &:hover {
    opacity: 0.8;
  }


  @media ${minDevice.tablet} {
    padding: 40px;
    height: 760px;

    font-size: 32px;
    line-height: 40px;

    border-radius: 46px;

    &::before {
      width: 148px;
      height: 148px;

      bottom: -48px;
      right: -48px;

      border-radius: 46px;
    }

    &:after {
      width: 80px;
      height: 80px;

      background-size: 80px;
    }

    & .text {
      max-width: 260px;
    }
  }
  @media ${minDevice.desktopXL} {
    padding: 48px;

    font-size: 46px;
    line-height: 56px;

    & .text {
      max-width: 360px;
    }
  }
`;