import React from 'react';
// hooks
import { useAvailableVehicles, useLang, useText } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// styled
import { HomePageCategoriesItem, HomePageCategoriesList, HomePageCategoriesSection, HomePageCategoryImg, HomePageCategoryInfo, HomePageCategoryLink, HomePageCategoryTitle } from './SectionFindTheBestCar.style';
import { HomePageSectionTitle } from 'pages/public/HomePage/HomePage.styled';
import { Container } from 'components/Common/common.styled';

// ===== text translate START =====
const dataToTranslate = {
  // === DB & LOCAL ===
  textTitleRest: "pages.home.views.find_the_best_car.title.rest",
  textTitleAccent: "pages.home.views.find_the_best_car.title.accent",
  // === LOCAL ===
  textCars: "cars",
  textCar: "car",
}
// ===== text translate END =====

const SectionFindTheBestCar = () => {
  const { currentLang } = useLang();
  const { textDB } = useText();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang, textDB);
  const { 
    textTitleRest,
    textTitleAccent,
    textCars,
    textCar,
  } = translate ?? {};
  // ===== text translate END =====
  const { hatchback, sedan, suv, combi, premium, trucks, scooters, } = useAvailableVehicles();
  const vehicleCategoriesData = [
    {
      name: "Hatchback",
      info: `${hatchback || 0} ${hatchback === 1 ? textCar : textCars}`,
      url: "/search?types=hatchback",
      imgSrc: `${process.env.PUBLIC_URL}/images/categories/hatchback.webp`,
    },
    {
      name: "Sedan",
      info: `${sedan || 0} ${sedan === 1 ? textCar : textCars}`,
      url: "/search?types=sedan",
      imgSrc: `${process.env.PUBLIC_URL}/images/categories/sedan.webp`,
    },
    {
      name: "SUV",
      info: `${suv || 0} ${suv === 1 ? textCar : textCars}`,
      url: "/search?types=suv",
      imgSrc: `${process.env.PUBLIC_URL}/images/categories/suv.webp`,
    },
    {
      name: "Premium",
      info: `${premium || 0} ${premium === 1 ? textCar : textCars}`,
      url: "/search?category=premium",
      imgSrc: `${process.env.PUBLIC_URL}/images/categories/premium.webp`,
    },
    {
      name: "Combi",
      info: `${combi || 0} ${combi === 1 ? textCar : textCars}`,
      url: "/search?types=combi",
      imgSrc: `${process.env.PUBLIC_URL}/images/categories/combi.webp`,
    },
    {
      name: "Trucks",
      info: `${trucks || 0} ${trucks === 1 ? textCar : textCars}`,
      url: "/search?category=trucks",
      imgSrc: `${process.env.PUBLIC_URL}/images/categories/trucks.webp`,
    },
    {
      name: "Scooters",
      info: `${scooters || 0} ${scooters === 1 ? textCar : textCars}`,
      url: "/search?category=scooters",
      imgSrc: `${process.env.PUBLIC_URL}/images/categories/scooters.webp`,
    },
  ];

  return (
    <HomePageCategoriesSection>
      <Container>
        <HomePageSectionTitle> <span>{textTitleAccent}</span> {textTitleRest} </HomePageSectionTitle>
        <HomePageCategoriesList>
          {vehicleCategoriesData?.map((i, idx) => {
            const itemName = i?.name;
            const itemInfo = i?.info;
            const itemToUrl = i?.url;
            const itemImgSrc = i?.imgSrc;
        
            return (
              <HomePageCategoriesItem key={idx}>
                <HomePageCategoryLink to={itemToUrl}> 
                  <HomePageCategoryTitle>{itemName}</HomePageCategoryTitle>
                  <HomePageCategoryImg loading='lazy' src={itemImgSrc} width="265" height="108" alt={`Category ${itemName}`} />
                  <HomePageCategoryInfo>{itemInfo}</HomePageCategoryInfo>
                </HomePageCategoryLink>
              </HomePageCategoriesItem>
            );
          })}
        </HomePageCategoriesList>
      </Container>
    </HomePageCategoriesSection>
  );
}

export default SectionFindTheBestCar