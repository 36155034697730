import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const updateBookingById = createAsyncThunk(
  "bookings/updateBookingById",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await axios.patch(`/bookings/${id}`, data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
