import styled from "styled-components";
import { Container } from "components/Common/common.styled";
import { maxDevice, minDevice } from "common/breakpoints";

export const ReviewsSection = styled.section`
  width: 100%;
  padding: 48px 0 28px;

  @media ${minDevice.tablet} {
    padding: 48px 0 32px;
  }
  @media ${minDevice.desktopL} {
    padding: 120px 0 80px;
  }
  // @media ${minDevice.desktopXL} {
  //   padding: 120px 0 80px;
  // }
`;
export const ReviewsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media ${minDevice.tablet} {
    gap: 28px;
  }
  @media ${minDevice.desktopXL} {
    gap: 52px;
  }
`;

export const ReviewsSwiperBox = styled.div`
  min-height: 100px;

  border-radius: 26px;
  background: #F1F1F1;
  background-image: url('${process.env.PUBLIC_URL}/images/reviews/1.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  & .reviews-swiper {
    & .swiper-button-prev, & .swiper-button-next {
      --swiper-navigation-sides-offset: 20px;
      z-index: 2;

      &:after {
        content: "→";

        color: var(--color--white, #fff);
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        line-height: 30px; /* 75% */
      }

      @media ${maxDevice.tablet} {
        display: none;
      }
    }
    & .swiper-button-prev:after  { transform: rotate(180deg); }

    // --swiper-navigation-sides-offset

    & .swiper-pagination {
      bottom: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      z-index: 2;
    }

    & .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin: 0;

      background-color: var(--color--white, #fff);
      opacity: 0.8;
    }
    & .swiper-pagination-bullet-active {
      background-color: var(--color--accent, #F19E1D);
      opacity: 1;
    }
  }
`;

export const ReviewsSwiperItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 360px;
  padding: 40px ;

  @media ${minDevice.tablet} {
    padding: 40px 120px;
  }
  @media ${minDevice.desktopL} {
    padding: 80px 220px;
  }
  // @media ${minDevice.desktopXL} {
  //   padding: 80px 220px;
  // }
`;

export const ReviewsSwiperItemText = styled.p`
  position: relative;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */

  &::before, &::after {
    content: ' " ';

    position: absolute;
    top: 0;

    display: block;
    width: 12px;
  
    color: #fff;
    font-size: 64px;
    font-style: italic;
    font-weight: 600;
    text-align: center;

    // line-height: 30px; /* 46.875% */
  }

  &::before { left:-40px; }
  &::after { right:-8px; }
`;
