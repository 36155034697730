import getDaysInMonth from "./getDaysInMonth";

export default function getDayMonthYearFromDate(initDate = null) {
  let currentDate = new Date();
  if (initDate) currentDate = new Date(initDate);
  currentDate?.setMinutes(currentDate?.getMinutes());

  const currentDay = currentDate?.getDate();
  const currentMonth = currentDate?.getMonth() + 1;
  const currentYear = currentDate?.getFullYear();
  const currentMonthDates = getDaysInMonth(currentMonth, currentYear);

  return {
    currentDate,
    currentDay,
    currentMonth,
    currentYear,
    currentMonthDates,
  };
}
