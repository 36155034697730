// helpers
import { compareStrings, getCurrentLangObject, getDeepObjectValue } from "./data";
// data
import { text } from "data/text";

export default function getTextList(textDB, key, currentLang = "cz") {
  if (!key) return null;

  let textListData = textDB?.find((i) => compareStrings(i?.key, key))?.text_list;
  if (!textListData) textListData = getDeepObjectValue(text, key);
  if (!textListData) return null;

  let textList = getCurrentLangObject(textListData, currentLang);
  if (!Array.isArray(textList)) textList = null;
  return textList;
}

export function getItemsList(textDB, key) {
  if (!key) return null;

  let textListData = textDB?.find((i) => compareStrings(i?.key, key))?.items_list;
  if (!textListData) textListData = getDeepObjectValue(text, key);
  if (!textListData || !Array.isArray(textListData)) return null;
  return textListData;
}
