import React from "react";
// // hooks
import { useLang, useText } from "hooks";
// helpers
import { getItemsList, getTranslate, isAvailableArray } from "helpers";
// styled
import { BenefitTitle, BenefitTitleWrap, BenefitsContainer, BenefitsItem, BenefitsList, BenefitsSection, } from "./Benefits.styled";
import { SectionTitle } from "components/Common/common.styled";

// ===== text translate START =====
const dataToTranslate = {
  // === DB & LOCAL ===
  textTitleRest: "views.benefits.title.rest",
  textTitleAccent: "views.benefits.title.accent",
}
// ===== text translate END =====

const Benefits = () => {
  const { currentLang } = useLang();
  const { textDB } = useText();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang, textDB);
  const { 
    textTitleRest,
    textTitleAccent,
  } = translate ?? {};
  // ===== text translate END =====
  // ===== text list START =====
  const itemsListBenefits = getItemsList(textDB, "views.benefits.items_list");
  // ===== text list END =====

  return (
    <BenefitsSection>
      <BenefitsContainer>
        <SectionTitle> <span>{textTitleAccent}</span> {textTitleRest} </SectionTitle>
        <BenefitsList>
          {isAvailableArray(itemsListBenefits)?.map((i, idx) => (
            <BenefitsItem key={idx}>
              <BenefitTitleWrap>
                <BenefitTitle>{i[currentLang]}</BenefitTitle>
              </BenefitTitleWrap>
            </BenefitsItem>
          ))}
        </BenefitsList>
      </BenefitsContainer>
    </BenefitsSection>
  );
};

export default Benefits;
