import { createSlice } from "@reduxjs/toolkit";
import {
  // === public rental ===
  getPublicRentalVehicles,
  // === private ById ===
  createVehicle, 
  updateVehicleById, 
  deleteVehicleById,
  // === private rental ===
  getRentalVehicles,
  // === private rental ByGroupId ===
  getRentalVehiclesByGroupId,
  addRentalVehicleByGroupId,
  updateRentalVehiclesByGroupId,
  deleteRentalVehiclesByGroupId,
  
  // === public sale ===
  getPublicSaleVehicles,
  // === sale ===
  getSaleVehicles,
  getSaleVehicleById,
  // === sales ===
  getSalesVehicles,
  getSalesVehicleById,
  updateSalesVehicleById,
  // === search ===
  getVehiclesBySearchQuery,
  getVehiclesBySearchQueryAndGroupId,
  // === other ===
  getRentalVehicleById,

} from "./operations";
// helpers
import { compareStrings, isAvailableArray } from "helpers";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const initialState = {};

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    resetVehiclesState() { return initialState; },
    resetVehiclesStatus(state) { state.status = ""; },
    resetVehiclesError(state) { state.error = null; },
    setStateSearchParams(state, action) { state.searchParams = action.payload; },
    setStateFiltersParams(state, action) { state.filtersParams = action.payload; },
    setStateSearchList(state, action) { state.searchList = action.payload},
    setStateRentalVehiclesGroup(state, action) { state.rentalVehiclesGroup = action.payload},
  },
  extraReducers: (builder) =>
    builder
      // === public rental ===
      .addCase(getPublicRentalVehicles.pending, (state) => { handlePending(state); })
      .addCase(getPublicRentalVehicles.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getPublicRentalVehicles.fulfilled, (state, action) => { handleFulfilled(state);
        state.publicRentalVehicles = action.payload;
      })
      // === private ById ===
      .addCase(createVehicle.pending, (state) => { handlePending(state); })
      .addCase(createVehicle.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(createVehicle.fulfilled, (state, action) => { handleFulfilled(state, "added");
        if(compareStrings(action.payload?.for, "Rental")) state.rentalVehicles = [...isAvailableArray(state.rentalVehicles)]?.concat([action.payload])
        if(compareStrings(action.payload?.for, "Sale")) state.salesVehicles = [...isAvailableArray(state.salesVehicles)]?.concat([action.payload])
      })
      .addCase(updateVehicleById.pending, (state) => { handlePending(state) })
      .addCase(updateVehicleById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateVehicleById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        if(compareStrings(action.payload?.for, "Rental")) {
          state.rentalVehicle = action.payload;

          if (!state.rentalVehiclesGroup) return 
          const newVehiclesData = [...isAvailableArray(state.rentalVehiclesGroup?.vehicles_data)]?.map((i) => {
            if (compareStrings(action.payload?.vehicle_id, i?.vehicle_id)) return action.payload
            return i 
          });

          const newRentalVehiclesGroup = {...state.rentalVehiclesGroup, vehicles_data: newVehiclesData};
          state.rentalVehiclesGroup = newRentalVehiclesGroup;
        }
      })
      .addCase(deleteVehicleById.pending, (state) => { handlePending(state); })
      .addCase(deleteVehicleById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(deleteVehicleById.fulfilled, (state, action) => { handleFulfilled(state, "deleted");
        if(compareStrings(action.payload?.for, "Sale")) state.salesVehicles = [...isAvailableArray(state.salesVehicles)]?.filter((i) => action.payload?.vehicle_id !== i?.vehicle_id);
        if(compareStrings(action.payload?.for, "Rental")) {
          const newVehiclesData = [...isAvailableArray(state.rentalVehiclesGroup?.vehicles_data)]?.filter((i) => action.payload?.vehicle_id !== i?.vehicle_id);
          const newRentalVehiclesGroup = {...state.rentalVehiclesGroup, vehicles_data: newVehiclesData};
          
          state.rentalVehiclesGroup = newRentalVehiclesGroup;

          if (!state.rentalVehicles) return 
          state.rentalVehicles = [...state.rentalVehicles].map((i) => {
            if (compareStrings(i?.vehicles_group_id, action.payload?.vehicles_group_id)) return {...i, available: i?.available - 1}
            return i;
          });
        }
      })
      // === private rental ===
      .addCase(getRentalVehicles.pending, (state) => { handlePending(state); })
      .addCase(getRentalVehicles.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getRentalVehicles.fulfilled, (state, action) => { handleFulfilled(state);
        state.rentalVehicles = action.payload;
      })
      // === rental ByGroupId ===
      .addCase(getRentalVehiclesByGroupId.pending, (state) => { handlePending(state) })
      .addCase(getRentalVehiclesByGroupId.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getRentalVehiclesByGroupId.fulfilled, (state, action) => { handleFulfilled(state);
        state.rentalVehiclesGroup = action.payload;
      })
      .addCase(addRentalVehicleByGroupId.pending, (state) => { handlePending(state) })
      .addCase(addRentalVehicleByGroupId.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(addRentalVehicleByGroupId.fulfilled, (state, action) => { handleFulfilled(state, "added");
        state.rentalVehiclesGroup.vehicles_data = [...state.rentalVehiclesGroup?.vehicles_data]?.concat([action.payload]);

        if (!state.rentalVehicles) return 
        state.rentalVehicles = [...state.rentalVehicles].map((i) => {
          if (compareStrings(i?.vehicles_group_id, action.payload?.vehicles_group_id)) return {...i, available: i?.available + 1}
          return i;
        });
      })
      .addCase(updateRentalVehiclesByGroupId.pending, (state) => { handlePending(state) })
      .addCase(updateRentalVehiclesByGroupId.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateRentalVehiclesByGroupId.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        state.rentalVehiclesGroup = action.payload;
        
        if (!state.rentalVehicles) return 
        state.rentalVehicles = [...state.rentalVehicles].map((i) => {
          if (compareStrings(i?.vehicles_group_id, action.payload?.vehicles_group_id)) return action.payload;
          return i;
        });
      })
      .addCase(deleteRentalVehiclesByGroupId.pending, (state) => { handlePending(state) })
      .addCase(deleteRentalVehiclesByGroupId.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(deleteRentalVehiclesByGroupId.fulfilled, (state, action) => { handleFulfilled(state, "deleted");
        if (!state.rentalVehicles) return 
        state.rentalVehicles = [...state.rentalVehicles].filter((i) => action.payload?.vehicles_group_id !== i?.vehicles_group_id);
      })


      // === public sale ===
      .addCase(getPublicSaleVehicles.pending, (state) => { handlePending(state); })
      .addCase(getPublicSaleVehicles.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getPublicSaleVehicles.fulfilled, (state, action) => { handleFulfilled(state);
        state.salesVehicles = action.payload;
      })

      // === private sale ===
      .addCase(getSaleVehicles.pending, (state) => { handlePending(state); })
      .addCase(getSaleVehicles.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getSaleVehicles.fulfilled, (state, action) => { handleFulfilled(state);
        state.salesVehicles = action.payload;
      })
      .addCase(getSaleVehicleById.pending, (state) => { handlePending(state) })
      .addCase(getSaleVehicleById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getSaleVehicleById.fulfilled, (state, action) => { handleFulfilled(state);
        state.salesVehicle = action.payload;
      })
      .addCase(getSalesVehicles.pending, (state) => { handlePending(state); })
      .addCase(getSalesVehicles.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getSalesVehicles.fulfilled, (state, action) => { handleFulfilled(state);
        state.salesVehicles = action.payload;
      })
      .addCase(getSalesVehicleById.pending, (state) => { handlePending(state) })
      .addCase(getSalesVehicleById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getSalesVehicleById.fulfilled, (state, action) => { handleFulfilled(state);
        state.salesVehicle = action.payload;
      })
      .addCase(updateSalesVehicleById.pending, (state) => { handlePending(state) })
      .addCase(updateSalesVehicleById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateSalesVehicleById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        state.salesVehicles = [...state.salesVehicles].map((i) => {
          if (action.payload?._id === i?._id) return action.payload;
          return i;
        });
      })

      // === search ===
      .addCase(getVehiclesBySearchQuery.pending, (state) => { handlePending(state); })
      .addCase(getVehiclesBySearchQuery.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getVehiclesBySearchQuery.fulfilled, (state, action) => { handleFulfilled(state);
        state.searchList = action.payload;
      })
      .addCase(getVehiclesBySearchQueryAndGroupId.pending, (state) => { handlePending(state); })
      .addCase(getVehiclesBySearchQueryAndGroupId.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getVehiclesBySearchQueryAndGroupId.fulfilled, (state, action) => { handleFulfilled(state);
        state.searchList = action.payload;
      })
      // === other ===
      .addCase(getRentalVehicleById.pending, (state) => { handlePending(state) })
      .addCase(getRentalVehicleById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getRentalVehicleById.fulfilled, (state, action) => { handleFulfilled(state);
        state.rentalVehicle = action.payload;
      })
});

export const {
  resetVehiclesState,
  resetVehiclesStatus,
  resetVehiclesError,
  setStateSearchParams,
  setStateFiltersParams,
  setStateSearchList,
  setStateRentalVehiclesGroup,
} = vehiclesSlice.actions;

export const vehiclesReducer = vehiclesSlice.reducer;
