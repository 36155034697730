import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { minDevice } from "common/breakpoints";
import { CardSetItemLi, CardSetUl } from "components/Common/Placeholders";

export const HomePageCategoriesSection = styled.section`
  padding: 20px;

  border-radius: 26px;
  background-color: var(--color--bg, #f1f1f1);

  @media ${minDevice.tablet} {
    padding: 36px 0 32px;

    border-radius: 46px;
  }
  @media ${minDevice.desktopXL} {
    padding: 60px 0 96px;
  }
`;


export const HomePageCategoriesList = styled(CardSetUl)`
  align-items: center;
  --gap: 8px;

  @media ${minDevice.desktopXL} {
    --gap: 16px;
  }
`;
export const HomePageCategoriesItem = styled(CardSetItemLi)`
  @media ${minDevice.tablet} {
    --items: 3;

    &:nth-of-type(4) {
      --items: 4;
    }
    &:nth-of-type(5) {
      --items: 4;
    }
    &:nth-of-type(6) {
      --items: 4;
    }
    &:nth-of-type(7) {
      --items: 4;
    }
  }
`;
export const HomePageCategoryLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 10px 48px;

  color: var(--color--primary, #414141);

  border-radius: 16px;
  background: var(--color--white, #fff);
  transition: var(--transition--200);

  @media ${minDevice.tablet} {
    padding: 10px 20px;
  }
  @media ${minDevice.desktopXL} {
    padding: 32px 40px;

    border-radius: 46px;
  }

  &:hover {
    color: var(--color--white, #fff);
    background: linear-gradient(90deg, #434343 0%, #000 100%);
  }
`;
export const HomePageCategoryImg = styled.img``;
export const HomePageCategoryTitle = styled.h3`
  font-family: "Helvetica Now Display";
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
`;
export const HomePageCategoryInfo = styled.p`
  line-height: 24px; /* 150% */
`;
