import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";

export const InsurancesListTag = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  --gap: 24px;
  gap: var(--gap);
`;

export const InsuranceItem = styled.li`
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  padding: 36px 32px;

  background-color: #fff;
  border-radius: 46px;

  @media ${minDevice.laptop} {
    &.office {
      --items: 3;
    }
  }
`;
export const InsuranceItemName = styled.p`
  margin-bottom: 16px;

  font-size: 18px;
  line-height: 30px; /* 166.667% */
`;
export const InsuranceItemPrice = styled.p`
  padding-bottom: 32px;

  font-size: 60px;
  line-height: 66px; /* 110% */

  border-bottom: 2px solid var(--color--bg, #f1f1f1);

  & span {
    font-size: 16px;
    line-height: 24px; /* 150% */
  }
`;

export const InsuranceFeaturesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px 0;
`;
export const InsuranceFeaturesItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

   & svg { fill: var(--color--accent, #f19e1d); }

  &.delete {
    text-decoration: line-through;

    & svg { fill: currentColor; }
  }
`;

export const InsuranceAddBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;

  color: #fff;
  line-height: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-transform: uppercase;

  background-color: var(--color--accent, #f19e1d);
  border: none;
  border-radius: 26px;

  &:hover {
    opacity: 0.8;
    box-shadow: var(--box-shadow--main);
  }
`;
