export default function insertBr(textList) {
  const normalized_text = textList?.join("\n");
  return normalized_text;
}

export function replaceBr(text) {
  const normalized_textList = text?.split("\n")?.filter(i => i);
  return normalized_textList;
}

// var originals = [
//   "i am a\nfat\n\nboy zzz",
//   "i am a\rfat\r\rboy zzz",
//   "i am a\r\nfat\r\n\r\nboy zzz"
// ];
  
// originals.forEach(function(str) {
//   str = str.replace(/(?: |\r|\n|\r\n)/g, function(m) {
//       return m === " " ? "<sp>" : "<br>";
//   });
// });
