import React from 'react';
// hooks
import { useLang, useText } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// styled
import { HowDoesItWorkSection, HowDoesItWorkStepsItem, HowDoesItWorkStepsList, HowDoesItWorkStepText, HowDoesItWorkStepTitle, HowDoesItWorkStepTitleWrap } from './SectionHowDoesItWork.styled';
import { HomePageSectionTitle } from 'pages/public/HomePage/HomePage.styled';
import { Container } from 'components/Common/common.styled';

// ===== text translate START =====
const dataToTranslate = {
  // === DB & LOCAL ===
  textTitleRest: "pages.home.views.how_does_it_work.title.rest",
  textTitleAccent: "pages.home.views.how_does_it_work.title.accent",
  textFindACarTitle: "pages.home.views.how_does_it_work.title_find_a_car",
  textFindACarText: "pages.home.views.how_does_it_work.text_find_a_car",
  textReservationTitle: "pages.home.views.how_does_it_work.title_reservation",
  textReservationText: "pages.home.views.how_does_it_work.text_reservation",
  textCarPickupTitle: "pages.home.views.how_does_it_work.title_car_pickup",
  textCarPickupText: "pages.home.views.how_does_it_work.text_car_pickup",
}
// ===== text translate END =====

const SectionHowDoesItWork = () => {
  const { currentLang } = useLang();
  const { textDB } = useText();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang, textDB);
  const { 
    textTitleRest,
    textTitleAccent,
    textFindACarTitle,
    textFindACarText,
    textReservationTitle,
    textReservationText,
    textCarPickupTitle,
    textCarPickupText,
  } = translate ?? {};
  // ===== text translate END =====

  const stepsListData = [
    { title: textFindACarTitle, text: textFindACarText, },
    { title: textReservationTitle, text: textReservationText, },
    { title: textCarPickupTitle, text: textCarPickupText, },
  ]

  return (
    <HowDoesItWorkSection>
      <Container>
        <HomePageSectionTitle> <span>{textTitleAccent}</span> {textTitleRest} </HomePageSectionTitle>
        <HowDoesItWorkStepsList>
          {stepsListData?.map((i, idx) => {
            return (
              <HowDoesItWorkStepsItem key={idx}>
                <HowDoesItWorkStepTitleWrap>
                  <HowDoesItWorkStepTitle>{i?.title}</HowDoesItWorkStepTitle>
                </HowDoesItWorkStepTitleWrap>
                <HowDoesItWorkStepText>{i?.text}</HowDoesItWorkStepText>
              </HowDoesItWorkStepsItem>
            )
          })}
        </HowDoesItWorkStepsList>
      </Container>
    </HowDoesItWorkSection>
  )
}

export default SectionHowDoesItWork