import React from "react";
// hooks
import { useLang } from "hooks";
// helpers
import { getCurrentLangObject, getTranslate } from "helpers";
// styled
import { RentalVehiclePricesItem, RentalVehiclePricesListTag, } from "./RentalVehiclePricesList.styled";
const dataToTranslate = {
  // === word ===
  textDay: "day",
  textDays: "days",
  textTotal: "total",
}
// ===== text translate END =====

const RentalVehiclePricesList = ({ rentPrices, className = "" }) => {
  const { currentLang, symbol } = useLang();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { 
    // === word ===
    textDay,
    textDays,
    textTotal,
  } = translate ?? {};
  // ===== text translate END =====
  const rentPriceByCasesCurrentLang = getCurrentLangObject(rentPrices?.rentPriceByCases, currentLang);

  const CatalogItemPricesData = [
    { days: "1-2", multiplier: 2, priceKey: "case1" },
    { days: "3-7", multiplier: 7, priceKey: "case2" },
    { days: "8-14", multiplier: 14, priceKey: "case3" },
    { days: "15-21", multiplier: 21, priceKey: "case4" },
    { days: "22-30", multiplier: 30, priceKey: "case5" },
    { days: "30+", multiplier: 30, priceKey: "case6" },
  ];

  return (
    <RentalVehiclePricesListTag className={className}>
      {CatalogItemPricesData?.map((i, idx) => {
        const itemPrice = rentPriceByCasesCurrentLang ? rentPriceByCasesCurrentLang[i?.priceKey] : 0;

        return (
          <RentalVehiclePricesItem key={idx} className={className}>
            <span> {i?.days} {textDays} </span>
            <span>{`${Math.round( itemPrice )} ${symbol} / ${textDay}`}</span>
            <span> {textTotal}: {Math.round(itemPrice * i?.multiplier)} {symbol} </span>
          </RentalVehiclePricesItem>
        );
      })}
    </RentalVehiclePricesListTag>
  );
};

export default RentalVehiclePricesList;
