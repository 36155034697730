import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";
// components
import { CardSetItemLabel } from "components/Common/Placeholders";
// styled
import { Form, FormBox } from "components/FormNew/Form.styled";
import { BtnAccentSolid } from "components/Button/Button.styled";

export const SearchVehiclesSection = styled.section`
  width: 100%;
  padding: 20px 0 0;
`;

export const SearchVehiclesFormTag = styled(Form)`
  position: relative;

  row-gap: 24px;
  padding: 20px 16px;

  background-color: var(--color--bg, #f1f1f1);
  border-radius: 26px;

  @media ${minDevice.tablet} {
    padding: 36px 24px;

    border-radius: 46px;
   
  }

  @media ${minDevice.desktopL} {
    padding: 36px 24px 24px;
    margin-bottom: 36px;

    border-bottom-right-radius: 0;

    &:before {
      content: "";

      position: absolute;
      bottom: -36px;
      right: 0;

      display: block;
      width: 400px;
      height: 36px;

      background-color: var(--color--bg, #f1f1f1);
      border-bottom-right-radius: 46px;
    }

    &:after {
      content: "";

      position: absolute;
      bottom: -36px;
      right: 0;

      display: block;
      width: 900px;
      height: 36px;

      background-image: url("${process.env.PUBLIC_URL}/images/icons/formbottom.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 36px;
      z-index: -1;
    }

    @media ${minDevice.desktopXL} {
      &:before { width: 600px; }
      &:after { width: 1280px; }
    }
  }
`;

export const SearchVehiclesFormMainBox = styled(FormBox)`
  @media ${minDevice.desktopL} {
    align-items: flex-start;
    align-content: flex-start;
    row-gap: 16px;

    &.top-21 { padding-top: 21px; }
  }
`;

export const SearchVehiclesFormRadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  height: 48px;

  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 26px;
  cursor: pointer;
  transition: var(--transition--100);

  input:checked ~ & {
    background-color: var(--color--bg, #f1f1f1);
    border-color: var(--color--accent, #f19e1d);
  }

  &::before {
    content: "";

    display: block;
    width: 24px;
    height: 24px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    pointer-events: none;
  }

  &.car-rental::before { background-image: url("${process.env.PUBLIC_URL}/images/icons/car-2.svg"); }
  &.truck-rental::before { background-image: url("${process.env.PUBLIC_URL}/images/icons/truck.svg"); }
  &.scooter-rental::before { background-image: url("${process.env.PUBLIC_URL}/images/icons/scooter.svg"); }
  &.car-for-sale::before { background-image: url("${process.env.PUBLIC_URL}/images/icons/car-for-sale.svg"); }
`;

export const SearchVehiclesFormCheckboxInput = styled.input`
  display: none;

  @media ${minDevice.desktopL} {
    order: 5;
  }
`;
export const SearchVehiclesFormCheckboxLabel = styled(CardSetItemLabel)`
  position: relative;

  flex-grow: 1;
  display: flex;
  gap: 12px;
  margin-bottom: 20px;

  font-size: 16px;
  line-height: 1;

  cursor: pointer;

  &:before {
    content: "";

    flex-shrink: 0;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--color--bg, #f1f1f1);
  }

  &.bg-fff:before { background-color: #fff; }

  &:after {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    flex-shrink: 0;
    display: block;
    width: 24px;
    height: 24px;

    background-color: var(--color--accent, #f19e1d);
    background-image: url("${process.env.PUBLIC_URL}/images/icons/check.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    border: 2px solid var(--color--accent, #f19e1d);
    border-radius: 50%;
    transition: var(--transition--100);
    opacity: 0;
  }

  input:checked + &:before {
    border-color: var(--color--accent, #f19e1d);
  }
  input:checked + &:after {
    opacity: 1;
  }
  input:disabled + & {
    cursor: auto;
    opacity: 0;
  }

  @media ${minDevice.desktopL} {
    margin: 12px 0;
    order: 5;
  }
`;

export const SearchVehiclesFormBtn = styled(BtnAccentSolid)`
  height: 60px;
  width: 100%;
  margin-top: 20px;

  line-height: normal;
  text-transform: uppercase;

  border-radius: 26px;

  @media ${minDevice.desktopL} {
    max-width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
    margin-top: 0px;
    transform: translateY(16px);
    order: 5;

    &:hover { transform: translateY(16px) scale(1.01); }
  }
`;