import React, { lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// hooks
import { useWindowDimensions } from "hooks";
import { PermissionRoute, PrivateRoutes, RestrictedRoute2, } from "hooks/useRoute";
// layouts
import { PrivateLayout, PublicLayout } from "layouts";
// pages
import AuthPage from "pages/public/AuthPage/AuthPage";
import HomePage from "pages/public/HomePage/HomePage";
// components
import Loader from "components/Loader/Loader";
// ===== PUBLIC Pages START =====
const FAQPage = lazy(() => import("pages/public/FAQPage/FAQPage"));
const AboutPage = lazy(() => import("pages/public/AboutPage/AboutPage"));
const SearchPage = lazy(() => import("pages/public/SearchPage/SearchPage"));
const ContactsPage = lazy(() => import("pages/public/ContactsPage/ContactsPage"));
const RentalCarPage = lazy(() => import("pages/public/RentalCarPage/RentalCarPage"));
const CarForSalePage = lazy(() => import("pages/public/CarForSalePage/CarForSalePage"));
const CatalogSalePage = lazy(() => import("pages/public/CatalogSalePage/CatalogSalePage")); 
const ProductSalePage = lazy(() => import("pages/public/ProductSalePage/ProductSalePage"));
const ProductRentalPage = lazy(() => import("pages/public/ProductRentalPage/ProductRentalPage"));
const ShortTermAndLongTermRentPage = lazy(() => import( "pages/public/ShortTermAndLongTermRentPage/ShortTermAndLongTermRentPage"));
const RentalOfElectricScootersPage = lazy(() => import( "pages/public/RentalOfElectricScootersPage/RentalOfElectricScootersPage"));
const RentalOfScootersForCouriersPage = lazy(() => import( "pages/public/RentalOfScootersForCouriersPage/RentalOfScootersForCouriersPage" ) );

// const ErrorNotFoundPage = lazy(() => import("pages/ErrorNotFoundPage/ErrorNotFoundPage"));
// ===== PUBLIC Pages END =====

// ===== PRIVATE Pages START =====
// ===== ADMIN Pages =====
const UsersPage = lazy(() => import("pages/admin/UsersPage/UsersPage"));
const TextsPage = lazy(() => import("pages/admin/TextsPage/TextsPage"));
const DashboardPage = lazy(() => import("pages/DashboardPage/DashboardPage"));
const ServicesPage = lazy(() => import("pages/admin/ServicesPage/ServicesPage") );
const LocationsPage = lazy(() => import("pages/admin/LocationsPage/LocationsPage") );
const InsurancesPage = lazy(() => import("pages/admin/InsurancesPage/InsurancesPage") );
// ===== VEHICLES Pages =====
const RentalVehiclesPage = lazy(() => import("pages/vehicles/RentalVehiclesPage/RentalVehiclesPage") );
const RentalVehiclesGroupPage = lazy(() => import("pages/vehicles/RentalVehiclesGroupPage/RentalVehiclesGroupPage") );
const RentalVehiclePage = lazy(() => import("pages/vehicles/RentalVehiclePage/RentalVehiclePage") );
const SaleVehiclesPage = lazy(() => import("pages/vehicles/SaleVehiclesPage/SaleVehiclesPage") );
const SaleVehiclePage = lazy(() => import("pages/vehicles/SaleVehiclePage/SaleVehiclePage") );
const VehiclesPage = lazy(() => import("pages/vehicles/VehiclesPage/VehiclesPage") );
// ===== BOOKINGS Pages =====
const BookingsPage = lazy(() => import("pages/BookingsPage/BookingsPage"));
const BookingPage = lazy(() => import("pages/BookingPage/BookingPage"));
// ===== SALES Pages =====
const SalesPage = lazy(() => import("pages/SalesPage/SalesPage"));
const SalePage = lazy(() => import("pages/SalePage/SalePage"));
// ===== PRIVATE Pages END =====

// ===== Components =====
const OrderFormRental = lazy(() => import("components/forms/OrderForm/OrderFormRental"));

const App = () => {
  const location = useLocation();
  const { height } = useWindowDimensions();
  const allowedRolesSuperAdmins = ["superadmin"];
  const allowedRolesAdmins = ["superadmin", "admin"];
  const allowedRolesRental = ["superadmin", "admin", "rental manager"];
  const allowedRolesSales = ["superadmin", "admin", "sales manager"];
  const allowedRolesAll = ["superadmin", "admin", "rental manager", "sales manager"];

  useEffect(() => { window.scrollTo({ top: 0, behavior: "smooth" }); }, [location]);
  useEffect(() => { document.getElementById("root").style.minHeight = height + "px"; }, [height]);

  return false ? (
    <Loader />
  ) : (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<HomePage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/search/:vehiclesGroupId/:vehicleName" element={<ProductRentalPage />} >
          <Route path="order" element={<OrderFormRental />} />
        </Route>
        <Route path="/sale" element={<CatalogSalePage />} />
        <Route path="/sale/:vehicleId/:vehicleName" element={<ProductSalePage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/rental-car" element={<RentalCarPage />} />
        <Route path="/cars-for-sale" element={<CarForSalePage />} />
        <Route path="/rental-of-scooters-for-couriers" element={<RentalOfScootersForCouriersPage />} />
        <Route path="/rental-of-electric-scooters" element={<RentalOfElectricScootersPage />} />
        <Route path="/short-term-and-long-term-rent" element={<ShortTermAndLongTermRentPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="login" element={ <RestrictedRoute2 component={AuthPage} /> } />
      </Route>

      <Route element={<PrivateRoutes redirectTo="/login" />}>
        <Route element={ <PermissionRoute component={PrivateLayout} allowedRoles={allowedRolesSuperAdmins} /> } >
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/users" element={<UsersPage />} />
        </Route>

        <Route element={ <PermissionRoute component={PrivateLayout} allowedRoles={allowedRolesAdmins} /> } >
          <Route path="/locations" element={<LocationsPage />} />
          <Route path="/insurances" element={<InsurancesPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/texts" element={<TextsPage />} >
            <Route path="homepage" element={<p>homepage</p>} />
          </Route>
        </Route>

        <Route path="/bookings" element={ <PermissionRoute component={PrivateLayout} allowedRoles={allowedRolesRental} /> } >
          <Route index element={<BookingsPage />} />
          <Route path=":bookingId" element={<BookingPage />} />
        </Route>

        <Route path="/sales" element={ <PermissionRoute component={PrivateLayout} allowedRoles={allowedRolesSales} /> } >
          <Route index element={<SalesPage />} />
          <Route path=":saleId" element={<SalePage />} />
        </Route>

        <Route path="/vehicles" element={ <PermissionRoute component={PrivateLayout} allowedRoles={allowedRolesAll} /> } >
          <Route index element={<VehiclesPage />} />
          <Route path="rental" element={<RentalVehiclesPage />} />
          <Route path="rental/:vehiclesGroupId" element={<RentalVehiclesGroupPage />} />
          <Route path="rental/:vehiclesGroupId/:vehicleId" element={<RentalVehiclePage />} />

          <Route path="sale" element={<SaleVehiclesPage />} />
          <Route path="sale/:vehicleId" element={<SaleVehiclePage />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
