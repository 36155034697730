import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { maxDevice, minDevice } from "common/breakpoints";
// components
import { CardSetItemLi, CardSetUl } from "components/Common/Placeholders";

export const ContactsBox = styled.div`
  width: 100%;
  color: inherit;
  background-color: transparent;

  &.accent {
    margin-bottom: 16px;
  }

  @media ${maxDevice.desktopXL} {
    &.accent {
      margin-bottom: 32px;
    }
  }
`;

export const ContactsList = styled(CardSetUl)`
  justify-content: space-between;
  row-gap: 16px;

  .burger-menu & {
    flex-direction: column;
  }

  @media ${minDevice.tablet} {
    .main &,
    .footer & { row-gap: 24px; }

    .main & { --gap: 60px; } 
  }

  @media ${minDevice.desktopL} {
    .main & { --gap: 100px; }
  }
`;
export const ContactsItem = styled(CardSetItemLi)`
  display: flex;
  gap: 12px;
 
  .main & { color: var(--color--primary, #414141); }
  .footer & { color: var(--color--white, #fff); }

  & svg {
    flex: 0 0 32px;
    display: block;
    width: 32px;
    height: 32px;

    fill: currentColor;
  }

  @media ${minDevice.tablet} {
    .main & { --items: 2; }
    .footer & { --items: 3; }
  }

  @media ${minDevice.desktopL} {
    .main & { --items: 3; }

    & svg {
      flex: 0 0 36px;
      width: 36px;
      height: 36px;
    }
  }
`;

export const ContactsLinkWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ContactsLink = styled(Link)`
  color: var(--color--accent, #f19e1d);

  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;

  transition: var(--transition--100);

  .main & { font-size: 16px; }
  .footer & { font-size: 16px; }

  &:hover { opacity: 0.7; }

  @media ${minDevice.desktopL} {
    .main & { font-size: 24px; }
  }
`;
export const ContactsTitle = styled.span`
  .main & {
    // font-size: 24px;
    font-size: 16px; 
    color: var(--color--primary, #414141);
  }

  .footer & {
    font-size: 16px;
    color: #fff;
  }

  @media ${minDevice.desktopL} {
    .main & { font-size: 24px; }
  }
`;
