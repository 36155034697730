import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const getBookingByVehiclesGroupId = createAsyncThunk(
  "bookings/getBookingByVehiclesGroupId",
  async (id, thunkAPI) => {
    try {
      const res = await axios.get(`/bookings/vehicles/${id}`);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);


