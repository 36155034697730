export const text = {
  truck_rental: {
    en: "Truck Rental",
    cz: "Pronájem Dodávek",
  },
  choose_a_car_brand: {
    en: "Choose a Car Brand",
    cz: "Vyberte si značku vozidla",
  },
  pickup_point: {
    en: "Pickup Point",
    cz: "Místo vyzvednutí",
  },
  drop_off_point: {
    en: "Drop off Point",
    cz: "Místo odevzdání",
  },
  pick_up_date: {
    en: "Pick-up date",
    cz: "Datum vyzvednutí",
  },
  time: {
    en: "Time",
    cz: "Čas",
  },
  return_date: {
    en: "Return date",
    cz: "Datum vrácení",
  },
  drop_off_location_is_different: {
    en: "Drop off location is different",
    cz: "Místo odevzdání je jiné",
  },
  select: {
    en: "Select",
    cz: "Vybrat",
  },
  show_cars: {
    en: "Show Cars",
    cz: "Vybrat vozidla",
  },

  // ===== nav START =====
  home: {
    en: "Home",
    cz: "Domov",
  },
  car_rental: {
    en: "Car rental",
    cz: "Pronájem aut",
  },
  scooter_rental: {
    en: "Scooter rental",
    cz: "Pronájem skútrů",
  },
  cars_for_sale: {
    en: "Cars for sale",
    cz: "Auta na splátky",
  },
  sale: {
    en: "Cars for sale",
    cz: "Auta na splátky",
  },
  about_us: {
    en: "About us",
    cz: "O nás",
  },
  contacts: {
    en: "Contacts",
    cz: "Kontakty",
  },
  social_networks: {
    en: "Social Networks",
    cz: "Sociální sítě",
  },
  rental_of_scooters_for_couriers: {
    en: "Rental of scooters for Couriers",
    cz: "Pronájem skútrů pro kurýry",
  },
  rental_of_electric_scooters: {
    en: "Rental of Electric Scooters",
    cz: "Pronájem elektrických skútrů",
  },
  short_term_and_long_term_rent: {
    en: "Short-term and long term rent",
    cz: "Krátkodobý a dlouhodobý pronájem",
  },
  faq: {
    en: "Questions/Answers",
    cz: "FAQ",
  },
  // ===== nav END =====

  // ===== pages START =====
  pages: {
    home: {
      views: {
        hero: {
          title: {
            accent: {
              en: "Choose",
              cz: "Vyberte",
            },
            rest: {
              en: "your travel stile with us",
              cz: "si svůj styl cestování s námi",
            },
          },
        }, // +
        find_the_best_car: {
          title: {
            accent: {
              en: "Find the best",
              cz: "Najděte nejlepší nabídky",
            },
            rest: {
              en: "car or scooter hire deals in Prague",
              cz: "pronájmu auta nebo skútru v Praze",
            },
          },
        }, // +
        how_does_it_work: {
          title: {
            accent: {
              en: "How does",
              cz: "Jak to",
            },
            rest: {
              en: "it work?",
              cz: "funguje?",
            },
          }, // +
          title_find_a_car: {
            en: "Find a car or scooter",
            cz: "Najděte auto nebo skútr",
          }, // +
          text_find_a_car: {
            en: "Start driving right now: Choose a car or scooter on our website. Select rental dates. On our website, you will be offered various classes of vehicles from economy options to premium or cargo vehicles. When renting, you will have the opportunity to choose an insurance plan to drive safely and comfortably.",
            cz: "Začněte jezdit právě teď: Vyberte si auto nebo skútr na našem webu. Vyberte termíny pronájmu. Na našem webu vám budou nabídnuty různé třídy vozidel od ekonomické varianty až po prémiové nebo nákladní auta. Při pronájmu budete mít možnost vybrat si pojištění, abyste mohli jezdit v bezpečí a pohodlí.",
          }, // +
          title_reservation: {
            en: "Reservation",
            cz: "Rezervace",
          }, // +
          text_reservation: {
            en: "After you have selected the car, you will receive a confirmation email for the reservation, and our manager will contact you to arrange the details of the car handover.",
            cz: "Po výběru vozu obdržíte potvrzovací e-mail rezervace a náš manažer vás bude kontaktovat, abychom upřesnili detaily předání vozu.",
          }, // +
          title_car_pickup: {
            en: "Car Pickup",
            cz: "Předání vozu",
          }, // +
          text_car_pickup: {
            en: "Upon handing over the car at our office or at the car delivery location, our manager will prepare the rental agreement, take photos of the car documentation, and conduct a detailed inspection. They will explain the contract details to you. Upon signing the agreement, they will hand over the keys to you, and you can start using the car.",
            cz: "Při předání automobilu v naší kanceláři nebo na místě dodání vozu připraví náš manažer nájemní smlouvu, provede fotodokumentaci vozidla a detailní prohlídku. Vysvětlí vám podrobnosti smlouvy. Při podpisu smlouvy vám předá klíče a můžete začít používat automobil.",
          }, // +
        }, // +
        car_rental_at_alufcar: {
          title: {
            accent: {
              en: "Car rental",
              cz: "Pronájem aut",
            },
            rest: {
              en: "at Alufcar",
              cz: "od Alufcar",
            },
          },
          items_list: [
            {
              en: "REQUIRED DOCUMENTS: Passport and driver's license",
              cz: "POTŘEBNÉ DOKLADY: Pas a řidičský průkaz",
            },
            {
              en: "All Vehicles are in excellent technical condition",
              cz: "Všechny vozidla jsou v excelentním technickém stavu",
            },
            {
              en: "A convenient service that allows you to quickly reserve a car without any deposits",
              cz: "Pohodlná služba, která vám umožní rychle rezervovat auto bez jakýchkoliv záloh",
            },
            {
              en: "Clean interior and full tank upon car handover",
              cz: "Čistý interiér a plná nádrž při předání vozu",
            },
            {
              en: "Available prices and tariffs of our company",
              cz: "Dostupné ceny a tarify naší společnosti",
            },
            {
              en: "Highway toll sticker included in the price",
              cz: "Dálniční známka v ceně",
            },
            {
              en: "The possibility to drive our cars abroad without any additional fees",
              cz: "Možnost jezdit našimi auty do zahraničí Bez jakýchkoli dodatečných poplatků",
            },
            {
              en: "Complete insurance of our cars",
              cz: "Kompletní pojištění našich vozů",
            },
          ],
        }, // +
        rental_of_scooters: {
          title: {
            accent: {
              en: "Rental",
              cz: "Pronájem",
            },
            rest: {
              en: "of scooters",
              cz: "skútrů",
            },
          },
          title_rental_of_scooters_for_couriers: {
            en: "Rental of scooters for Couriers",
            cz: "Pronájem skútrů pro kurýry",
          },
          title_rental_of_electric_scooters: {
            en: "Rental of Electric Scooters",
            cz: "Pronájem elektrických skútrů",
          },
          title_short_term_and_long_term_rent: {
            en: "Short-term and long term rent",
            cz: "Krátkodobý a dlouhodobý pronájem",
          },
          text_profitable_rental_from_1_day: {
            en: "Profitable rental from 1 day a month or more",
            cz: "Výhodný pronájem od 1 dne až po měsíc nebo déle",
          },
        }, // +
      },
    }, // +
    about_us: {
      views: {
        hero: {
          title: {
            accent: {
              en: "Car rental",
              cz: "Autopůjčovna",
            },
            rest: {
              en: "in Prague",
              cz: "v Praze",
            },
          },
          text_list: {
            en: [
              "Aluf Car offers you a reliable solution. Our company specializes in car rental and offers a rich selection of vehicles to suit your needs. Whether you need a car for a day or a month, we provide competitive prices and high-quality service.",
              "We also offer the option of leasing a vehicle with a purchase option for those who want to own their own car after the lease term ends. All you need to do is pay the first installment ranging from 15% to 30%.",
              "Car rental at Aluf Car is the nearest car rental service and an economical solution for various situations. You may need a car for a business trip, vacation, or relocation. With our numerous options, you will always find a car that suits your needs.",
              "Renting a scooter at Aluf Car is the best option at the best prices for couriers. We offer the best service and support in any situation. We are chosen for our advantages, prices, and service.",
              "How to choose the right car?",
              "When choosing, it is important to consider your needs. We offer a wide range of classes, from economy to premium, as well as SUVs and sedans. Our customers can rely on safety, comfort, and convenience during their trip. ",
              'Advantages of "Aluf Car"',
              "Accessibility: We provide affordable prices.",
              "Freedom: Car rental gives you the freedom to move and travel at a time that suits you. ",
              "Online booking: You can easily book cars or scooters on our website at any time of the day. ",
              "Long-term rental: Our customers can take advantage of long-term rental programs with additional benefits. ",
              "Promotions and discounts: We regularly offer attractive discounts and promotions, vouchers for repeat rentals. ",
              "Don't miss the opportunity to book a reliable car or scooter at an affordable price. Our company is always ready to offer the best options in the Czech Republic. ",
            ],
            cz: [
              "Aluf Car vám nabízí spolehlivé řešení. Naše společnost je specializovaná na autopůjčovnu a nabízí bohatý výběr vozů podle vašich potřeb. Pokud potřebujete auto na den nebo měsíc, poskytujeme dostupné ceny a vysokou úroveň služeb. ",
              "Také nabízíme možnost pronájmu vozidla s opcí odkoupení pro ty, kteří chtějí po skončení platnosti pronájmu vlastnit své vlastní vozidlo. Stačí zaplatit první splátku ve výši 15% až 30%.",
              "Pronájem auta v Aluf Car je nejbližší autopůjčovna a ekonomické řešení pro různé situace. Můžete potřebovat auto na služební cestu, dovolenou nebo přestěhování. S našimi mnoha možnostmi vždy najdete auto, které vyhovuje vašim potřebám.",
              "Pronájem skútru v Aluf Car je nejlepší možností za nejlepší ceny pro kurýry. Nabízíme nejlepší servis a podporu v každé situaci. Jsme vybíráni pro naše výhody, ceny a servis.",
              "Jak vybrat vhodné auto?",
              "Při výběru je důležité zohlednit vaše potřeby. Nabízíme široký výběr různých tříd, od ekonomických po prémiové třídy, stejně jako terénní vozidla a osobní automobily. Naši zákazníci se mohou spolehnout na bezpečnost, komfort a pohodlí během cesty.",
              'Výhody společnosti "Aluf Car"',
              "Dostupnost: Poskytujeme dostupné ceny.",
              "Svoboda: Autopůjčovna vám dává svobodu pohybu a cestování v čase, který vám vyhovuje.",
              "Rezervace online: Můžete snadno rezervovat auta nebo skútry na našem webu kdykoli během dne.",
              "Dlouhodobý pronájem: Naši zákazníci mohou využívat dlouhodobé pronájemní programy s dodatečnými výhodami.",
              "Akce a slevy: Pravidelně nabízíme výhodné slevy a akce, vouchery pro opětovný pronájem.",
              "Nezmeškejte příležitost rezervovat spolehlivé auto nebo skútr za dostupnou cenu. Naše společnost je vždy připravena nabídnout ty nejlepší možnosti v České republice.",
            ],
          },
        }, // +
        why_customers_choose_us: {
          title: {
            en: "Why customers choose us",
            cz: "Proč nás zákazníci volí",
          },
          text_list: {
            en: [
              "Wide selection of vehicles: We offer a diverse portfolio of vehicles of various classes, from economy to luxury. Regardless of your preferences or needs, you will find a vehicle that suits you with us. ",
              "Professional service: Our team is fully trained and experienced to provide the highest level of service. We strive to ensure that every customer is fully satisfied with our services from start to finish.",
              "Flexible rental options: Whether you need a car for a few days or a longer period, we have a solution for you. Our flexible rental options allow you to tailor your rental to your needs and budget. ",
              "Transparent pricing policy: We believe in transparency and fairness. Our pricing policy is clear and understandable, with no hidden fees or unpleasant surprises. ",
              "Quality and reliability: Every vehicle in our fleet is regularly serviced and maintained in optimal condition. With Aluf Car, you can be sure that you will receive a vehicle you can rely on. ",
              "Thanks to these factors, more and more customers are choosing our services, appreciating the quality, reliability, and professional approach",
            ],
            cz: [
              "Široký výběr vozidel: Nabízíme rozmanité portfolio vozidel různých tříd, od ekonomických až po luxusní. Bez ohledu na vaše preference nebo potřeby, najdete u nás vozidlo, které vám vyhovuje.",
              "Profesionální servis: Náš tým je plně vyškolen a zkušený, aby poskytoval nejvyšší úroveň služeb. Snažíme se zajistit, aby každý zákazník byl plně spokojen s našimi službami od začátku do konce. ",
              "Flexibilní možnosti pronájmu: Nezáleží na tom, zda potřebujete auto na pár dní nebo na delší dobu, máme pro vás řešení. Naše flexibilní možnosti pronájmu vám umožňují přizpůsobit si pronájem podle vašich potřeb a rozpočtu. ",
              "Transparentní cenová politika: Věříme v transparentnost a férovost. Naše cenová politika je jasná a srozumitelná, bez skrytých poplatků nebo nepříjemných překvapení. ",
              "Kvalita a spolehlivost: Každé vozidlo v naší flotile je pravidelně servisováno a udržováno v optimálním stavu. S Aluf Car můžete být jisti, že dostanete vozidlo, na které se můžete spolehnout. ",
              "Díky těmto faktorům si naše služby vybírá stále více zákazníků, kteří oceňují kvalitu, spolehlivost a profesionální přístup.",
            ],
          },
        },
      }, // +
    }, // +
    contacts: {
      views: {
        hero: {
          text_we_are_open_daily_from: {
            en: "We are open daily from 8 am to 22 pm. (GMT+2)",
            cz: "Otevřeno máme denně od 8 do 22 hodin (GMT+2)",
          },
        },
      },
    }, // +
    rental_car: {
      views: {
        hero: {
          title: {
            accent: {
              en: "Car rental",
              cz: "Pronájem aut",
            },
            rest: {
              en: "at AlufCar",
              cz: "od Aluf Car",
            },
          },
          text_list: {
            en: [
              "Welcome to Aluf Car, your partner for car rental! We specialize in providing a wide range of cars across various categories at reasonable prices. Whether you need an economy car for everyday journeys or a luxury vehicle for a special occasion, you will find the right car with us",
              "We take pride in offering both short-term and long-term rentals to meet your mobility needs. Our clients can choose the rental duration according to their plans and budget",
              "One of our main competitive advantages is our low rental prices and high level of customer service. We constantly strive to offer the most attractive prices in the car rental market, allowing you to save money without compromising on service quality",
              "Our professional staff is always ready to help you choose and reserve the car that best suits your needs. We value each client and guarantee an individual approach to every transaction",
              "So don't hesitate - contact us today and take advantage of our low prices and excellent customer service for your next journey!",
            ],
            cz: [
              "Vítejte ve společnosti Aluf Car, vašem partnerovi pro pronájem automobilů! Specializujeme se na poskytování širokého výběru automobilů různých kategorií za přijatelné ceny. Bez ohledu na to, zda potřebujete auto z ekonomické třídy na každodenní cesty nebo luxusní automobil na zvláštní událost, u nás najdete ten správný vůz.",
              "Hrdíme se na to, že nabízíme jak krátkodobý, tak dlouhodobý pronájem, abychom uspokojili vaše potřeby v oblasti mobility. Naši klienti mohou vybírat délku pronájmu podle svých plánů a rozpočtu",
              "Jedním z našich hlavních konkurenčních výhod jsou naše nízké ceny pronájmu a vysoká úroveň zákaznického servisu. Neustále se snažíme nabídnout nejatraktivnější ceny na trhu pronájmu automobilů, abyste mohli ušetřit peníze bez kompromisů ve kvalitě služeb",
              "Naši profesionální zaměstnanci jsou vždy připraveni pomoci vám vybrat a rezervovat automobil, který nejlépe vyhovuje vašim potřebám. Každého klienta si vážíme a zaručujeme individuální přístup k každé transakci",
              "Takže neváhejte - obraťte se na nás ještě dnes a využijte našich nízkých cen a vynikajícího zákaznického servisu pro vaši další cestu!",
            ],
          },
        },
      },
    }, // +
    rental_of_scooters_for_couriers: {
      views: {
        hero: {
          title: {
            accent: {
              en: "Rental of scooters",
              cz: "Pronájem  skútrů",
            },
            rest: {
              en: "for couriers",
              cz: "pro kurýry",
            },
          },
          text_list: {
            en: [
              "Get ready to speed up your deliveries with Aluf Scooter Rental in Prague! We offer the ideal solution for couriers seeking convenience, savings, and efficiency.",
              "Our scooters are available at the most advantageous prices in Prague, making us the perfect choice for your business. We understand that speed and reliability are crucial for couriers, so our scooter rental terms guarantee the convenience and efficiency of your deliveries.",
              "When renting from us, you don't need to worry about high deposit payments. We offer the lowest deposit amounts to ensure maximum comfort and availability of your rental.",
              "Moreover, all our scooters are in perfect technical condition. We offer only new models older than two years to ensure the safety and reliability of your trips.",
              "Additionally, for long-term rentals, we offer additional discounts to help you save even more. Don't miss the opportunity to speed up your deliveries and save money with Aluf Scooter Rental. Contact us today to get started!",
            ],
            cz: [
              "Připravte se zrychlit své dodávky s Aluf Scooter Rental v Praze! Nabízíme ideální řešení pro kurýry, kteří hledají pohodlí, úspory a efektivitu.",
              "Naše skútry jsou k dispozici za nejvýhodnější ceny v Praze, což nás činí ideální volbou pro váš podnik. Rozumíme, že pro kurýry je důležitá rychlost a spolehlivost, proto naše podmínky pronájmu skútrů zaručují pohodlí a efektivitu vašich dodávek.",
              "Při pronájmu u nás nemusíte mít obavy z vysokých zálohových plateb. Nabízíme nejnižší zálohové částky, abychom vám zajistili maximální pohodlí a dostupnost vašeho pronájmu.",
              "Kromě toho jsou všechny naše skútry v perfektním technickém stavu. Nabízíme pouze nové modely starší než dva roky, abychom vám zajistili bezpečnost a spolehlivost vašich cest.",
              "Kromě toho při dlouhodobém pronájmu nabízíme další slevy, abychom vám pomohli ještě více ušetřit. Nezmeškejte příležitost zrychlit své dodávky a ušetřit peníze s Aluf Scooter Rental. Kontaktujte nás ještě dnes, abyste mohli začít!",
            ],
          },
        },
      },
    }, // +
    rental_of_electric_scooters: {
      views: {
        hero: {
          title: {
            accent: {
              en: "Rental of electric",
              cz: "Pronájem elektrických",
            },
            rest: {
              en: "scooters",
              cz: "skútrů",
            },
          },
          text_list: {
            en: [
              "We invite you to try renting electric scooters at Aluf Car! Electric scooters are an advanced means of transportation that offer several advantages over their gasoline counterparts.",
              "The first thing you'll notice is the eco-friendliness of electric scooters. They don't emit harmful emissions and don't pollute the environment, making them the ideal choice for environmentally responsible travel around the city.",
              "Additionally, electric scooters are more economical to operate. Charging the battery is cheaper than filling up with gasoline, allowing you to save on fuel and reduce transportation costs.",
              "Our company offers scooters with removable batteries, making the charging process even more convenient. You can easily swap out a depleted battery for a fully charged one and continue your journey without unnecessary delays.",
              "Furthermore, our scooters have an impressive range. With one battery charge, you can travel up to 180 km, providing you with sufficient energy for long trips around the city or beyond its borders.",
              "Charging the battery is also easy and convenient. You can use standard electrical outlets anywhere or even at home.",
              "Try renting an electric scooter from us, and you'll appreciate all the benefits of this modern means of transportation!",
            ],
            cz: [
              "Zveme vás vyzkoušet pronájem elektrických skútrů ve společnosti Aluf Car! Elektrické skútry jsou pokročilým prostředkem dopravy, který má řadu výhod oproti benzinovým alternativám.",
              "První věc, kterou si všimnete, je ekologičnost elektrických skútrů. Nevytvářejí škodlivé emise a neznečišťují životní prostředí, což z nich činí ideální volbu pro ekologicky odpovědné cestování po městě.",
              "Kromě toho jsou elektrické skútry ekonomičtější v provozu. Nabíjení baterie je levnější než tankování benzínu, což vám umožňuje šetřit na palivu a snižovat náklady na dopravu.",
              "Naše společnost nabízí skútry s vyměnitelnými bateriemi, což činí proces nabíjení ještě pohodlnějším. Můžete snadno vyměnit vybitou baterii za plně nabitou a pokračovat ve své cestě bez zbytečných prodlev.",
              "Kromě toho mají naše skútry impresivní dojezd. S jedním nabitím baterie můžete ujet až 180 km, což vám poskytuje dostatečnou energii pro dlouhé cesty po městě nebo za jeho hranice.",
              "Baterii je také snadné a pohodlné nabíjet. Můžete využít běžné elektrické zásuvky kdekoliv nebo dokonce doma.",
              "Vyzkoušejte si pronájem elektrického skútru u nás a oceníte všechny výhody tohoto moderního dopravního prostředku!",
            ],
          },
        },
      },
    }, // +
    short_term_and_long_term_rent: {
      views: {
        hero: {
          title: {
            accent: {
              en: "Short-term and",
              cz: "Krátkodobý i",
            },
            rest: {
              en: "long-term rent",
              cz: "dlouhodobý pronájem",
            },
          },
          text_list: {
            en: [
              "Welcome to Aluf Car! We offer a unique opportunity to rent scooters for your comfort and convenience in Prague. Whether you're a tourist visiting for vacation or a resident needing transportation for daily purposes or food delivery, we have a suitable rental option for you.",
              "We provide brand new scooters for short-term and long-term rentals. For those who want to explore the city or simply enjoy a stroll, we offer short-term rental options. If you need a scooter regularly, we are happy to offer long-term rentals suitable for both daily commutes and business, including food delivery.",
              "We have a wide range of scooters to choose from: electric and gasoline. Electric models are ideal for environmentally conscious travelers and those who appreciate silence and comfort. Gasoline scooters provide greater power and range, ideal for long rides or work in food delivery.",
              "Don't miss the opportunity to explore Prague on a comfortable scooter from Aluf Car! Contact us today to enhance your travel experience or daily life in the city.",
            ],
            cz: [
              "Vítejte v Aluf Car! Nabízíme jedinečnou možnost pronajmout si skútry pro váš pohodlí a komfort v Praze. Bez ohledu na to, zda jste turista, který přijel na dovolenou, nebo obyvatel potřebující dopravu pro každodenní účely nebo pro rozvoz jídla, máme pro vás vhodnou variantu pronájmu.",
              "Nabízíme zbrusu nové skútry na krátkodobý i dlouhodobý pronájem. Pro ty, kteří chtějí objevovat město nebo si jen užít procházku, máme možnost krátkodobého pronájmu. Pokud potřebujete skútr pravidelně, rádi vám nabídneme dlouhodobý pronájem, který je vhodný jak pro každodenní cesty, tak i pro podnikání, včetně rozvozu jídla.",
              "Máme širokou škálu skútrů na výběr: elektrické i benzínové. Elektrické modely jsou ideální pro ekologicky uvědoměné cestovatele a ty, kteří ocení ticho a pohodlí. Benzínové skútry poskytují větší výkon a dojezd, ideální pro dlouhé procházky nebo práci v oblasti rozvozu jídla.",
              "Nenechte si ujít příležitost objevovat Prahu na pohodlném skútru od Aluf Car! Kontaktujte nás ještě dnes a zlepšete si své cestování nebo každodenní život ve městě.",
            ],
          },
        },
      },
    }, // +
    cars_for_sale: {
      views: {
        hero: {
          title: {
            accent: {
              en: "Cars For Sale",
              cz: "Auta na splátky",
            },
            rest: {
              en: "in Aluf Car",
              cz: "v Aluf Car",
            },
          },
          text_list: {
            en: [
              "In our offer, we only have vehicles that are currently in stock and ready for immediate pickup. We do not include virtual cars photographed in showrooms or on used car lots. Each vehicle is carefully selected and inspected according to the strictest standards. Before delivery, all vehicles are thoroughly prepared in our own or authorized service centers. They undergo complete cleaning, disinfection, and paint renovation and waxing. We ensure maximum care and above-standard quality.",
              "If you cannot find the desired vehicle in our offer, don't worry, we will arrange a custom-made car exactly according to your wishes.",
              "Our clients can be individuals, sole traders, entrepreneurs, and large companies from the Czech Republic and Slovakia. Approval is fast and simple. You only need to provide two documents (ID card or passport + driver's license) + proof of current address. We do not require proof of income, we do not conduct checks of banking or non-banking registers, and we do not mind if you have executions or are insolvent. We will propose a realistic installment plan according to your possibilities. After payment, the car becomes your property.",
              "Choose a vehicle from our offer and within 30 minutes, you can drive away with a new car. The vehicle will be fully prepared, insured, and documented with a proper contract. We offer the option of vehicle handover by agreement, including weekends, delivery to a specified address, or client transportation.",
              "The vehicle can be paid off early at any time or a newer model can be ordered. Feel free to contact us for a personalized calculation or to order a vehicle.",
            ],
            cz: [
              "V naší nabídce máme pouze vozidla, která jsou aktuálně skladem a připravena k okamžitému odběru. Nezahrnujeme virtuální automobily vyfocené v showroomech nebo na autobazarech. Každé vozidlo pečlivě vybíráme a prověřujeme podle nejpřísnějších standardů. Před předáním jsou všechna vozidla důkladně připravena ve vlastním nebo autorizovaném servisu. Procházejí kompletní čištěním, dezinfekcí a renovací laku a voskováním. Dbáme na maximální péči a nadstandardní kvalitu.",
              "Pokud nenajdete požadované vozidlo v naší nabídce, nezoufejte, zajistíme vám automobil na zakázku přesně podle vašich přání.",
              "Naši klienti mohou být fyzické osoby, živnostníci, podnikatelé i velké společnosti z České republiky i Slovenska. Schvalování je rychlé a jednoduché. Stačí předložit pouze dva doklady (OP nebo PAS + ŘP) + doklad o aktuální adrese. Nepožadujeme doložení příjmu, neprovádíme kontroly bankovních ani nebankovních registrů, a nevadí nám exekuce nebo insolvence. Navrhneme vám reálnou splátku podle vašich možností. Po zaplacení se automobil stává vaším majetkem.",
              "Vyberte si vozidlo z naší nabídky a do 30 minut můžete vyrazit s novým autem. Vozidlo bude plně připraveno, pojištěno a dokumentováno smlouvou. Nabízíme možnost předání vozidla podle dohody, včetně víkendů, dopravu na stanovenou adresu nebo přepravu klientů. Vozidlo je možné kdykoli doplatit předčasně nebo objednat novější model. Neváhejte nás kontaktovat pro osobní kalkulaci nebo objednání vozidla. ",
            ],
          },
        }, // +
        benefits: {
          title: {
            accent: {
              en: "for sale",
              cz: "na splátky",
            },
            rest: {
              en: "Car",
              cz: "Auta",
            },
          },
          items_list: [
            {
              en: "Passport approval only",
              cz: "Pouze schválení pasu",
            },
            {
              en: "Convenient installment plan",
              cz: "Pohodlný splátkový kalendář",
            },
            {
              en: "Initial payment from 0% Down payment",
              cz: "Počáteční platba od 0 % Akontace",
            },
            {
              en: "The possibility of buying a car according to your requirements",
              cz: "Možnost koupě vozu dle Vašich požadavků",
            },
            {
              en: "Full car service before we buy it",
              cz: "Záruka, že vůz je před prodejem plně servisován a opraven",
            },
            {
              en: "Registration within 1 day",
              cz: "Registrace 1 den",
            },
            {
              en: "Without proof of income and references",
              cz: "Bez doložení příjmu a referencí",
            },
            {
              en: "95% approval",
              cz: "95% schvalení",
            },
          ],
        }, // +
        difference: {
          title: {
            accent: {
              en: "vehicle from us",
              cz: "vozidlem u nás",
            },
            rest: {
              en: "The difference between leasing and an installment ",
              cz: "Rozdíl  mezi leasingem a splátkovým",
            },
          },
          link_title: {
            en: "Cars on installments from us",
            cz: "Auta na splátky u nás",
          },
          items_list: [
            {
              en: "Car leasing",
              cz: "Leasing aut",
            },
            {
              en: "The possibility of buying a car only from an authorized dealer with increased prices",
              cz: "Možnost nákupu vozu pouze u autorizovaného prodejce se zvýšenými cenami",
            },
            {
              en: "The price of the loan depends on your workplace, credit history and your income",
              cz: "Cena půjčky závisí na vašem pracovišti, úvěrové historii a vašem příjmu",
            },
            {
              en: "The interest rate of the loan can be changed during the course of the loan",
              cz: "Úrokovou sazbu úvěru lze v průběhu úvěru měnit",
            },
            {
              en: "Mandatory CASCO + life insurance",
              cz: "Povinné CASCO + životní pojištění",
            },
            {
              en: "Bez možnosti vrácení vozu",
              cz: "Without the possibility of returning the car",
            },
            {
              en: "Without any additional services for the service/repair of the car",
              cz: "Bez jakýchkoliv doplňkových služeb pro servis/opravu vozu",
            },
          ],
          items_list_2: [
            {
              en: "We will select a car from any car dealer or private person",
              cz: "Vybereme vůz od jakéhokoli autobazaru nebo soukromé osoby",
            },
            {
              en: "We are not interested in credit history or proof of income. Our clients receive approval in 95%",
              cz: "Kreditní historie nebo potvrzení o příjmu nás nezajímá. Naši klienti obdrží schválení v 95%",
            },
            {
              en: "Stable repayment schedule, conditions do not change",
              cz: "Stabilní splátkový kalendář, podmínky se nemění",
            },
            {
              en: "No life insurance is required",
              cz: "Není nutné žádné životní pojištění",
            },
            {
              en: "The car can be exchanged",
              cz: "Auto lze vyměnit",
            },
            {
              en: "Constant help in all matters: Finding and ordering spare parts, maintenance, tires, insurance and much more",
              cz: "Neustálá pomoc ve všech záležitostech: Hledání a objednávka náhradních dílů, údržba, pneumatiky, pojištění a mnoho dalšího. Veškeré dotazy na klíč",
            },
          ],
        }, // +
        who_will_it_suit: {
          title: {
            accent: {
              en: "Who will",
              cz: "Komu",
            },
            rest: {
              en: "it suit?",
              cz: "to bude vyhovovat?",
            },
          },
          items_list: [
            {
              en: "Young employee starting a career: Young people who are just starting to work often have limited access to finances.",
              cz: "Mladý pracovník začínající kariéru: Mladí lidé, kteří teprve začínají pracovat, často mají omezený přístup k financím.",
            },
            {
              en: "Person with fluctuating financial situation: For people who frequently change jobs or have irregular income, purchasing a car on installments can be a better strategy.",
              cz: "Osoba s proměnlivou finanční situací: Pro lidi, kteří často mění zaměstnání nebo mají nepravidelný příjem, může být nákup vozu na splátky lepší strategií.",
            },
            {
              en: "Person with limited credit history: For individuals with limited credit history who may have difficulty obtaining loans or car financing, purchasing a car on installments from Aluf car company can be a beneficial option.",
              cz: "Osoba s omezenou kreditní historií: Pro lidi s omezenou kreditní historií, kteří by mohli mít obtíže s získáním úvěrů nebo autokreditů, může být nákup auta na splátky od společnosti Aluf car výhodnou variantou.",
            },
            {
              en: "Student in need of transportation for study and work: Students who have limited access to funds but require transportation for studying and working can take advantage of the opportunity to purchase a car on installments.",
              cz: "Student potřebující dopravu pro studium a práci: Studenti, kteří mají omezený přístup k penězům, ale potřebují dopravu pro studium a práci, mohou využít možnost nákupu automobilu na splátky.",
            },
          ],
        }, // +
      },
    }, // +
    product_sale_page: {
      views: {
        car_for_sale_from_us: {
          title: {
            en: "Car for sale from us",
            cz: "Auta na splátky od nás",
          },
          // text_list: {
          //   en: [
          //     "100 % guarantee that the car has actual mileage on the tachometer",
          //     "Guarantee that the car is fully technically serviced",
          //     "Minimum down payment on the car",
          //     "Possibility of VAT deduction",
          //     "Data processing speed(you can pick up the car in few hours)",
          //     "24/7 support",
          //     "Highway stamp for one year as a gift",
          //     "The best car insurance price",
          //   ],
          //   cz: [
          //     "100 % záruka, že auto má na tachometru skutečně ujeté kilometry",
          //     "Záruka, že vůz je plně technicky servisován.",
          //     "Minimální acontace na auto",
          //     "Možnost odpočtu DPH",
          //     "Rychlost zpracování dat (auto si můžete vyzvednout za několik hodin)",
          //     "24/7 podpora",
          //     "Dálniční známka na jeden rok jako dárek",
          //     "Nejlepší cena pojištění auta",
          //   ],
          // },
          items_list: [
            {
              en: "100 % guarantee that the car has actual mileage on the tachometer",
              cz: "100 % záruka, že auto má na tachometru skutečně ujeté kilometry",
            },
            {
              en: "Guarantee that the car is fully technically serviced",
              cz: "Záruka, že vůz je plně technicky servisován.",
            },
            {
              en: "Minimum down payment on the car",
              cz: "Minimální acontace na auto",
            },
            {
              en: "Possibility of VAT deduction",
              cz: "Možnost odpočtu DPH",
            },
            {
              en: "Data processing speed(you can pick up the car in few hours)",
              cz: "Rychlost zpracování dat (auto si můžete vyzvednout za několik hodin)",
            },
            {
              en: "24/7 support",
              cz: "24/7 podpora",
            },
            {
              en: "Highway stamp for one year as a gift",
              cz: "Dálniční známka na jeden rok jako dárek",
            },
            {
              en: "The best car insurance price",
              cz: "Nejlepší cena pojištění auta",
            },
          ],
        },
        other: {
          text_phone_number_for_contacting_us: {
            en: "Phone number for contacting us",
            cz: "Telefonní číslo, na které nás můžete kontaktovat",
          },
          text_order_a_car_for_sale: {
            en: "Order a car for sale",
            cz: "Objednejte si auto na splátky",
          },
        },
      },
    }, // +
    product_rental_page: {
      views: {
        other: {
          text_how_we_count_a_rent_price: {
            en: "How we count a rent price",
            cz: "Jak počítáme cenu pronájmu",
          },
          text_take_a_look: {
            en: "take a look",
            cz: "podívat se",
          },
          text_what_in_price: {
            en: "What in price",
            cz: "Co je zahrnuto v ceně",
          },
          text_choose_an_insurance_plan: {
            en: "Choose an insurance plan",
            cz: "Vyberte si plán pojištěni",
          },
          text_if_you_make_an_urgent_reservation: {
            en: "If you make an urgent reservation, the rental manager may contact you and ask you to change the pick-up time or change the car. If you are not satisfied with his proposal, please contact us. We'll find another car.",
            cz: "Pokud provedete urgentní rezervaci, může vás kontaktovat manažer půjčovny a požádat vás o změnu času vyzvednutí nebo výměnu vozu. Pokud vám jeho návrh nevyhovuje, kontaktujte nás. Najdeme jiné auto",
          },
        },
      },
    }, // +
  }, // +
  // ===== pages END =====

  // ===== views START =====
  views: {
    reviews: {
      title: {
        accent: {
          en: "What our customers",
          cz: "Co naši zákazníci o nás píšou",
        },
        rest: {
          en: "write about us",
          cz: "o nás píšour",
        },
      },
      items_list: [
        {
          en: "I am very satisfied with my experience with Alufcar company. Their professional and helpful approach really pleasantly surprised me. I rented a car for vacation, and the whole process was very simple and comfortable.",
          cz: "Jsem velmi spokojený s mým zážitkem s firmou Alufcar. Jejich profesionální a vstřícný přístup mě opravdu mile překvapil. Pronajal jsem si auto na dovolenou a celý proces byl velmi jednoduchý a pohodlný.",
        },
        {
          en: "The selection and reservation of the scooter were quick and easy. The staff was very professional and willing to answer all my questions. The scooter I rented was in perfect condition and provided a smooth ride around the city.",
          cz: "Vybírání a rezervace skútru proběhly rychle a snadno. Personál byl velmi profesionální a ochotný zodpovědět všechny mé dotazy. Skútr, který jsem si pronajal, byl v perfektním stavu a poskytoval plynulou jízdu po městě.",
        },
        {
          en: "I was very pleased with the large fleet of vehicles offered by the company. They provide a wide selection of cars from various brands and models, which is impressive.",
          cz: "Byl jsem velmi spokojen s velkým vozovým parkem společnosti. Nabízejí široký výběr vozidel různých značek a modelů, což je dojímavé.",
        },
      ],
    }, // +
    benefits: {
      title: {
        accent: {
          en: "Why",
          cz: "Proč",
        },
        rest: {
          en: "us",
          cz: "právě my",
        },
      },
      items_list: [
        {
          en: "The lowest prices and tariff",
          cz: "Nejnižší ceny a tarify",
        },
        {
          en: "The POSSIBILITY TO TRAVEL ABROAD without additional fees",
          cz: "MOŽNOST CESTOVAT DO ZAHRANIČÍ bez dalších poplatků",
        },
        {
          en: "Customer support 24/7",
          cz: "Asistenční podpora 24/7",
        },
        {
          en: "Speed of registration and transfer of the car. within 10 minutes",
          cz: "Rychlost registrace a předání vozidla. do 10 minut.",
        },
        {
          en: "Comprehensive insurance for all our cars",
          cz: "Komplexní pojištění všech našich vozidel",
        },
      ],
    }, // +
    how_we_count_a_rent_price: {
      title: {
        en: "How we count a rent price",
        cz: "Jak počítáme cenu pronájmu",
      },
      text_for_example_if_you_rent_a_car: {
        en: "For example. If you rent a car for a short period, the rental cost per day is more expensive than if you rent a car for a long period. the longer the rental period, the cheaper it is for you. ",
        cz: "Například.  Pokud si půjčíte auto na krátkou dobu, náklady na pronájem na den jsou dražší, než když si půjčíte auto na dlouhou dobu. čím delší doba pronájmu, tím levnější pro vás",
      },
      text_list: {
        en: [
          "1-2 days 40 euro/day",
          "3-7 days 30 euro/day",
          "8-14 days 20 euro/day",
          "15-30 days 10 euro/day",
        ],
        cz: [
          "1-2 dní 700 czk/den",
          "3-7 dní 500 czk/den",
          "8-14 dní 300 czk/den",
          "15-30 dní 200 czk/den",
        ],
      },
    }, // +
  }, // +
  // ===== views END =====
  // ====== words ======

  send_a_message: {
    en: "Send a message",
    cz: "Poslat zprávu",
  },
  send_a_request: {
    en: "Send a request",
    cz: "Poslat zprávu",
  },
  login: {
    en: "Login",
    cz: "Přihlásit se",
  },
  logout: {
    en: "Logout",
    cz: "Odhlásit se",
  },
  search: {
    en: "Search",
    cz: "Hledat",
  },
  filters: {
    en: "Filters",
    cz: "Filtry",
  },
  vehicle_type: {
    en: "Vehicle type",
    cz: "Typy vozidel",
  },
  car_brands: {
    en: "Car brands",
    cz: "Značky aut",
  },
  day: {
    en: "day",
    cz: "den",
  },
  days: {
    en: "days",
    cz: "dní",
  },
  cost: {
    en: "Cost",
    cz: "Cena",
  },
  rent_for: {
    en: "Rent for",
    cz: "Pronájem na",
  },
  book: {
    en: "Book now",
    cz: "Rezervace",
  },
  book_now: {
    en: "Book now",
    cz: "Rezervace",
  },
  to_add: {
    en: "To add",
    cz: "Přidát",
  },
  close_it: {
    en: "Close it",
    cz: "Zavřít",
  },
  free: {
    en: "Free",
    cz: "Zdarma",
  },
  address: {
    en: "Address",
    cz: "Adresa",
  },
  enter_address: {
    en: "Enter address",
    cz: "Zadejte adresu",
  },
  date_of_birth: {
    en: "Date of birth",
    cz: "Datum narození",
  },
  a_comment: {
    en: "A comment",
    cz: "Komentář",
  },
  phone_number: {
    en: "Phone number",
    cz: "Telefonní číslo",
  },
  car: {
    en: "Car",
    cz: "Vozidlo",
  },
  cars: {
    en: "Cars",
    cz: "Vozidel",
  },
  view_deal: {
    en: "View deal",
    cz: "Zobrazit detaily",
  },
  cars_are_avalable: {
    en: "cars are avalable",
    cz: "vozidla jsou k dispozici",
  },
  insurance: {
    en: "Insurance",
    cz: "Pojištění",
  },
  delivery: {
    en: "Delivery",
    cz: "Dodávka",
  },
  total: {
    en: "Total",
    cz: "Celkem",
  },
  deposit: {
    en: "Deposit",
    cz: "Kauce",
  },
  deposit_2: {
    en: "Deposit",
    cz: "Akontace",
  },
  to_pay_now: {
    en: "To pay now",
    cz: "Platit nyní",
  },
  specifications: {
    en: "Specifications",
    cz: "Specifikace",
  },
  year_of_manufacture: {
    en: "Year of manufacture",
    cz: "Rok výroby",
  },
  gearbox: {
    en: "Gear box",
    cz: "Převodovka",
  },
  transmission: {
    en: "Transmission",
    cz: "Převodovka",
  },
  drive: {
    en: "Drive",
    cz: "Pohon",
  },
  engine_power: {
    en: "Power",
    cz: "Výkon",
  },
  engine_capacity: {
    en: "Engine capacity",
    cz: "Objem motoru",
  },
  fuel_consumption: {
    en: "Consumption",
    cz: "Spotřeba",
  },
  fuel: {
    en: "Fuel",
    cz: "Palivo",
  },
  petrol: {
    en: "Petrol",
    cz: "Benzin",
  },
  diesel: {
    en: "Diesel",
    cz: "Diesel",
  },
  seats: {
    en: "Seats",
    cz: "Počet míst",
  },
  from: {
    en: "from",
    cz: "od",
  },
  credit: {
    en: "Credit",
    cz: "Splátka ",
  },
  order: {
    en: "Order",
    cz: "Objednat",
  },

  additional_services: {
    en: "Additional services",
    cz: "Doplňkové služby",
  },
  place_and_date_of_rental: {
    en: "Place and Date of Rental",
    cz: "Místo a datum pronájmu ",
  },
  driver_information: {
    en: "Driver information",
    cz: "Informace o řidiči",
  },
  first_and_last_name_in_latin: {
    en: "First and last name in Latin",
    cz: "Jméno a příjmení v latině.",
  },
  thank_you_for_your_request: {
    en: "Thank you for your request",
    cz: "Děkujeme za Vaši žádost",
  },
  our_manager_will_contact_you_within_10_minutes: {
    en: "Our manager will contact you within 10 minutes",
    cz: "Náš manažer vás bude kontaktovat do 10 minut",
  },
  // set_pickup_and_return_date: {
  //   en: "Set pickup and return dates and click search",
  //   cz: "Nastavte data vyzvednutí a vrácení a klikněte na Hledat",
  // },
  set_pickup_and_return_date: {
    en: "Set pickup and return date",
    cz: "Nastavte datum vyzvednutí a vrácení",
  },

  // ===== private =====
  replacement_date: {
    en: "Replacement date",
    cz: "Datum změny",
  },
};
