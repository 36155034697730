import React from "react";
// styled
import { OfficeNavItem, OfficeNavItemLink, OfficeNavList, OfficeNavTag, } from "./OfficeNav.styled";

const OfficeNavSalesManager = ({ setIsBurgerMenuOpen, isSmallView }) => {
  const navListData = [
    {
      name: "Sales",
      url: "/sales",
      end: true,
    },
    {
      name: "Vehicles",
      url: "/vehicles/sale",
    },
  ];

  const handleClick = () => {
    if (!isSmallView) return;
    if (setIsBurgerMenuOpen) return setIsBurgerMenuOpen(false);
  };

  return (
    <OfficeNavTag>
      <OfficeNavList>
        {navListData?.map((i, idx) => {
          return (
            <OfficeNavItem key={idx} className={i?.className || ""}>
              <OfficeNavItemLink className={i?.className || ""} onClick={() => handleClick(i?.name)} to={i?.url} end={i?.end}>
                {i?.name}
              </OfficeNavItemLink>
            </OfficeNavItem>
          );
        })}
      </OfficeNavList>
    </OfficeNavTag>
  );
};

export default OfficeNavSalesManager;
