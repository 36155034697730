import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const updateVehicleById = createAsyncThunk(
  "vehicles/updateVehicleById",
  async ({id, data}, thunkAPI) => {
    try {
      const res = await axios.patch(`/vehicles/${id}`, data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
