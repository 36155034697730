import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const getTextsPublic = createAsyncThunk(
  "texts/getTextsPublic",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/texts/public");
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
