import { getCurrentLangPrice, isAvailableArray } from "helpers/data";

export default function getNormalizeAdditionalServices(additionalServices) {
  const isAdditionalServicesSelected = Boolean(isAvailableArray(additionalServices?.filter(i => i?.count > 0))?.length);
  const selectedAdditionalServices = isAdditionalServicesSelected ? additionalServices?.filter(i => i?.count > 0): null;
  let priceAdditionalServices = { eur: 0, czk: 0 };
  if (isAdditionalServicesSelected) priceAdditionalServices = isAvailableArray(selectedAdditionalServices)?.reduce(
    (prev, i) => {
      const totalEUR = prev?.eur + i?.count * getCurrentLangPrice(i?.price, "en");
      const totalCZK = prev?.czk + i?.count * getCurrentLangPrice(i?.price, "cz");
      return { eur: totalEUR, czk: totalCZK };
    },
    { eur: 0, czk: 0 }
  );


  const res = {
    list: selectedAdditionalServices,
    price: priceAdditionalServices,
  }

  return res
}