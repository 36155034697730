import { sortDates1key, sortDatesTimestamp2keys, sortLocalDates1key } from "./sortDates";
import { sortLocalDatesTimes1key } from "./sortDatesTimes";
import { sortStings1key, sortStings2keys, sortStings3keys } from "./sortStings";
import { sortNumbers1key, sortNumbers2keys, sortNumbers3keys } from "./sortNumbers";

export default function getSortedData({dataToSort, sortKey, sortType, grow, newSortIndex = null, currentLang = null, currency = null }) {
  switch (sortType) {
    case "sortNumbers1key":
      return sortNumbers1key(dataToSort, grow, sortKey);
    case "sortStings1key":
      return sortStings1key(dataToSort, grow, sortKey);
    case "sortDates1key":
      return sortDates1key(dataToSort, grow, sortKey);
    case "sortLocalDates1key":
      return sortLocalDates1key(dataToSort, grow, sortKey);
    case "sortLocalDatesTimes1key":
      return sortLocalDatesTimes1key(dataToSort, grow, sortKey);

    case "sortStings2keys":
      return sortStings2keys(dataToSort, grow, newSortIndex, sortKey);
    case "sortStings3keys":
      return sortStings3keys(dataToSort, grow, newSortIndex, sortKey, currentLang);
    case "sortNumbers2keys":
      return sortNumbers2keys(dataToSort, grow, newSortIndex, sortKey);
    case "sortNumbers3keys":
      return sortNumbers3keys(dataToSort, grow, newSortIndex, sortKey, currency);
    case "sortDatesTimestamp2keys":
      return sortDatesTimestamp2keys(dataToSort, grow, newSortIndex, sortKey);

    default:
      return dataToSort;
  }
}
