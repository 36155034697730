const { createBooking } = require("./createBooking");
const { getBookings } = require("./getBookings");
const { getBookingById } = require("./getBookingById");
const { updateBookingById } = require("./updateBookingById");
const { getBookingByVehiclesGroupId } = require("./getBookingByVehiclesGroupId");

module.exports = {
  createBooking,
  getBookings,
  getBookingById,
  updateBookingById,
  getBookingByVehiclesGroupId,
};
