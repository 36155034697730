import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { maxDevice } from "common/breakpoints";

export const ContactsLineBox = styled.div`
  &.burger-menu {
    display: block;
    width: 100%;
  }

  @media ${maxDevice.tablet} {
    display: none;
  }
`;
export const ContactsLineList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 16px;

  .burger-menu & {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 24px;
  }
`;
export const ContactsLineItem = styled.li`
  .burger-menu & {
    padding: 8px 12px;
  }
`;
export const ContactsLineLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;

  color: var(--color--primary, #414141)
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  & svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;

    fill: currentColor;
  }

  &:hover {
    color: var(--color--accent, #f19e1d);
  }
`;
