import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const getSalesVehicles = createAsyncThunk(
  "vehicles/getSalesVehicles",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/vehicles/sales");
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
