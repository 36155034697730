import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { maxDevice, minDevice } from "common/breakpoints";
// components
import { Container } from "components/Common/common.styled";

export const FooterTag = styled.footer`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 36px 0 56px;

  color: var(--color--white, #fff);
  background-color: var(--color--primary, #414141);

  @media ${minDevice.tablet} {
    padding: 48px 0 104px;
  }
  @media ${minDevice.desktopL} {
    padding: 60px 0 112px;
  }
  // @media ${minDevice.desktopXL} {
  //   padding: 60px 0 112px;
  // }
`;

export const FooterTop = styled.div`
  width: 100%;
`;
export const FooterBottom = styled.div`
  width: 100%;
`;
export const FooterTopContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 52px;

  @media ${minDevice.tablet} {
    gap: 72px;
  }

  @media ${minDevice.desktopL} {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
  @media ${minDevice.desktopXL} {
    // flex-direction: row;
    // justify-content: space-between;
    gap: 90px;
  }
  
`;

export const FooterBox1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;

  @media ${maxDevice.desktopL} {
    align-items: center;
  }
  // @media ${maxDevice.desktopXL} {
  //   align-items: center;
  // }

  @media ${minDevice.desktopL} {
    max-width: 500px;
    // max-width: calc((100% - 90px) / 2);
  }
  @media ${minDevice.desktopXL} {
    max-width: calc((100% - 90px) / 2);
  }

`;
export const FooterBox2 = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media ${minDevice.desktopL} {
    // max-width: calc((100% - 30px) / 2);
    gap: 52px;
  }
  @media ${minDevice.desktopXL} {
    max-width: calc((100% - 30px) / 2);
    gap: 52px;
  }
`;

export const FooterBottomContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  @media ${minDevice.tablet} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;
export const FooterPolicyLink = styled(Link)`
  color: var(--color--white, #fff);
  text-align: right;
  font-size: 16px;

  transition: var(--transition--100);

  &:hover {
    color: var(--color--accent, #F19E1D);
  }
`;
