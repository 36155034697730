import React, { useState } from 'react';
// helpers
import { compareStrings, isAvailableArray } from 'helpers';
// styled
import { DropdownItem, DropdownList, DropdownPlaceholder, DropdownWrap } from './Dropdown2.styled';

const Dropdown2 = ({ dropdownData, activeItem, placeholder = 'Select', className, style, disabled }) => {
  const [showOptionsList, setShowOptionsList] = useState(false);
  const dropdownClassName = disabled ? `disabled ${className}` : showOptionsList ? `active ${className}` : className;

  function onDropdownClick() {
    setShowOptionsList(!showOptionsList);
  }

  return (
    <>
      <DropdownWrap style={style} className={dropdownClassName} onClick={() => onDropdownClick()}>
        <DropdownList className={showOptionsList && "active"}>
          {!activeItem &&<DropdownItem className={"active"}><DropdownPlaceholder>{placeholder}</DropdownPlaceholder></DropdownItem>}
          {dropdownData && isAvailableArray(dropdownData)?.map((i, idx) => {
            const isActive = compareStrings(i?.itemCompareStringsData, activeItem);

            return (<DropdownItem key={idx} className={isActive && "active"}>{i?.component}</DropdownItem>)
          })}
        </DropdownList>
      </DropdownWrap>
    </>
  )
}

export default Dropdown2