import { useSelector } from "react-redux";
// redux
import { selectLang } from "redux/lang/langSelectors";
// common
import { availableCurrency, availableSymbol } from "common/constants";

const useLang = () => {
  const currentLang = useSelector(selectLang) || "cz";
  let currentLangAttribute = "cs";
  if (currentLang === "en") currentLangAttribute = "en";
  // let currentLangAttribute = "cs_CZ";
  // if (currentLang === "en") currentLangAttribute = "en_US";

  return {
    currentLang,
    currentLangAttribute,
    currency: availableCurrency[currentLang],
    symbol: availableSymbol[currentLang],
  };
};

export default useLang;
