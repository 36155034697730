import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
// redux
import { getLocations } from 'redux/locations/operations';
import { getVehiclesBySearchQuery } from 'redux/vehicles/operations';
import { selectLocationsList } from 'redux/locations/locationsSelectors';
import { selectPublicRentalVehicles } from 'redux/vehicles/vehiclesSelectors';
// hooks
import { useLang } from 'hooks';
// helpers
import { compareStrings, getLocalDate, getUniqueValueFromArrayKeys1, isAvailableArray, serializeSearchParams, generateSearchUrl, getTranslate } from 'helpers';
// common
import { locationList, timeOptions } from 'common/constants';
// components
import Modal from 'components/Modal/Modal';
import Dropdown2 from 'components/Dropdown/Dropdown2';
import Datepicker from 'components/Datepicker/Datepicker';
// styled
import { SearchVehiclesFormBtn, SearchVehiclesFormCheckboxLabel, SearchVehiclesFormMainBox, SearchVehiclesFormRadioLabel, SearchVehiclesFormTag, SearchVehiclesSection } from "./SearchVehiclesForm.styled";
import { FormBox, InputDateBtn, InputNone, LabelText, LabelWrap } from 'components/FormNew/Form.styled';
import { DropdownItemBtn } from 'components/Dropdown/Dropdown2.styled';
import { Container } from 'components/Common/common.styled';
// ===== text translate START =====
const dataToTranslate = {
  // === sting ===
  textCarRental: "car_rental",
  textTruckRental: "truck_rental",
  textScooterRental: "scooter_rental",
  textCarsForSale: "cars_for_sale",
  textReturnDate: "return_date",
  textPickupDate: "pick_up_date",
  textPickupPoint: "pickup_point",
  textDropOffPoint: "drop_off_point",
  textChooseACarBrand: "choose_a_car_brand",
  textBtnShowCars: "show_cars",
  textDropOffLocationIsDifferent: "drop_off_location_is_different",
  // === word ===
  textTime: "time",
  textSelect: "select",
}
// ===== text translate END =====

const SearchVehiclesForm = () => {
  const [render, setRender] = useState(true);
  const [searchCategory, setSearchCategory] = useState('Car rental');
  const [showModal, setShowModal] = useState(false);
  const [sDate, setSDate] = useState('');
  const [sTime, setSTime] = useState("12:00");
  const [eDate, setEDate] = useState('');
  const [eTime, setETime] = useState("12:00");
  const [brandValue, setBrandValue] = useState(null);
  const [locationsToRender, setLocationsToRender] = useState(locationList || null);
  const [sLocation, setSLocation] = useState(locationList[0] || null);
  const [eLocation, setELocation] = useState(null);
  const [addELocation, setAddELocation] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentLang } = useLang();
  const locations = useSelector(selectLocationsList);
  const vehicles = useSelector(selectPublicRentalVehicles);
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const {
    // === sting ===
    textCarRental,
    textTruckRental,
    textScooterRental,
    textCarsForSale,
    textReturnDate,
    textPickupDate,
    textPickupPoint,
    textDropOffPoint,
    textBtnShowCars,
    textChooseACarBrand,
    textDropOffLocationIsDifferent,
    // === word ===
    textTime,
    textSelect,
  } = translate ?? {};
  // ===== text translate END =====

  let vehicleBrands = getUniqueValueFromArrayKeys1(isAvailableArray(vehicles)?.filter(i => !["Scooters", "Trucks"]?.includes(i?.category)), "brand")?.map(i => i?.brand)?.sort();
  if(compareStrings(searchCategory, 'Scooter rental')) vehicleBrands = getUniqueValueFromArrayKeys1(isAvailableArray(vehicles)?.filter(i => compareStrings(i?.category, "Scooters")), "brand")?.map(i => i?.brand)?.sort();
  if(compareStrings(searchCategory,'Truck rental')) vehicleBrands = getUniqueValueFromArrayKeys1(isAvailableArray(vehicles)?.filter(i => compareStrings(i?.category, "Trucks")), "brand")?.map(i => i?.brand)?.sort();
  const isCarForSale = compareStrings(searchCategory, "Car for sale");
  const isShowCarsBtnDisabled = isCarForSale ? false : !(sDate && eDate && sTime && eTime);
  const isLocationsListDisabled = isAvailableArray(locationsToRender)?.length < 2;

  // ===== dropdown START =====
  const dropdownDataVehicles = vehicleBrands?.map(i => ({
    itemData: i,
    itemCompareStringsData: i,
    component: <DropdownItemBtn type="button" onClick={() => setBrandValue(i)}>{i}</DropdownItemBtn>,
  }));
  const dropdownDataLocationsS = locationsToRender?.map(i => ({
    itemData: i,
    itemCompareStringsData: i,
    component: <DropdownItemBtn type="button" onClick={() => setSLocation(i)}>{i}</DropdownItemBtn>,
  }));
  const dropdownDataLocationsE = locationsToRender?.map(i => ({
    itemData: i,
    itemCompareStringsData: i,
    component: <DropdownItemBtn type="button" onClick={() => setELocation(i)}>{i}</DropdownItemBtn>,
  }));
  const dropdownDataTimeS = timeOptions?.map(i => ({
    itemData: i,
    itemCompareStringsData: i,
    component: <DropdownItemBtn type="button" onClick={() => setSTime(i)}>{i}</DropdownItemBtn>,
  }));
  const dropdownDataTimeE = timeOptions?.map(i => ({
    itemData: i,
    itemCompareStringsData: i,
    component: <DropdownItemBtn type="button" onClick={() => setETime(i)}>{i}</DropdownItemBtn>,
  }));
  // ===== dropdown END =====

  function handleSetDate (data) {
    setSDate(data?.startDay);
    setEDate(data?.endDay);
    setShowModal(false);
  }

  function handleSubmit (e) {
    e.preventDefault();

    let category = null;
    switch (searchCategory) {
      case "Car for sale":
        return navigate('/sale');
      case "Truck rental":
        category = "trucks";
        break;
      case "Scooter rental":
        category = "scooters";
        break;
    
      // ===== fake case =====
      case "fake case":
        console.log("eLocation", eLocation);
        break;
      // ===== fake case =====

      default:
        category = null;
        break;
    }

    const paramsObj = { sDate, eDate, sTime, eTime, category, brands: brandValue ? [brandValue?.replace("Š", "S")] : null };
    const serializedSearchParams = serializeSearchParams(paramsObj);
    const searchUrl = `/search?${generateSearchUrl(serializedSearchParams)}`;
    const rental = { sDate, eDate, sTime, eTime, sLocation, eLocation, }

    dispatch(getVehiclesBySearchQuery({sDate, eDate}));
    navigate(searchUrl, {state: {rental}});
  };

  useEffect(() => {
    if (render) return setRender(false);
    if (!locations) dispatch(getLocations());
  }, [render, dispatch, locations]);

  useEffect(() => { if (locations) setLocationsToRender(isAvailableArray(locations)?.map(i => i?.address)) }, [locations]);
  
  return (
    <SearchVehiclesSection>
      <Container>
        <SearchVehiclesFormTag id="searchForm" onSubmit={handleSubmit}>
          <FormBox>
            <LabelWrap className="min-768-items-2 min-1440-items-4">
              <InputNone value="Car rental" name="search_category" id="search_category_car_rental" type="radio" checked={searchCategory === "Car rental"} onChange={(e) => { setSearchCategory(e.target.value); setBrandValue(null); }} />
              <SearchVehiclesFormRadioLabel className="search-category car-rental bg-fff" htmlFor="search_category_car_rental"> {textCarRental} </SearchVehiclesFormRadioLabel>
            </LabelWrap>
            <LabelWrap className="min-768-items-2 min-1440-items-4">
              <InputNone value="Truck rental" name="search_category" id="search_category_truck_rental" type="radio" checked={searchCategory === "Truck rental"} onChange={(e) => { setSearchCategory(e.target.value); setBrandValue(null); }} />
              <SearchVehiclesFormRadioLabel className="search-category truck-rental bg-fff" htmlFor="search_category_truck_rental"> {textTruckRental} </SearchVehiclesFormRadioLabel>
            </LabelWrap>
            <LabelWrap className="min-768-items-2 min-1440-items-4">
              <InputNone value="Scooter rental" name="search_category" id="search_category_scooter_rental" type="radio" checked={searchCategory === "Scooter rental"} onChange={(e) => { setSearchCategory(e.target.value); setBrandValue(null); }} />
              <SearchVehiclesFormRadioLabel className="search-category scooter-rental bg-fff" htmlFor="search_category_scooter_rental"> {textScooterRental} </SearchVehiclesFormRadioLabel>
            </LabelWrap>
            <LabelWrap className="min-768-items-2 min-1440-items-4">
              <InputNone value="Car for sale" name="search_category" id="search_category_car_for_sale" type="radio" checked={searchCategory === "Car for sale"} onChange={(e) => { setSearchCategory(e.target.value); setBrandValue(null); }} />
              <SearchVehiclesFormRadioLabel className="search-category car-for-sale bg-fff" htmlFor="search_category_car_for_sale">{textCarsForSale}</SearchVehiclesFormRadioLabel>
            </LabelWrap>
          </FormBox>

          <SearchVehiclesFormMainBox className="min-1440-items-4 top-21">
            <Dropdown2 dropdownData={dropdownDataVehicles} activeItem={brandValue} className={"bg-fff searchVehicle min-768-items-2 min-1440-items-1"} placeholder={textChooseACarBrand} disabled={isCarForSale} />
            <Dropdown2 dropdownData={dropdownDataLocationsS} activeItem={sLocation} className={"bg-fff searchVehicle min-768-items-2 min-1440-items-1"} placeholder={textPickupPoint} disabled={isLocationsListDisabled} />
          </SearchVehiclesFormMainBox>

          <SearchVehiclesFormMainBox className="min-1440-items-4_3">
            <InputNone name="addsLocationReturn" type="checkbox" id={`addsLocationReturn`} onChange={() => setAddELocation(!addELocation)} checked={addELocation} />
            <SearchVehiclesFormCheckboxLabel style={{alignItems: 'center'}} className={addELocation ? "min-768-items-2 min-1440-items-4 bg-fff" : "min-1440-items-4 bg-fff"} htmlFor={`addsLocationReturn`}>{textDropOffLocationIsDifferent}</SearchVehiclesFormCheckboxLabel>
            
            {addELocation && <Dropdown2 dropdownData={dropdownDataLocationsE} activeItem={eLocation} className={"searchVehicle min-768-items-2 min-1440-items-4 order-5 locations"} placeholder={textDropOffPoint} disabled={isLocationsListDisabled} /> }

            <LabelWrap className="min-425-items-2 min-1440-items-4">
              <LabelText> {textPickupDate}: </LabelText>
              <InputDateBtn className="pick-up-date date bg-fff" type='button' onClick={setShowModal} disabled={isCarForSale}>{sDate ? getLocalDate(sDate) : textSelect}</InputDateBtn>
            </LabelWrap>

            <LabelWrap className="min-425-items-2 min-1440-items-4">
              <LabelText> {textTime}: </LabelText>
              <Dropdown2 dropdownData={dropdownDataTimeS} activeItem={sTime} placeholder={textSelect} className={"bg-fff time icon"} disabled={isCarForSale} />
            </LabelWrap>

            <LabelWrap className="min-425-items-2 min-1440-items-4">
              <LabelText> {textReturnDate}: </LabelText>
              <InputDateBtn className="return-date date bg-fff" type='button' onClick={setShowModal} disabled={isCarForSale}>{eDate ? getLocalDate(eDate) : textSelect}</InputDateBtn>
            </LabelWrap>

            <LabelWrap className="min-425-items-2 min-1440-items-4">
              <LabelText> {textTime}: </LabelText>
              <Dropdown2 dropdownData={dropdownDataTimeE} activeItem={eTime} placeholder={textSelect} className={"bg-fff time icon"} disabled={isCarForSale} />
            </LabelWrap>

            <SearchVehiclesFormBtn className="min-1440-items-2" type="submit" form="searchForm" disabled={isShowCarsBtnDisabled}>{textBtnShowCars}</SearchVehiclesFormBtn>
          </SearchVehiclesFormMainBox>
        </SearchVehiclesFormTag>
        
        <Modal showModal={showModal} setShowModal={() => setShowModal(false)}>
          <Datepicker handleSetDate={handleSetDate} handleCancel={() => setShowModal(false)}/> 
        </Modal>
      </Container>
    </SearchVehiclesSection>
  );
};

export default SearchVehiclesForm;
