import styled from "styled-components";

export const LogoutBtnTag = styled.button`
  flex-shrink: 0;

  display: flex;
  align-items: center;
  height: 48px;
  gap: 8px;
  padding: 0 12px;
  margin: auto 0 16px;

  color: var(--color--primary, #414141);
  font-size: 20px;

  border: none;
  transition: var(--transition--100);

  & svg { 
    flex: 0 0 20px;
    width: 20px;
    height: 20px;

    fill: currentColor; 
  }

  aside.active & {
    font-size: 16px;
  }

  &.active,
  &:hover {
    color: var(--color--bg, #f1f1f1);
    background-color: var(--color--accent, #f19e1d);
  }
`;
