export default function getDataFromDate(dateString) {
  let date = new Date(dateString);
  if (!dateString) date = new Date();

  const day = date?.getDate();
  const month = date?.getMonth() + 1;
  const year = date?.getFullYear();
  const ISOString = date?.toISOString();

  const res = {
    day,
    month,
    year,
    ISOString,
  };

  return res;
}

// old_date = "Fri Jan 08 2021 16:01:30 GMT+0900"
// const new_date = old_date.toISOString().substring(0, 10);
// new_date = "2021-01-08"
