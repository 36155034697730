import { getCurrentLangPrice } from "helpers/data";

export default function getRentPrices(rentPrice, rentPriceByCases, days = 0, insurance = null, additionalServices = null, deliveryServices = null) {
  const rentPriceEUR = getCurrentLangPrice(rentPrice, 'en');
  const rentPriceCZK = getCurrentLangPrice(rentPrice, 'cz');

  const rentPriceByCasesEUR = {
    case1: rentPriceEUR,
    case2: getCurrentLangPrice(rentPriceByCases?.case_2, 'en'),
    case3: getCurrentLangPrice(rentPriceByCases?.case_3, 'en'),
    case4: getCurrentLangPrice(rentPriceByCases?.case_4, 'en'),
    case5: getCurrentLangPrice(rentPriceByCases?.case_5, 'en'),
    case6: getCurrentLangPrice(rentPriceByCases?.case_6, 'en'),
  }
  const rentPriceByCasesCZK = {
    case1: rentPriceCZK,
    case2: getCurrentLangPrice(rentPriceByCases?.case_2, 'cz'),
    case3: getCurrentLangPrice(rentPriceByCases?.case_3, 'cz'),
    case4: getCurrentLangPrice(rentPriceByCases?.case_4, 'cz'),
    case5: getCurrentLangPrice(rentPriceByCases?.case_5, 'cz'),
    case6: getCurrentLangPrice(rentPriceByCases?.case_6, 'cz'),
  }

  let rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case1;
  if (days > 2 && days <= 7) rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case2;
  if (days > 7 && days <= 14) rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case3;
  if (days > 14 && days <= 21) rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case4;
  if (days > 21 && days <= 30) rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case5;
  if (days > 30) rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case6;

  let rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case1;
  if (days > 2 && days <= 7) rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case2;
  if (days > 7 && days <= 14) rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case3;
  if (days > 14 && days <= 21) rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case4;
  if (days > 21 && days <= 30) rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case5;
  if (days > 30) rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case6;

  const totalPricePerDayEUR = rentPriceWithDiscountEUR + getCurrentLangPrice(insurance?.price, "en") + getCurrentLangPrice(additionalServices?.price, "en");
  const totalPricePerDayCZK = rentPriceWithDiscountCZK + getCurrentLangPrice(insurance?.price, "cz") + getCurrentLangPrice(additionalServices?.price, "cz");

  const totalPriceEUR = days * (rentPriceWithDiscountEUR + getCurrentLangPrice(insurance?.price, "en") + getCurrentLangPrice(additionalServices?.price, "en")) + getCurrentLangPrice(deliveryServices?.price, "en");
  const totalPriceCZK = days * (rentPriceWithDiscountCZK + getCurrentLangPrice(insurance?.price, "cz") + getCurrentLangPrice(additionalServices?.price, "cz")) + getCurrentLangPrice(deliveryServices?.price, "cz");
  
  const dataToReturn = {
    rentPrice: {
      eur: rentPriceEUR,
      czk: rentPriceCZK,
    },
    rentPriceByCases: {
      en: rentPriceByCasesEUR,
      cz: rentPriceByCasesCZK,
    },
    rentPriceWithDiscount: {
      eur: rentPriceWithDiscountEUR,
      czk: rentPriceWithDiscountCZK,
    },
    days,
    insurance,
    deliveryServices,
    additionalServices,
    totalPricePerDay: {
      eur: totalPricePerDayEUR,
      czk: totalPricePerDayCZK,
    },
    totalPrice: {
      eur: totalPriceEUR,
      czk: totalPriceCZK,
    },
  }

  return dataToReturn
}


  // const rentPriceByCasesEUR = {
  //   case1: rentPriceEUR,
  //   case2: getNumberToFixed((rentPriceEUR * (100 - 45) / 100), 2),
  //   case3: getNumberToFixed((rentPriceEUR * (100 - 50) / 100), 2),
  //   case4: getNumberToFixed((rentPriceEUR * (100 - 55) / 100), 2),
  //   case5: getNumberToFixed((rentPriceEUR * (100 - 60) / 100), 2),
  //   case6: getNumberToFixed((rentPriceEUR * (100 - 60) / 100), 2),
  // }
  // const rentPriceByCasesCZK = {
  //   case1: rentPriceCZK,
  //   case2: getNumberToFixed((rentPriceCZK * (100 - 45) / 100), 2),
  //   case3: getNumberToFixed((rentPriceCZK * (100 - 50) / 100), 2),
  //   case4: getNumberToFixed((rentPriceCZK * (100 - 55) / 100), 2),
  //   case5: getNumberToFixed((rentPriceCZK * (100 - 60) / 100), 2),
  //   case6: getNumberToFixed((rentPriceCZK * (100 - 60) / 100), 2),
  // }
  // const rentPriceByCasesEUR = {
  //   case1: rentPriceEUR,
  //   case2: Math.round(rentPriceEUR * (100 - 45) / 100),
  //   case3: Math.round(rentPriceEUR * (100 - 50) / 100),
  //   case4: Math.round(rentPriceEUR * (100 - 55) / 100),
  //   case5: Math.round(rentPriceEUR * (100 - 60) / 100),
  //   case6: Math.round(rentPriceEUR * (100 - 60) / 100),
  // }
  // const rentPriceByCasesCZK = {
  //   case1: rentPriceCZK,
  //   case2: Math.round(rentPriceCZK * (100 - 45) / 100),
  //   case3: Math.round(rentPriceCZK * (100 - 50) / 100),
  //   case4: Math.round(rentPriceCZK * (100 - 55) / 100),
  //   case5: Math.round(rentPriceCZK * (100 - 60) / 100),
  //   case6: Math.round(rentPriceCZK * (100 - 60) / 100),
  // }

  // let rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case1;
  // if (days > 2 && days <= 7) rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case2;
  // if (days > 7 && days <= 14) rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case3;
  // if (days > 14 && days <= 21) rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case4;
  // if (days > 21 && days <= 30) rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case5;
  // if (days > 30) rentPriceWithDiscountEUR = rentPriceByCasesEUR?.case6;

  // let rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case1;
  // if (days > 2 && days <= 7) rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case2;
  // if (days > 7 && days <= 14) rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case3;
  // if (days > 14 && days <= 21) rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case4;
  // if (days > 21 && days <= 30) rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case5;
  // if (days > 30) rentPriceWithDiscountCZK = rentPriceByCasesCZK?.case6;
