export default function getDeepObjectValue(obj, keyString) {
  const res = keyString?.split(".").reduce((o, k) => o?.[k], obj);
  return res;
}

// ==== var 1 ====
// const exampleObj = {
//   hello: {
//     there: {
//       friend: 'my friend!',
//       neighbor: 'neighbor!',
//       world: 'world!'
//     }
//   }
// };

// const getVal = (ob, s) => s.split('.').reduce((o, k) => o?.[k], ob);

// console.log( getVal(exampleObj, "hello.there.world") ); // "world!"
// console.log( getVal(exampleObj, "hello.stranger") );    // undefined

// ==== var 2 ====
// const exampleObj = {
//   hello: {
//     there: {
//       friend: 'my friend!',
//       neighbor: 'neighbor!',
//       world: 'world!'
//     }
//   }
// };

// const getVal = (ob, ...k) => k.reduce((o, k) => o?.[k], ob);

// console.log( getVal(exampleObj, "hello", "there", "world") ); // "world!"
// console.log( getVal(exampleObj, "hello", "stranger") );       // undefined
