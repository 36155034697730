const getTimeBetweenDates = (startDate, endDate) => {
  const startD = new Date(startDate).getTime();
  const endD = new Date(endDate).getTime();
  const millisecondsDuration = Math.abs(startD - endD);
  const deltaInSeconds = Math.abs(startD - endD) / 1000;

  const days = Math.floor(deltaInSeconds / 86400);
  const deltaMinusDaysInSeconds = deltaInSeconds - days * 86400;

  const hours = Math.floor(deltaMinusDaysInSeconds / 3600) % 24;
  const deltaMinusHoursInSeconds = deltaMinusDaysInSeconds - hours * 3600;

  const minutes = Math.floor(deltaMinusHoursInSeconds / 60) % 60;

  return { days, hours, minutes, milliseconds: millisecondsDuration };
};

export default getTimeBetweenDates;

export const getTimeBetweenDatesTimes = (sDate, eDate, sTime = "12:00", eTime = "12:00") => {
  const startD = new Date(`${sDate}T${sTime}`)
  const endD = new Date(`${eDate}T${eTime}`)

  const millisecondsDuration = Math.abs(startD - endD);
  const deltaInSeconds = Math.abs(startD - endD) / 1000;

  const days = Math.floor(deltaInSeconds / 86400);
  const deltaMinusDaysInSeconds = deltaInSeconds - days * 86400;
  const daysRounded = Math.ceil(deltaInSeconds / 86400);

  const hours = Math.floor(deltaMinusDaysInSeconds / 3600) % 24;
  const deltaMinusHoursInSeconds = deltaMinusDaysInSeconds - hours * 3600;

  const minutes = Math.floor(deltaMinusHoursInSeconds / 60) % 60;
  const res = { days, hours, minutes, milliseconds: millisecondsDuration, daysRounded }

  return res;
};
