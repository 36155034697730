import React from "react";
// components
import SvgUse from "components/SvgUse/SvgUse";
// styled
import { ContactsSnBox, ContactsSnItem, ContactsSnLink, ContactsSnList, ContactsSnTitle, } from "./ContactsSn.styled";

const ContactsSn = ({ className }) => {
  const contactsSnData = [
    {
      title: "Contact us on WhatsApp",
      ariaLabel: "Contact us on WhatsApp",
      icontag: "whatsapp",
      toUrl: "https://wa.me/420775177777",
      target: "_blank",
    },
    {
      title: "Contact us on Instagram",
      ariaLabel: "Contact us on Instagram",
      icontag: "instagram",
      toUrl: "https://www.instagram.com/aluf.car/",
      target: "_blank",
    },
    {
      title: "Contact us on Viber",
      ariaLabel: "Contact us on Viber",
      icontag: "viber",
      toUrl: "viber://chat?number=+420775177777",
      target: "_blank",
    },
    {
      title: "Follow us on Facebook",
      ariaLabel: "Follow us on Facebook",
      icontag: "facebook",
      toUrl: "https://www.facebook.com/profile.php?id=61557490143376",
      target: "_blank",
    },
    {
      title: "Follow us on Linkedin",
      ariaLabel: "Follow us on Linkedin",
      icontag: "linkedin",
      toUrl: "#",
      target: "_blank",
    },
  ];

  return (
    <ContactsSnBox className={className}>
      <ContactsSnList>
        {contactsSnData?.map((i, idx) => {
          return (
            <ContactsSnItem key={idx}>
              <ContactsSnLink to={i?.toUrl} target={i?.target} aria-label={i?.ariaLabel} >
                <SvgUse icontag={i?.icontag} />
                <ContactsSnTitle>{i?.title}</ContactsSnTitle>
              </ContactsSnLink>
            </ContactsSnItem>
          );
        })}
      </ContactsSnList>
    </ContactsSnBox>
  );
};
// TODO TEXT
// TODO change svg for mobile menu

export default ContactsSn;
