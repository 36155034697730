import React, { useEffect, useState } from "react";
// hooks
import { useDate, useLang, useWindowDimensions } from "hooks";
// helpers
import { compareStrings, getCurrentLangObject, getDateFromDateTimeLocal, getDatesBetweenDates, getDaysInMonth, isAvailableArray } from "helpers";
// common
import { weekdaysListData } from "common/constants";
// styled
import { DatepickerBoxWrap, DatepickerBox, DatepickerHeader, DatepickerMain, DatepickerFooter, DatepickerBtnMonthCurrent, DatepickerBtnMonthNext, DatepickerBtnMonthPrev, DatepickerWeekdaysList, DatepickerWeekdaysItem, DatepickerDaysList, DatepickerDaysItem, DatepickerDaysItemBtn, DatepickerBtnSet, DatepickerBtnCancel, } from "./Datepicker.styled";

const Datepicker = ({ handleSetDate, handleCancel, startDayInit, endDayInit, isStartDayBtnDisabled, isEndDayBtnDisabled, oneDate, min, max }) => {
  const { currentLang } = useLang();
  const { width } = useWindowDimensions();
  const { today, todayMonthIndex, todayYear } = useDate();

  let nextMonthIndex = todayMonthIndex + 1;
  let nextMonthIndexYear = todayYear;
  if (todayMonthIndex === 11) {
    nextMonthIndex = 0;
    nextMonthIndexYear = todayYear + 1;
  }

  const [monthInView, setMonthInView] = useState(1);
  const [currentYear, setCurrentYear] = useState(todayYear);
  const [currentMonth, setCurrentMonth] = useState(todayMonthIndex + 1);
  const [currentMonthDatesList, setCurrentMonthDatesList] = useState(getDaysInMonth(currentMonth , currentYear));
  const [prevMonthEmptyDatesList, setPrevMonthEmptyDatesList] = useState([]);
  
  const [nextMonthYear, setNextMonthYear] = useState(nextMonthIndexYear);
  const [nextMonth, setNextMonth] = useState(nextMonthIndex + 1);
  const [nextMonthDatesList, setNextMonthDatesList] = useState(getDaysInMonth(nextMonth, nextMonthYear));
  const [currentMonthEmptyDatesList, setCurrentMonthEmptyDatesList] = useState([]);

  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [dates, setDates] = useState([]);

  const currentMonthBtnTitle = new Date(`${currentYear}, ${currentMonth}`)?.toLocaleString("en-US", { month: "long", year: "numeric", });
  const nextMonthBtnTitle = new Date(`${nextMonthYear}, ${nextMonth}`)?.toLocaleString("en-US", { month: "long", year: "numeric", });
  const isBtnMonthPrevDisabled = currentMonth === todayMonthIndex + 1 && currentYear === todayYear;
  const isSetBtnDisabled = compareStrings(startDay, startDayInit) && compareStrings(endDay, endDayInit);

  const handleChangeMonth = (btnName) => {
    let step = 1;

    switch (btnName) {
      case "prev":
        setCurrentMonth((prev) => {
          if (prev === 1) {
            setCurrentYear((prev) => prev - step)
            return 12
          }
          return prev - step
        })

        setNextMonth((prev) => {
          if (prev === 1) {
            setNextMonthYear((prev) => prev - step)
            return 12
          }
          return prev - step
        })


        break;
    
      default:
        setCurrentMonth((prev) => {
          if (prev === 12) {
            setCurrentYear((prev) => prev + step)
            return 1
          }
          return prev + step
        })

        setNextMonth((prev) => {
          if (prev === 12) {
            setNextMonthYear((prev) => prev + step)
            return 1
          }
          return prev + step
        })
        break;
    }
  }

  const handleSelectDay = (e, selectDate) => {
    if (oneDate) handleSetDate({startDay: selectDate})

    if (!startDay && !endDay) {
      e.target.classList.add('active');
      setStartDay(selectDate);
    }
    if (startDay && !endDay) {
      if (selectDate === startDay) {
        e.target.classList.remove('active');
        setStartDay(null)
      } 
      if (selectDate > startDay) {
        e.target.classList.add('active');
        setEndDay(selectDate);
      }
      if (selectDate < startDay) {
        e.target.classList.add('active');
        setStartDay(selectDate);
        setEndDay(startDay);
      }
    }
    if (startDay && endDay) {
      if (selectDate === endDay) {
        e.target.classList.remove('active');
        setEndDay(null)
      }
      if (selectDate === startDay) {
        e.target.classList.remove('active');
        setStartDay(null)
      }
      else return
    }
    if (!startDay && endDay) {
      if (selectDate === endDay) {
        e.target.classList.remove('active');
        setEndDay(null)
      }
      if (selectDate > endDay) {
        e.target.classList.add('active');
        setStartDay(endDay);
        setEndDay(selectDate);
      }
      if (selectDate < endDay) {
        e.target.classList.add('active');
        setStartDay(selectDate);
      }
    }
  }

  useEffect(() => {
    const currentMonthDates = getDaysInMonth(currentMonth, currentYear);
    setCurrentMonthDatesList(currentMonthDates);
    setPrevMonthEmptyDatesList(getPrevMonthEmptyDatesList(currentMonthDates[0]?.getDay()));
  }, [currentMonth, currentYear]);

  useEffect(() => {
    if (startDayInit && endDayInit) {
      setStartDay(startDayInit);
      setEndDay(endDayInit);
      setDates(getDatesBetweenDates(startDayInit, endDayInit)?.map(i => getDateFromDateTimeLocal(i)));
    }
  }, [startDayInit, endDayInit]);

  useEffect(() => {
    if (startDay && endDay) setDates(getDatesBetweenDates(startDay, endDay)?.map(i => getDateFromDateTimeLocal(i)));
    else setDates([]);
  }, [startDay, endDay]);

  useEffect(() => {
    if (monthInView < 2) return
    const nextMonthDates = getDaysInMonth(nextMonth, nextMonthYear);
    setNextMonthDatesList(nextMonthDates);
    setCurrentMonthEmptyDatesList(getPrevMonthEmptyDatesList(nextMonthDates[0]?.getDay()));
  }, [monthInView, nextMonth, nextMonthYear]);

  useEffect(() => {
    // if (monthInView > 1) return
    if (width < 768) setMonthInView(1);
    else setMonthInView(2);
  }, [width, monthInView])

  return (
    <DatepickerBoxWrap>
      <DatepickerBox>
        <DatepickerHeader>
          <DatepickerBtnMonthPrev name="prev" aria-label="Next month" type="button" onClick={() => handleChangeMonth('prev')} disabled={isBtnMonthPrevDisabled}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M10 12L6 8L10 4" stroke="#414141" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          </DatepickerBtnMonthPrev>
          <DatepickerBtnMonthCurrent type="button">{currentMonthBtnTitle}</DatepickerBtnMonthCurrent>
          {monthInView === 1 && 
            <DatepickerBtnMonthNext name="next" aria-label="Next month" type="button" onClick={() => handleChangeMonth('next')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M10 12L6 8L10 4" stroke="#414141" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </DatepickerBtnMonthNext>
          }
        </DatepickerHeader>
        <DatepickerMain>
          <DatepickerWeekdaysList>
            {weekdaysListData?.map((i, idx) => (<DatepickerWeekdaysItem key={idx}>{getCurrentLangObject(i, currentLang)}</DatepickerWeekdaysItem>))}
          </DatepickerWeekdaysList>
          <DatepickerDaysList>
            {prevMonthEmptyDatesList?.map((i, idx) => (<DatepickerDaysItem key={idx}></DatepickerDaysItem>))}
            {currentMonthDatesList?.map((i, idx) => {
              const isStartDay = getDateFromDateTimeLocal(i) === startDay;
              const isEndDay = getDateFromDateTimeLocal(i) === endDay;
              const isPastDay = today > i;
              let isMinMax = true;
              if (min) isMinMax = min <= i;
              if (max) isMinMax = i <= max;
              if (min && max) isMinMax = min <= i && i <= max;
              const isBtnDisabled = !isMinMax || isPastDay || (isStartDayBtnDisabled && isStartDay) || (isEndDayBtnDisabled && isEndDay);
              const isActiveLight = isAvailableArray(dates)?.includes(getDateFromDateTimeLocal(i)) ? "active-light" : "";
              const isActive = (isStartDay || isEndDay) ? 'active' : '';

              return (
                <DatepickerDaysItem key={idx}>
                  <DatepickerDaysItemBtn className={`${isActiveLight} ${isActive}`} disabled={isBtnDisabled} type="button" onClick={(e) => handleSelectDay(e, getDateFromDateTimeLocal(i))} >{i.getDate()}</DatepickerDaysItemBtn>
                </DatepickerDaysItem>
              )
            })}
          </DatepickerDaysList>
        </DatepickerMain>
        {monthInView === 1 && 
          <DatepickerFooter>
            <DatepickerBtnCancel type="button" onClick={handleCancel}>Cancel</DatepickerBtnCancel>
            <DatepickerBtnSet type="button" onClick={() => handleSetDate({startDay, endDay})} disabled={!(startDay && endDay)}> 
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.7703 5.26275C17.0876 5.60123 17.0746 6.13682 16.7412 6.45903L7.11161 15.7667C6.94716 15.9257 6.72597 16.0096 6.49916 15.9991C6.27235 15.9886 6.05958 15.8847 5.91003 15.7112L2.20633 11.4153C1.90318 11.0637 1.93815 10.5291 2.28444 10.2213C2.63073 9.91351 3.15721 9.94902 3.46036 10.3006L6.59177 13.9327L15.5921 5.23328C15.9255 4.91108 16.4529 4.92427 16.7703 5.26275Z" />
              </svg>
              Set date 
            </DatepickerBtnSet>
          </DatepickerFooter>
        }
      </DatepickerBox>
  
      {monthInView === 2 && 
        <DatepickerBox>
          <DatepickerHeader>
            {monthInView === 1 && 
              <DatepickerBtnMonthPrev type="button" name="prev" aria-label="Prev month" onClick={() => handleChangeMonth('prev')} disabled={isBtnMonthPrevDisabled}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M10 12L6 8L10 4" stroke="#414141" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </DatepickerBtnMonthPrev>
            }
            <DatepickerBtnMonthCurrent type="button">{nextMonthBtnTitle}</DatepickerBtnMonthCurrent>
            <DatepickerBtnMonthNext type="button" name="next" aria-label="Next month" onClick={() => handleChangeMonth('next')}> 
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M10 12L6 8L10 4" stroke="#414141" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </DatepickerBtnMonthNext>
          </DatepickerHeader>
          <DatepickerMain>
            <DatepickerWeekdaysList>
              {weekdaysListData?.map((i, idx) => (<DatepickerWeekdaysItem key={idx}>{getCurrentLangObject(i, currentLang)}</DatepickerWeekdaysItem>))}
            </DatepickerWeekdaysList>
            <DatepickerDaysList>
              {currentMonthEmptyDatesList?.map((i, idx) => (<DatepickerDaysItem key={idx}></DatepickerDaysItem>))}
              {nextMonthDatesList?.map((i, idx) => {
                const isStartDay = getDateFromDateTimeLocal(i) === startDay;
                const isEndDay = getDateFromDateTimeLocal(i) === endDay;
                const isPastDay = today > i;
                let isMinMax = true;
                if (min) isMinMax = min <= i;
                if (max) isMinMax = i <= max;
                if (min && max) isMinMax = min <= i && i <= max;

                const isBtnDisabled = !isMinMax || isPastDay || (isStartDayBtnDisabled && isStartDay) || (isEndDayBtnDisabled && isEndDay);
                const isActiveLight = isAvailableArray(dates)?.includes(getDateFromDateTimeLocal(i)) ? "active-light" : "";
                const isActive = (isStartDay || isEndDay)  ? 'active' : '';

                return (
                  <DatepickerDaysItem key={idx}>
                    <DatepickerDaysItemBtn className={`${isActiveLight} ${isActive}`} disabled={isBtnDisabled} type="button" onClick={(e) => handleSelectDay(e, getDateFromDateTimeLocal(i))}>{i.getDate()}</DatepickerDaysItemBtn>
                  </DatepickerDaysItem>
                )
              })}
            </DatepickerDaysList>
          </DatepickerMain>
          <DatepickerFooter>
            <DatepickerBtnCancel type="button" onClick={handleCancel}>Cancel</DatepickerBtnCancel>
            <DatepickerBtnSet type="button" onClick={() => handleSetDate({startDay, endDay})} disabled={!(startDay && endDay) || isSetBtnDisabled}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.7703 5.26275C17.0876 5.60123 17.0746 6.13682 16.7412 6.45903L7.11161 15.7667C6.94716 15.9257 6.72597 16.0096 6.49916 15.9991C6.27235 15.9886 6.05958 15.8847 5.91003 15.7112L2.20633 11.4153C1.90318 11.0637 1.93815 10.5291 2.28444 10.2213C2.63073 9.91351 3.15721 9.94902 3.46036 10.3006L6.59177 13.9327L15.5921 5.23328C15.9255 4.91108 16.4529 4.92427 16.7703 5.26275Z" />
              </svg>
              Set date 
            </DatepickerBtnSet>
          </DatepickerFooter>
        </DatepickerBox>
      }
    </DatepickerBoxWrap>
  );
};

export default Datepicker;

function getPrevMonthEmptyDatesList(dayIndex = 0) {
  if (dayIndex === 0) return new Array(6).fill("");

  return new Array(dayIndex - 1)?.fill("");
}