import React from "react";
// hooks
import useAuth from "hooks/useAuth";
// components
import OfficeNavAdmin from "./OfficeNavAdmin";
import OfficeNavSuperadmin from "./OfficeNavSuperadmin";
import OfficeNavSalesManager from "./OfficeNavSalesManager";
import OfficeNavRentalManager from "./OfficeNavRentalManager";

const OfficeNav = ({ setIsBurgerMenuOpen, isSmallView }) => {
  const { isSuperAdmin, isAdmin, isRentalManager, isSalesManager } = useAuth();

  return (
    <>
      {isAdmin && ( <OfficeNavAdmin setIsBurgerMenuOpen={setIsBurgerMenuOpen} isSmallView={isSmallView} /> )}
      {isSuperAdmin && ( <OfficeNavSuperadmin setIsBurgerMenuOpen={setIsBurgerMenuOpen} isSmallView={isSmallView} /> )}
      {isSalesManager && ( <OfficeNavSalesManager setIsBurgerMenuOpen={setIsBurgerMenuOpen} isSmallView={isSmallView} /> )}
      {isRentalManager && ( <OfficeNavRentalManager setIsBurgerMenuOpen={setIsBurgerMenuOpen} isSmallView={isSmallView} /> )}
    </>
  );
};

export default OfficeNav;
