import styled from "styled-components";
// common
import { maxDevice, minDevice } from "common/breakpoints";
import { CardSet } from "components/Common/Placeholders";

export const ChooseYourTravelSection = styled.section`
  width: 100%;
  padding: 60px 0 80px;

  @media ${minDevice.tablet} {
    padding: 28px 0 80px;
  }
  @media ${minDevice.desktopXL} {
    padding: 20px 0 132px;
  }
`;

export const ChooseYourTravelBoxWrap = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${minDevice.tablet} {
    flex-direction: row;
    gap: 32px;
  }
  @media ${minDevice.desktopXL} {
    gap: 60px;
  }
`;
export const ChooseYourTravelBox1 = styled(CardSet)`
  align-content: flex-end;
  --gap: 8px;

  @media ${minDevice.desktopXL} {
    max-width: none;
  }
`;
export const ChooseYourTravelBox2 = styled(CardSet)`
  align-content: flex-end;

  --gap: 16px;
  row-gap: 8px;

  // @media ${minDevice.desktopL} {
  //   max-width: 600px;
  // }
  @media ${minDevice.desktopXL} {
    max-width: none;
  }
`;

export const ChooseYourTravelTitle = styled.h1`
  display: flex;
  flex-direction: column;

  color: var(--color--dark, #1e1e1e);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Helvetica Now Display";
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
  text-transform: uppercase;

  @media ${minDevice.tablet} {
    font-size: 36px;
    line-height: 36px;
  }
  @media ${minDevice.desktopL} {
    font-size: 48px;
    line-height: 60px;
  }
  @media ${minDevice.desktopXL} {
    font-size: 68px;
    line-height: 80px;
  }

  & span {
    color: var(--color--accent, #f19e1d);
  }
`;

export const ChooseYourTravelImg = styled.img`
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  height: auto;

  object-fit: cover;
  object-position: center;

  &.img-1 {
    --items: 1;

    object-position: top 75% left 50%;
    border-radius: 36px;
  }
  &.img-2,
  &.img-3 {
    --items: 2;

    border-radius: 26px;
  }
  &.img-4 {
    --items: 1;

    object-position: top 75% left 50%;
    border-radius: 46px;
  }
  &.img-5,
  &.img-6,
  &.img-7 {
    --items: 3;

    border-radius: 26px;
  }

  @media ${maxDevice.tablet} {
    &.img-1 {
      aspect-ratio: 288 / 76;
    }
    &.img-2,
    &.img-3 {
      aspect-ratio: 140 / 38;
    }
    &.img-4 {
      aspect-ratio: 288 / 180;
    }
    &.img-5,
    &.img-6,
    &.img-7 {
      aspect-ratio: 86 / 62;
    }
  }

  @media ${minDevice.tablet} {
    &.img-1 {
      width: 335px;
      height: 89px;
    }
    &.img-2,
    &.img-3 {
      width: 163px;
      height: 45px;
    }
    &.img-4 {
      width: 321px;
      height: 229px;
    }
    &.img-5,
    &.img-6,
    &.img-7 {
      width: 96px;
      height: 80px;
    }
  }
  @media ${minDevice.desktopL} {
    aspect-ratio: initial;

    &.img-1 {
      width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
      aspect-ratio: initial;
      height: 240px;
    }
    &.img-2,
    &.img-3 {
      width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
      height: 160px;
      aspect-ratio: initial;
    }
    &.img-4 {
      width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
      height: 395px;
      aspect-ratio: initial;
    }
    &.img-5,
    &.img-6,
    &.img-7 {
      width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
      aspect-ratio: initial;
      height: 100px;
    }
  }
  @media ${minDevice.desktopXL} {
    &.img-1 {
      width: 710px;
      height: 188px;
      aspect-ratio: initial;
    }
    &.img-2,
    &.img-3 {
      width: 345px;
      height: 95px;
      aspect-ratio: initial;
    }
    &.img-4 {
      width: 669px;
      height: 395px;
      aspect-ratio: initial;
    }
    &.img-5,
    &.img-6,
    &.img-7 {
      width: 200px;
      height: 138px;
      aspect-ratio: initial;
    }
  }
`;