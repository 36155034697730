import React from "react";
// hooks
import { useLang } from "hooks";
// helpers
import { getTranslate } from "helpers";
// components
import SocialNetworks from "components/SocialNetworks/SocialNetworks";
// styled
import { NavItem, NavItemLink, NavList, NavTag } from "./Nav.styled";
const dataToTranslate = {
  // === word ===
  textHome: "home",
  textCarRental: "car_rental",
  textScooterRental: "scooter_rental",
  textCarsForSale: "cars_for_sale",
  textAboutUs: "about_us",
  textFaq: "faq",
  textContacts: "contacts",
}
// ===== text translate END =====

const Nav = ({ className, setIsBurgerMenuOpen }) => {
  const { currentLang } = useLang();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const {
    // === word ===
    textHome,
    textCarRental,
    textScooterRental,
    textCarsForSale,
    textAboutUs,
    textFaq,
    textContacts,
  } = translate ?? {};
  // ===== text translate END =====

  const navLinkDataList = [
    {
      name: textHome || "Home",
      url: "/",
      end: true,
    },
    {
      name: textCarRental || "Car rental",
      url: "/search",
      end: true,
      className: "car_rental"
    },
    {
      name: textScooterRental || "Scooter rental",
      url: "/search?category=scooters",
      end: true,
      className: "scooter_rental"
    },
    {
      name: textCarsForSale || "Cars for sale",
      url: "/sale",
      className: "sale",
    },
    {
      name: textAboutUs || "About us",
      url: "/about-us",
      end: true,
    },
    {
      name: textFaq || "FAQ",
      url: "/faq",
      className: "faq",
      end: true,
    },
    {
      name: textContacts || "Contacts",
      url: "/contacts",
      end: true,
    },
  ];

  const handleClick = () => {
    if (setIsBurgerMenuOpen) return setIsBurgerMenuOpen(false);
  };

  return (
    <NavTag className={className}>
      <NavList>
        {navLinkDataList?.map((i, idx) => {
          const itemName = i?.name;
          const itemUrl = i?.url;
          const itemClassName = i?.className || "";
          const itemEnd = i?.end;

          return (
            <NavItem key={idx} className={itemClassName}>
              <NavItemLink className={itemClassName} onClick={() => handleClick()} to={itemUrl} end={itemEnd} > {itemName} </NavItemLink>
            </NavItem>
          );
        })}
        {className === "header" && ( <NavItem> <SocialNetworks className={className} /> </NavItem> )}
      </NavList>
    </NavTag>
  );
};

export default Nav;
