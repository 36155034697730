import { createSlice } from "@reduxjs/toolkit";
import { createUser, getUsers, updateUserById, deleteUserById, } from "./operations";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const initialState = {};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUsersState() { return initialState; },
    resetUsersStatus(state) { state.status = ""; },
    resetUsersError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createUser.pending, (state, action) => { handlePending(state); })
      .addCase(createUser.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(createUser.fulfilled, (state, action) => { handleFulfilled(state, "added");
        state.list = state.list?.concat([action.payload]);
      })
      .addCase(getUsers.pending, (state, action) => { handlePending(state); })
      .addCase(getUsers.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getUsers.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(updateUserById.pending, (state, action) => { handlePending(state); })
      .addCase(updateUserById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateUserById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        state.list = [...state.list].map((i) => {
          if (action.payload?.user_id === i?.user_id) return action.payload;
          return i;
        });
      })
      .addCase(deleteUserById.pending, (state, action) => { handlePending(state); })
      .addCase(deleteUserById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(deleteUserById.fulfilled, (state, action) => { handleFulfilled(state, "deleted");
        state.list = [...state.list].filter((i) => action.payload?.user_id !== i?.user_id);
      })
});

export const { resetUsersState, resetUsersStatus, resetUsersError } = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
