import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const OfficeNavTag = styled.nav`
  flex-grow: 1;
`;
export const OfficeNavList = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const OfficeNavItem = styled.li`
  border-top: 1px solid var(--color--bg, #f1f1f1);
`;

export const OfficeNavItemLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 48px;
  gap: 8px;
  padding: 0 12px;

  color: var(--color--primary, #414141);
  font-size: 20px;

  transition: var(--transition--100);

  aside.active & {
    font-size: 16px;
  }

  &.active,
  &:hover {
    color: var(--color--bg, #f1f1f1);
    background-color: var(--color--accent, #f19e1d);
  }
`;

export const OfficeNavItemBtn = styled.button`
  border: none;
  background-color: transparent;
  transition: var(--transition--100);

  .header & {
    color: var(--color--primary, #414141);
    text-transform: uppercase;
  }

  .footer & {
    color: var(--color--white, #fff);
  }

  &.active {
    position: relative;

    &::before {
      content: "";

      position: absolute;
      left: -13px;

      display: block;
      width: 1px;
      height: 24px;

      background-color: var(--color--accent, #f19e1d);
    }
  }
  .header &.active {
    color: var(--color--accent, #f19e1d);
  }

  &:hover {
    color: var(--color--accent, #f19e1d);
  }
`;
