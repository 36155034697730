import { createSlice } from "@reduxjs/toolkit";
import { createService, deleteServiceById, getServices, updateServiceById } from "./operations";
import { isAvailableArray } from "helpers";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const initialState = {};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    resetServicesState() { return initialState; },
    resetServicesStatus(state) { state.status = ""; },
    resetServicesError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createService.pending, (state, action) => { handlePending(state); })
      .addCase(createService.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(createService.fulfilled, (state, action) => { handleFulfilled(state, "added");
        state.list = [...isAvailableArray(state.list)]?.concat([action.payload]);
      })
      .addCase(getServices.pending, (state, action) => { handlePending(state); })
      .addCase(getServices.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getServices.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(updateServiceById.pending, (state, action) => { handlePending(state); })
      .addCase(updateServiceById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateServiceById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        state.list = [...state.list].map((i) => {
          if (action.payload?.service_id === i?.service_id) return action.payload;
          return i;
        });
      })
      .addCase(deleteServiceById.pending, (state, action) => { handlePending(state); })
      .addCase(deleteServiceById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(deleteServiceById.fulfilled, (state, action) => { handleFulfilled(state, "deleted");
        state.list = [...state.list].filter((i) => action.payload?.service_id !== i?.service_id);
      })
});

export const { resetServicesState, resetServicesStatus, resetServicesError } = servicesSlice.actions;
export const servicesReducer = servicesSlice.reducer;
