import React from "react";
// hooks
import { useLang, useText } from "hooks";
// helpers
import { getItemsList, getTranslate, isAvailableArray } from "helpers";
// styled
import { HomePageCarRental, HomePageCarRentalBox2, HomePageCarRentalBoxWrap, HomePageCarRentalItem, HomePageCarRentalLink, HomePageCarRentalLinkWrap, HomePageCarRentalList } from "./SectionCarRental.styled";
import { Container } from "components/Common/common.styled";

// ===== text translate START =====
const dataToTranslate = {
  // === DB & LOCAL ===
  textTitleRest: "pages.home.views.car_rental_at_alufcar.title.rest",
  textTitleAccent: "pages.home.views.car_rental_at_alufcar.title.accent",
}
// ===== text translate END =====

const SectionCarRental = () => {
  const { currentLang } = useLang();
  const { textDB } = useText();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang, textDB);
  const { 
    textTitleRest,
    textTitleAccent, 
  } = translate ?? {};
  // ===== text translate END =====
  // ===== text list START =====
  const itemsList = getItemsList(textDB, "pages.home.views.car_rental_at_alufcar.items_list");
  // ===== text list END =====
  
  return (
    <HomePageCarRental>
      <Container>
        <HomePageCarRentalBoxWrap>
          <HomePageCarRentalLinkWrap>
            <HomePageCarRentalLink to='/rental-car'>
              <span className="text"><span className="accent">{textTitleAccent}</span> {textTitleRest}</span> 
            </HomePageCarRentalLink>
          </HomePageCarRentalLinkWrap>
          <HomePageCarRentalBox2>
            <HomePageCarRentalList>
              {isAvailableArray(itemsList)?.map((i, idx) => (<HomePageCarRentalItem key={idx}>{i[currentLang]}</HomePageCarRentalItem>))}
            </HomePageCarRentalList>
          </HomePageCarRentalBox2>
        </HomePageCarRentalBoxWrap>
      </Container>
    </HomePageCarRental>
  );
};

export default SectionCarRental;
