import { isAvailableArray } from "./data";

export default function getAvailableVehicles(dataList) {
  const availableVehicles = isAvailableArray(dataList)?.reduce((prev, i) => {
    const iKeyCategory = i?.category.toLowerCase()?.replace(" +", "_plus");
    const iKeyBodyTypes = i?.body_type.toLowerCase();
    const newRes = {
      ...prev,
      ...{ [iKeyCategory]: prev[iKeyCategory] + i?.available },
      ...{ [iKeyBodyTypes]: prev[iKeyBodyTypes] + i?.available },
    };

    return newRes;
  }, initialValue);

  return availableVehicles;
}

const initialValue = {
  econom: 0,
  econom_plus: 0,
  comfort: 0,
  comfort_plus: 0,
  premium: 0,
  trucks: 0,
  scooters: 0,

  combi: 0,
  hatchback: 0,
  suv: 0,
  sedan: 0,
  van: 0,
  scooter: 0,
};
