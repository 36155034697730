export function getDateFromDateTime(date) {
  var local = new Date(date);
  return local?.toJSON()?.slice(0, 10);
}

export function getDateFromDateTimeLocal(date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local?.toJSON()?.slice(0, 10);
}

