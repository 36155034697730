export const breakpoint = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  mobile: 480,
  tablet: 768,
  laptop: 1024,
  desktop: 1200,
  desktopL: 1440,
  desktopL2: 1600,
  desktopXL: 1920,
};

export const minDevice = {
  mobileS: `(min-width: ${breakpoint.mobileS}px)`,
  mobileM: `(min-width: ${breakpoint.mobileM}px)`,
  mobileL: `(min-width: ${breakpoint.mobileL}px)`,
  mobile: `(min-width: ${breakpoint.mobile}px)`,
  tablet: `(min-width: ${breakpoint.tablet}px)`,
  laptop: `(min-width: ${breakpoint.laptop}px)`,
  desktop: `(min-width: ${breakpoint.desktop}px)`,
  desktopL: `(min-width: ${breakpoint.desktopL}px)`,
  desktopL2: `(min-width: ${breakpoint.desktopL2}px)`,
  desktopXL: `(min-width: ${breakpoint.desktopXL}px)`,
};

export const maxDevice = {
  mobileS: `(max-width: ${breakpoint.mobileS - 1}px)`,
  mobileM: `(max-width: ${breakpoint.mobileM - 1}px)`,
  mobileL: `(max-width: ${breakpoint.mobileL - 1}px)`,
  mobile: `(max-width: ${breakpoint.mobile - 1}px)`,
  tablet: `(max-width: ${breakpoint.tablet - 1}px)`,
  laptop: `(max-width: ${breakpoint.laptop - 1}px)`,
  desktop: `(max-width: ${breakpoint.desktop - 1}px)`,
  desktopL: `(max-width: ${breakpoint.desktopL - 1}px)`,
  desktopL2: `(max-width: ${breakpoint.desktopL2 - 1}px)`,
  desktopXL: `(max-width: ${breakpoint.desktopXL - 1}px)`,
};

export const customMinMaxDevice = (min, max) => {
  return `(min-width: ${min}px) and (max-width: ${max}px)`
};

export const customBreakpoint = (type, pxNumber) => {
  return `(${type}-width: ${pxNumber}px)`
}