import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";
// components
import { CardSetItemLi, CardSetUl } from "components/Common/Placeholders";

export const RentalVehiclePricesListTag = styled(CardSetUl)`
  --gap: 8px;

  @media ${minDevice.desktopXL} {
    --gap: 12px;
  }
`;
export const RentalVehiclePricesItem = styled(CardSetItemLi)`
  --items: 1;

  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  padding-left: 12px;

  font-size: 12px;
  line-height: 21px;

  background-color: var(--color--bg, #f1f1f1);
  border-radius: 46px;

  & span:nth-of-type(1) {
    flex: 1 0 32px;
    margin-right: auto;

    font-weight: 600;
    color: var(--color--accent, #f19e1d);
  }

  & span:nth-of-type(2) {
    flex: 1 0 80px;
    text-align: end;
  }
  & span:nth-of-type(3) {
    flex: 0 0 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 4px 8px;

    color: #fff;

    background-color: var(--color--primary, #414141);
    border-radius: 46px;
  }

  &.product-page {
    background-color: #fff;
  }

  @media ${minDevice.mobileM} {
    gap: 12px;

    & span:nth-of-type(1) {
      flex: 1 0 60px;
    }
    & span:nth-of-type(3) {
      flex: 1 0 116px;
    }
  }

  @media ${minDevice.mobileL} {
    font-size: 14px;

    & span:nth-of-type(2) {
      flex: 1 0 88px;
    }
    & span:nth-of-type(3) {
      flex: 1 0 140px;
      padding: 4px 12px;
    }
  }

  @media ${minDevice.tablet} {
    --items: 2;
    gap: 8px;

    font-size: 14px;
    line-height: 1;

    & span:nth-of-type(1) {
      flex: 1 0 72px;
    }
    & span:nth-of-type(2) {
      flex: 1 0 90px;
    }
    & span:nth-of-type(3) {
      flex: 1 0 136px;
      padding: 4px;
    }
  }
  @media ${minDevice.desktopXL} {
    & span:nth-of-type(1) {
      flex: 1 0 40px;
    }
    & span:nth-of-type(2) {
      flex: 1 0 90px;
    }
    & span:nth-of-type(3) {
      flex: 1 0 124px;
    }

    &.product-page {
      --items: 3;
      padding-left: 16px;

      & span:nth-of-type(1) {
        flex: 1 0 60px;
      }
    }
  }
`;
