import styled from "styled-components";
// components
import { FlexAcJc } from "components/Common/Placeholders";

export const LangSwitcherWrap = styled(FlexAcJc)`
  position: relative;

  flex: 0 0 80px;
  width: 80px;
  gap: 12px;
  height: 24px;
  padding-right: 16px;

  &::before {
    content: "";

    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    display: block;
    width: 8px;
    height: 8px;

    background-image: url('${process.env.PUBLIC_URL}/images/icons/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    pointer-events: none;
    transition: var(--transition--200);
  }
  &.active::before { transform: translateY(-50%) rotate(180deg); }
`;
export const LangSwitcheBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: transparent;
  border: none;
  text-transform: uppercase;
  transition: var(--transition--100);

  .header & {
    color: var(--color--primary, #414141);
    text-transform: uppercase;
  }

  &:hover {
    color: var(--color--accent, #f19e1d);
  }
`;

export const LangSwitcherBox = styled(FlexAcJc)`
  position: absolute;
  bottom: -60px;
  left: -10%;

  width: 120%;
  padding: 12px 20px;

  background-color: var(--color--bg, #F1F1F1);
  border-radius: 16px;
  box-shadow: var(--box-shadow--main);
  transition: var(--transition--200);
  z-index: 1;
  scale: 0;
  transform-origin: center;

  &::before {
    content: "";

    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);

    display: block;
    width: 24px;
    height: 12px;

    background-color: var(--color--bg, #F1F1F1);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  &.active { scale: 1; }
`;