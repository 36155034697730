export function sortLocalDatesTimes1key(arr, grow, key) {
  return [...arr]?.sort((a, b) => {
    if (grow)
      return (
        a[key].replaceAll(".", "")?.replaceAll(":", "")?.replaceAll(", ", "") -
        b[key].replaceAll(".", "")?.replaceAll(":", "")?.replaceAll(", ", "")
      );
    return (
      b[key].replaceAll(".", "")?.replaceAll(":", "")?.replaceAll(", ", "") -
      a[key].replaceAll(".", "")?.replaceAll(":", "")?.replaceAll(", ", "")
    );
  });
}

// export function sortLocalDates1key(arr, grow, key) {
//   return [...arr]?.sort((a, b) => {
//     if (grow) return a[key].replaceAll(".", "") - b[key].replaceAll(".", "");
//     return b[key].replaceAll(".", "") - a[key].replaceAll(".", "");
//   });
// }
