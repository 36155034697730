import { createSlice } from "@reduxjs/toolkit";
import {
  createSale,
  getSales,
  getSaleById,
  updateSaleById,
} from "./operations";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const handleSuccess = (state) => {
  state.status = "success";
  state.error = null;
};

const initialState = {};

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    resetSalesState() { return initialState; },
    resetSalesStatus(state) { state.status = ""; },
    resetSalesError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createSale.pending, (state, action) => { handlePending(state); })
      .addCase(createSale.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(createSale.fulfilled, (state, action) => { handleSuccess(state); })
      .addCase(getSales.pending, (state, action) => { handlePending(state); })
      .addCase(getSales.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getSales.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(getSaleById.pending, (state, action) => { handlePending(state); })
      .addCase(getSaleById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getSaleById.fulfilled, (state, action) => { handleFulfilled(state);
        state.item = action.payload;
      })
      .addCase(updateSaleById.pending, (state, action) => { handlePending(state); })
      .addCase(updateSaleById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateSaleById.fulfilled, (state, action) => { handleFulfilled(state, "updated");        
        state.item = {...state.item, ...action.payload }
      })
});

export const { resetSalesState, resetSalesStatus, resetSalesError } = salesSlice.actions;
export const salesReducer = salesSlice.reducer;
