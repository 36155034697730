import React from "react";
// hooks
import { useLang } from "hooks";
// helpers
import { getLocalDateWithDayOfWeek } from "helpers";
// styled
import { TodayBox } from "./Today.styled";

const Today = () => {
  const { currentLang } = useLang();

  return (
    <TodayBox>
      {getLocalDateWithDayOfWeek(new Date(), "long")[currentLang]}
    </TodayBox>
  );
};

export default Today;
