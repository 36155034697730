import { useSelector } from "react-redux";
// redux
import { selectServicesList } from "redux/services/servicesSelectors";
// common
import { compareStrings, isAvailableArray } from "helpers";

const useServices = () => {
  const allServicesList = useSelector(selectServicesList);
  const deliveryServicesList = isAvailableArray(allServicesList)
    ?.filter((i) => compareStrings(i?.category?.en, "Delivery"))
    ?.map((i) => ({name: i?.name, price: i?.price, allow_for: i?.allow_for}));
  const basicServicesList = isAvailableArray(allServicesList)
    ?.filter((i) => compareStrings(i?.category?.en, "Basic services"))
    ?.map((i) => ({name: i?.name, price: i?.price, allow_for: i?.allow_for}));
  const additionalServicesList = isAvailableArray(allServicesList)
    ?.filter((i) => compareStrings(i?.category?.en, "Additional services"))
    ?.map((i) => ({count: 0, name: i?.name, price: i?.price, allow_for: i?.allow_for}));

  return {
    allServicesList,
    basicServicesList,
    deliveryServicesList,
    additionalServicesList,
  };
};

export default useServices;
