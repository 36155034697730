import { createSlice } from "@reduxjs/toolkit";
import { createLocation, deleteLocationById, getLocations, updateLocationById } from "./operations";
import { isAvailableArray } from "helpers";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const initialState = {};

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    resetLocationsState() { return initialState; },
    resetLocationsStatus(state) { state.status = ""; },
    resetLocationsError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createLocation.pending, (state, action) => { handlePending(state); })
      .addCase(createLocation.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(createLocation.fulfilled, (state, action) => { handleFulfilled(state, "added");
        state.list = [...isAvailableArray(state.list)]?.concat([action.payload]);
      })
      .addCase(getLocations.pending, (state, action) => { handlePending(state); })
      .addCase(getLocations.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getLocations.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(updateLocationById.pending, (state, action) => { handlePending(state); })
      .addCase(updateLocationById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateLocationById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        state.list = [...state.list].map((i) => {
          if (action.payload?.location_id === i?.location_id) return action.payload;
          return i;
        });
      })
      .addCase(deleteLocationById.pending, (state, action) => { handlePending(state); })
      .addCase(deleteLocationById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(deleteLocationById.fulfilled, (state, action) => { handleFulfilled(state, "deleted");
        state.list = [...state.list].filter((i) => action.payload?.location_id !== i?.location_id);
      })
});

export const { resetLocationsState, resetLocationsStatus, resetLocationsError } = locationsSlice.actions;
export const locationsReducer = locationsSlice.reducer;
