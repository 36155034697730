import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// redux
import { getPublicRentalVehicles } from "redux/vehicles/operations";
// views
import { SectionHowDoesItWork, SectionFindTheBestCar, SectionRentalOfScooters, SectionChooseYourTravel, Benefits, SectionCarForSale, SectionCarRental, } from "views";
// components
import Reviews from "components/Reviews/Reviews";
import ContactsLine from "components/ContactsLine/ContactsLine";
import SearchVehiclesForm from "components/forms/SearchVehiclesForm/SearchVehiclesForm";
// styled
import { Container } from "components/Common/common.styled";

const HomePage = () => {
  document.body.classList.add("white");
  const [render, setRender] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (render) return setRender(false);
    dispatch(getPublicRentalVehicles());
  }, [render, dispatch]);

  return (
    <>
      <Container> <ContactsLine /> </Container>
      <SearchVehiclesForm />
      <SectionChooseYourTravel />
      <SectionFindTheBestCar />
      <SectionHowDoesItWork />
      <SectionCarRental />
      <SectionRentalOfScooters />
      <SectionCarForSale />
      <Reviews />
      <Benefits />
    </>
  );
};

export default HomePage;
