import React, { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
// redux
import { getTextsPublic } from "redux/texts/operations";
// hooks
import { useLang, useWindowDimensions } from "hooks";
// components
import Loader from "components/Loader/Loader";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

// styled
import { PageWrap, Main } from "./PublicLayout.styled";

const PublicLayout = () => {
  const [render, setRender] = useState(true);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const { isSmallView } = useWindowDimensions();
  const { currentLangAttribute } = useLang();
  // ===== document =====
  const htmlRef = document.querySelector("html");
  const bodyRef = document.querySelector("body");
  htmlRef.setAttribute("lang", currentLangAttribute);
  if (isSmallView && isBurgerMenuOpen) bodyRef.classList.add("mobileMenuIsOpen");
  if (isSmallView && !isBurgerMenuOpen) bodyRef.classList.remove("mobileMenuIsOpen");
  // ===== document =====

  useEffect(() => {
    if (render) return setRender(false);
    dispatch(getTextsPublic());
  }, [render, dispatch]);

  return (
    <PageWrap>
      <Header isSmallView={isSmallView} isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />
      <Main>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Main>
      <Footer />
    </PageWrap>
  );
};

export default PublicLayout;
