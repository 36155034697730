import React from "react";
// hooks
import { useWindowDimensions } from "hooks";
// components
import Nav from "components/Nav/Nav";
import Logo from "components/Logo/Logo";
import Contacts from "components/Contacts/Contacts";
import ContactsSn from "components/Contacts/ContactsSn";
// styled
import { FooterBottom, FooterBottomContainer, FooterBox1, FooterBox2, FooterPolicyLink, FooterTag, FooterTop, FooterTopContainer } from "./Footer.styled";

const Footer = () => {
  const { is768, is1440 } = useWindowDimensions();

  return (
    <FooterTag>
      <FooterTop>
        <FooterTopContainer>
          <FooterBox1>
            <Logo className={"footer"} />
            {is1440 && <Nav className={"footer"} />}
          </FooterBox1>
          <FooterBox2>
            {is768 && <Contacts className={"footer"} />}
            <ContactsSn className={"footer"} />
          </FooterBox2>
        </FooterTopContainer>
      </FooterTop>
      <FooterBottom>
        <FooterBottomContainer>
          <div style={{display: 'flex', gap: '16px'}}>
            <FooterPolicyLink to="/faq">Privacy policy</FooterPolicyLink>
            |
            <FooterPolicyLink to="/login">Login</FooterPolicyLink>
          </div>
          <span>© 2024, All Rights Reserved</span>
        </FooterBottomContainer>
      </FooterBottom>
    </FooterTag>
  );
};

export default Footer;
