import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const getRentalVehicleById = createAsyncThunk(
  "vehicles/getRentalVehicleById",
  async ({vehiclesGroupId, vehicleId}, thunkAPI) => {
    try {
      const res = await axios.get(`/vehicles/rental/${vehiclesGroupId}/${vehicleId}`);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
