import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";
// components
import { CardSetItem } from "components/Common/Placeholders";

export const DropdownWrap = styled(CardSetItem)`
  position: relative;

  width: 100%;
  min-height: 48px;

  &.icon::before {
    content: "";

    position: absolute;
    top: 14px;
    left: 22px;

    display: block;
    width: 20px;
    height: 20px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    pointer-events: none;
    z-index: 2;
  }

  &::after {
    content: "";

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;

    display: block;
    width: 8px;
    height: 8px;

    background-image: url("${process.env.PUBLIC_URL}/images/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    pointer-events: none;
    transition: var(--transition--100);
  }

  &.active::after { 
    transform: translateY(-50%) rotate(180deg); 
    z-index: 3;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

     &::after { content: none; }
  }

  &.locations::before { background-image: url("${process.env.PUBLIC_URL}/images/icons/map-marker-plus.svg"); }
  &.time::before { background-image: url("${process.env.PUBLIC_URL}/images/icons/time.svg"); }
  &.locations.order-5 { @media ${minDevice.desktopL} { order: 4; margin-bottom: auto; } }

`;
export const DropdownList = styled.ul`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 48px;
  max-height: 48px;

  border-radius: 26px;;
  transition: var(--transition--200);
  overflow-y: scroll;

  &.active {
    max-height: 240px;

    background-color: var(--color--bg, #f1f1f1);
    box-shadow: var(--box-shadow--main);
    z-index: 3;
  }
`;
export const DropdownItem = styled.li`
  display: none;
  width: 100%;

  text-align: start;

  &.active, .active & { display: block; }
`;

export const DropdownPlaceholder = styled.p`
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 4px 16px;

  background-color: var(--color--bg, #f1f1f1);
  cursor: pointer;

  .bg-fff & { background-color: #fff; }
  .icon & { padding-left: 54px; }

  &:hover { color: var(--color--accent, #f19e1d); }
`;

export const DropdownItemBtn = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  padding: 4px 16px;

  text-align: start;

  background-color: var(--color--bg, #f1f1f1);
  border: 1px solid transparent;
  border-top-color: #fff;
  transition: var(--transition--20);

  &:hover { color: var(--color--accent, #f19e1d); }

  .icon & { padding-left: 54px; }
  .bg-fff & { 
    background-color: #fff;
    border-top-color: var(--color--bg, #f1f1f1); 
  }
  
`;