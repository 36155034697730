const { createInsurance } = require("./createInsurance");
const { deleteInsuranceById } = require("./deleteInsuranceById");
const { getInsurances } = require("./getInsurances");
const { updateInsuranceById } = require("./updateInsuranceById");

module.exports = {
  createInsurance,
  getInsurances,
  deleteInsuranceById,
  updateInsuranceById,
};
