import React from "react";
// hooks
import { useWindowDimensions } from "hooks";
// components
import Nav from "components/Nav/Nav";
import Logo from "components/Logo/Logo";
import BurgerMenu from "components/BurgerMenu/BurgerMenu";
import BurgerMenuBtn from "components/BurgerMenu/BurgerMenuBtn";
import LangSwitcher from "components/LangSwitcher/LangSwitcher";
// styled
import { HeaderContainer, HeaderTag } from "./Header.styled";

const Header = ({ isBurgerMenuOpen, setIsBurgerMenuOpen, isSmallView }) => {
  const { is1440 } = useWindowDimensions();

  return (
    <HeaderTag>
      <HeaderContainer>
        <Logo className={"header"} />
        {is1440 && <Nav className={"header"} />}
        <LangSwitcher />
        {isSmallView && <BurgerMenuBtn isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />}
        {isSmallView && <BurgerMenu isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />}
      </HeaderContainer>
    </HeaderTag>
  );
};

export default Header;
