import React from 'react';
// hooks
import { useLang, useText } from 'hooks';
// helpers
import { getItemsList, getTranslate, isAvailableArray } from 'helpers';
// styled
import { SectionCarForSaleBox, SectionCarForSaleBox2Inner, SectionCarForSaleBox2Item, SectionCarForSaleBox2Link, SectionCarForSaleBox2LinkItem, SectionCarForSaleBox2LinkList, SectionCarForSaleBox2LinkWrap, SectionCarForSaleBox2List, SectionCarForSaleBox3Item, SectionCarForSaleBox3List, SectionCarForSaleBtn, SectionCarForSaleContainer, SectionCarForSaleItem, SectionCarForSaleItemText, SectionCarForSaleList, SectionCarForSaleSubTitle, SectionCarForSaleTag } from './SectionCarForSale.styled';
import { HomePageSectionTitle } from 'pages/public/HomePage/HomePage.styled';

// ===== text translate START =====
const dataToTranslate = {
  // === DB & LOCAL ===
  textBenefitsTitleRest: "pages.cars_for_sale.views.benefits.title.rest",
  textBenefitsTitleAccent: "pages.cars_for_sale.views.benefits.title.accent",
  textDifferenceLinkTitle: "pages.cars_for_sale.views.difference.link_title",
  textDifferenceTitleRest: "pages.cars_for_sale.views.difference.title.rest",
  textDifferenceTitleAccent: "pages.cars_for_sale.views.difference.title.accent",
  textWhoWillItSuitTitleRest: "pages.cars_for_sale.views.who_will_it_suit.title.rest",
  textWhoWillItSuitTitleAccent: "pages.cars_for_sale.views.who_will_it_suit.title.accent",
  // === LOCAL ===
  textSendAMessage: "send_a_message",
}
// ===== text translate END =====

const SectionCarForSale = () => {
  const { currentLang } = useLang();
  const { textDB } = useText();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang, textDB);
  const { 
    textBenefitsTitleRest,
    textBenefitsTitleAccent,
    textDifferenceLinkTitle,
    textDifferenceTitleRest,
    textDifferenceTitleAccent,
    textWhoWillItSuitTitleRest,
    textWhoWillItSuitTitleAccent,
    textSendAMessage,
  } = translate ?? {};
  // ===== text translate END =====
  // ===== text list START =====
  const itemsListBenefits = getItemsList(textDB, "pages.cars_for_sale.views.benefits.items_list");
  const itemsListDifference = getItemsList(textDB, "pages.cars_for_sale.views.difference.items_list");
  const itemsListDifference2 = getItemsList(textDB, "pages.cars_for_sale.views.difference.items_list_2");
  const itemsListWhoWillItSuitItems = getItemsList(textDB, "pages.cars_for_sale.views.who_will_it_suit.items_list");
  // ===== text list END =====

  return (
    <SectionCarForSaleTag>
      <SectionCarForSaleContainer>
        <SectionCarForSaleBox>
          <HomePageSectionTitle> {textBenefitsTitleRest} <span>{textBenefitsTitleAccent}</span> </HomePageSectionTitle>
          <SectionCarForSaleList>
            {isAvailableArray(itemsListBenefits)?.map((i, idx) => (
              <SectionCarForSaleItem key={idx}>
                <SectionCarForSaleItemText> {i[currentLang]} </SectionCarForSaleItemText>
              </SectionCarForSaleItem>
            ))}
          </SectionCarForSaleList>
        </SectionCarForSaleBox>

        <SectionCarForSaleBox>
          <SectionCarForSaleSubTitle> {textDifferenceTitleRest} <span>{textDifferenceTitleAccent}</span> </SectionCarForSaleSubTitle>
          <SectionCarForSaleBox2Inner>
            <SectionCarForSaleBox2List>
              {isAvailableArray(itemsListDifference)?.map((i, idx) => (
                <SectionCarForSaleBox2Item key={idx}> {i[currentLang]} </SectionCarForSaleBox2Item>
              ))}
            </SectionCarForSaleBox2List>

            <SectionCarForSaleBox2LinkWrap>
              <SectionCarForSaleBox2Link to="/cars-for-sale"> {textDifferenceLinkTitle} </SectionCarForSaleBox2Link>
              
              <SectionCarForSaleBox2LinkList>
                {isAvailableArray(itemsListDifference2)?.map((i, idx) => (
                  <SectionCarForSaleBox2LinkItem key={idx}> {i[currentLang]} </SectionCarForSaleBox2LinkItem>
                ))}
              </SectionCarForSaleBox2LinkList>
            </SectionCarForSaleBox2LinkWrap> 
          </SectionCarForSaleBox2Inner>
        </SectionCarForSaleBox>

        <SectionCarForSaleBox>
          <SectionCarForSaleSubTitle> <span>{textWhoWillItSuitTitleAccent}</span> {textWhoWillItSuitTitleRest} </SectionCarForSaleSubTitle>
          <SectionCarForSaleBox3List>
            {isAvailableArray(itemsListWhoWillItSuitItems)?.map((i, idx) => (
              <SectionCarForSaleBox3Item key={idx}> {i[currentLang]} </SectionCarForSaleBox3Item>
            ))}
          </SectionCarForSaleBox3List>
        </SectionCarForSaleBox>

        <SectionCarForSaleBtn to="/contacts">{textSendAMessage}</SectionCarForSaleBtn>
      </SectionCarForSaleContainer>
    </SectionCarForSaleTag>
  )
}

export default SectionCarForSale